import React, { useEffect, useState } from 'react'
import { styled } from "@mui/material/styles";
import { Avatar, Container, Grid2 as Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { LockOpen } from '@mui/icons-material';
import ControlMUITextField from '../../Components/MUI/ControlMUItextField';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SpanLink } from '../../Components/SpanLink';
import { LoadingButton } from '@mui/lab';
import { useRegisterMutation } from '../../graphql/mutations/auth.generated';
import { RegisterInput } from '../../graphql/types';
import { toast } from 'sonner';
import VerifyDialog from './component/VerifyDialog';
import { setValidationError } from '../../Components/helperFunctions/setValidationError';
import MuiSelect from '../../Components/MUI/MuiSelect';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PREFIX = "Register";

const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
};

const Root = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(10, 0),
  [`& .${classes.paper}`]: {
    width: "100%",
    padding: theme.spacing(4),
  },
  [`& .${classes.avatar}`]: {
    width: 56,
    height: 56,
    background: theme.palette.primary.main
  },
}));

type Birthdate = {
  year: number,
  month: number,
  day: number,
  confirmPassword: string
}

type PassType = {
  [key: string]: string;  // Each key is a string and its value is a string (e.g., "text")
};


const Register = () => {

  const { control, handleSubmit, watch, setError, setValue } = useForm<RegisterInput & Birthdate>()
  const { t } = useTranslation()

  const [registerMutation, { loading }] = useRegisterMutation()
  const [open, setOpen] = useState(false)
  const [noUpdateFromOther, setNoUpdateFromOther] = useState({
    name: false,
    mobile: false
  })
  const [passType, setPassType] = useState<PassType>({
    password: "password",
    confirmPassword: "password"
  })

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = (data: RegisterInput & Birthdate) => {
    console.log(data.address);

    if (watch("year")) {
      if (!watch("month") || !watch("day")) {
        if (!watch("month")) {
          setError("month", {
            message: t("fieldIsRequired"),
            type: "required"
          })
        }
        if (!watch("day")) {
          setError("day", {
            message: t("fieldIsRequired"),
            type: "required"
          })
        }
        return
      }
      data.birthdate = `${data.year}-${data.month}-${data.day}`
    }

    if (data.password !== data.confirmPassword) {
      setError("confirmPassword", {
        message: t("confirmPasswordError"),
        type: "required"
      })
      return
    }



    for (const key in data) {
      if (key === "year" || key === "month" || key === "day" || key === "confirmPassword") {
        delete data[key];
      }
    }

    registerMutation({
      variables: {
        input: {
          ...data,
          ...(data.birthdate && { birthdate: data.birthdate })
        }
      }
    }).then(() => {
      toast.success(t("accountHasBeenCreated"))
      setOpen(true)
    }).catch(({ graphQLErrors }) => {
      setValidationError({ graphQLErrors, setError })
    })
  }

  const [years, setYears] = useState<number[]>([]);
  const [months] = useState<number[]>(Array.from({ length: 12 }, (_, i) => i + 1));
  const [days, setDays] = useState<number[]>([]);

  // Generate years from 10 to 90
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearRange = Array.from({ length: 81 }, (_, i) => currentYear - 10 - i);
    setYears(yearRange);
  }, []);

  // Update days based on selected year and month
  useEffect(() => {
    if (watch("year" as any) !== null || watch("month" as any) === null) {
      const daysInMonth = new Date(watch("year" as any), watch("month" as any) + 1, 0).getDate();
      setDays(Array.from({ length: daysInMonth }, (_, i) => i + 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("year" as any), watch("month" as any)]);

  const changePassType = (type: string) => {
    setPassType(prev => ({
      ...prev,
      [type]: prev[type] === "password" ? "text" : "password"
    }))
  }

  return (
    <Root justifyContent={"center"} alignItems={"center"}>
      {open &&
        <VerifyDialog
          open={open}
          handleClose={handleClose}
          email={watch("email")}
          password={watch("password")}
        />
      }
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            className={classes.paper}
            component={Paper}
            elevation={3}
            spacing={3}
            alignItems={"center"}
          >
            <Avatar className={classes.avatar}>
              <LockOpen fontSize='large' />
            </Avatar>
            <Typography variant='h1' fontSize={40}>{t("signUp")}</Typography>
            <Grid container spacing={2} width={"100%"} m={0}>
              <Grid size={{ xs: 6 }}>
                <ControlMUITextField
                  control={control}
                  name='firstName'
                  label={t('firstName')}
                  rules={{
                    required: t("fieldIsRequired"),
                  }}
                  onBlur={(e) => {
                    if (noUpdateFromOther.name) {
                      return
                    }
                    setValue("address.name", `${watch("firstName")} ${watch("lastName")}`, {
                      shouldDirty: true,
                    });
                  }}
                />
              </Grid>
              <Grid size={{ xs: 6 }}>
                <ControlMUITextField
                  control={control}
                  name='lastName'
                  label={t('lastName')}
                  rules={{
                    required: t("fieldIsRequired"),
                  }}
                  onBlur={(e) => {
                    if (noUpdateFromOther.name) {
                      return
                    }
                    setValue("address.name", `${watch("firstName")} ${watch("lastName")}`, {
                      shouldDirty: true,
                    });
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <ControlMUITextField
                  control={control}
                  name='email'
                  label={t('email')}
                  rules={{
                    required: t("fieldIsRequired"),
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <ControlMUITextField
                  name='password'
                  label={t('password')}
                  type={passType.password}
                  control={control}
                  rules={{
                    required: t("fieldIsRequired"),
                  }}
                  slotProps={{
                    input: {
                      endAdornment: <IconButton size='small' onClick={() => changePassType("password")}>
                        {passType.password === "password" && <VisibilityOffIcon color='primary' fontSize='inherit' />}
                        {passType.password === "text" && <VisibilityIcon color='primary' fontSize='inherit' />}
                      </IconButton>
                    }
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <ControlMUITextField
                  name='confirmPassword'
                  label={t('confirmPassword')}
                  type={passType.confirmPassword}
                  control={control}
                  rules={{
                    required: t("fieldIsRequired"),
                  }}
                  slotProps={{
                    input: {
                      endAdornment: <IconButton size='small' onClick={() => changePassType("confirmPassword")}>
                        {passType.confirmPassword === "password" && <VisibilityOffIcon color='primary' fontSize='inherit' />}
                        {passType.confirmPassword === "text" && <VisibilityIcon color='primary' fontSize='inherit' />}
                      </IconButton>
                    }
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <ControlMUITextField
                  control={control}
                  name='mobile'
                  label={t('mobile')}
                  onBlur={(e) => {
                    if (noUpdateFromOther.mobile) {
                      return
                    }
                    setValue("address.mobile", watch("mobile"), {
                      shouldDirty: true,
                    });
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Typography>{t("birthDate")}</Typography>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <MuiSelect
                  control={control}
                  data={years.map(e => ({ key: String(e), value: e }))}
                  label={t("year")}
                  name='year'
                />
              </Grid>
              <Grid size={{ xs: 4 }}>
                <MuiSelect
                  control={control}
                  data={months.map((e, index) => ({ key: String(e), value: index }))}
                  label={t("month")}
                  name='month'
                  disabled={!watch("year" as any)}
                />
              </Grid>
              <Grid size={{ xs: 4 }}>
                <MuiSelect
                  control={control}
                  data={days.map(e => ({ key: String(e), value: e }))}
                  label={t("day")}
                  name='day'
                  disabled={!watch("year" as any) || watch("month" as any) === null}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Typography>{t("address")}</Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <ControlMUITextField
                  label={t("name")}
                  onChange={() => setNoUpdateFromOther(prev => ({ ...prev, name: true }))}
                  control={control}
                  name="address.name"
                />
              </Grid>
              <Grid size={{ xs: 6 }}>
                <ControlMUITextField
                  onChange={() => setNoUpdateFromOther(prev => ({ ...prev, mobile: true }))}
                  label={t("phone") + " 1"}
                  control={control}
                  name="address.mobile"
                />
              </Grid>
              <Grid size={{ xs: 6 }}>
                <ControlMUITextField
                  label={t("phone") + " 2"}
                  control={control}
                  name="address.phone"
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <ControlMUITextField
                  label={t("address")}
                  control={control}
                  name="address.address"
                  rules={{
                    required: t("fieldIsRequired"),
                  }}
                  rows={2}
                />
              </Grid>
            </Grid>
            <LoadingButton variant='contained' type='submit' fullWidth loading={loading}>
              {t('signUp')}
            </LoadingButton>
            <Typography>
              {t("haveAccount") + " "}
              <SpanLink to={"/login"}>
                {t("login")}
              </SpanLink>
            </Typography>
          </Stack>
        </form>

      </Container>
    </Root>
  )
}

export default Register