import { Close, ShoppingCart } from '@mui/icons-material'
import { Divider, Paper, Stack, Tooltip, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton';
import Loading from '../Loading';
import { formatted } from '../helperFunctions/function';
import ButtonLink from '../ButtonLink';
import CartItem from './CartItem';
import useCartData from '../Hooks/useCartData';

const CartContent = ({
    toggleDrawer,
    classes
}: {
    toggleDrawer: (newOpen: boolean) => void
    classes: any
}) => {
    const { t } = useTranslation()
    const { data, loading, updateQueries } = useCartData()

    return (
        <Stack width={340} role="presentation" p={2} spacing={3} height={"100dvh"}>
            {loading ? <Loading /> :
                <>
                    <Stack
                        component={Paper}
                        px={2}
                        py={1}
                        alignItems={"center"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        elevation={5}
                    >
                        <Typography fontSize={20} textTransform={"capitalize"}>{t("cart")}</Typography>
                        <Tooltip title={t("closeCart")}>
                            <IconButton aria-label="close" size='small' onClick={() => toggleDrawer(false)}>
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Stack spacing={3} className={classes.productWrapper}>
                        {data?.cart.items && data?.cart.items.length > 0 ?
                            data?.cart.items.map((e, i) =>
                                <Fragment key={e?.id}>
                                    <CartItem
                                        item={{
                                            id: e?.id!,
                                            name: e?.product.name!,
                                            price: e?.price!,
                                            quantity: e?.quantity!,
                                            image: e?.product.image?.path,
                                            totalPrice: e?.price!,
                                            options: e?.options,
                                            addons: e?.addons,
                                            productId: e?.product.id!
                                        }}
                                        refetch={updateQueries}
                                    />
                                    {(i !== data?.cart.items.length - 1) && <Divider />}
                                </Fragment>)
                            : <Stack justifyContent={"center"} alignItems={"center"} height={"100%"} spacing={2}>
                                <ShoppingCart fontSize='large' color='primary' />
                                <Typography fontSize={20} textTransform={"capitalize"}>{t("emptyCart")}</Typography>
                            </Stack>
                        }

                    </Stack>
                    {data?.cart.items && data?.cart.items.length > 0 && <>
                        <Divider />
                        <Stack spacing={3}>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography fontSize={16} fontWeight={"bolder"}>
                                    {t("subtotal")}:
                                </Typography>
                                <Typography fontSize={16} fontWeight={"bolder"} color='primary.main'>
                                    {formatted(data?.cart.price)}
                                </Typography>
                            </Stack>
                            <Divider />
                            <Stack direction={"row"} spacing={3}>
                                <ButtonLink to='/cart' text={t("viewCart")} fullWidth onClick={() => toggleDrawer(false)} />
                                <ButtonLink to='/checkout' text={t("proceedToBuy")} fullWidth color='secondary' onClick={() => toggleDrawer(false)} />
                            </Stack>
                        </Stack>
                    </>
                    }
                </>
            }

        </Stack>
    )
}

export default CartContent