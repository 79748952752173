import { Button, Container, Divider, Paper, Skeleton, Stack, Typography } from '@mui/material'
import CartCard from './Component/CartCard'
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import BreadCrumb from '../../../Components/BreadCrumb';
import Loading from '../../../Components/Loading';
import { formatted } from '../../../Components/helperFunctions/function';
import { isEven } from '../../../Components/helperFunctions/checkOdd';
import { ShoppingCart } from '@mui/icons-material';
import ButtonLink from '../../../Components/ButtonLink';
import useCartData from '../../../Components/Hooks/useCartData';
import { useState } from 'react';

const PREFIX = "cart";

const classes = {
    paymentWrapper: `${PREFIX}-paymentWrapper`,
    cartWrapper: `${PREFIX}-cartWrapper`,
    divider: `${PREFIX}-divider`,
    default: `${PREFIX}-default`,
};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.paymentWrapper}`]: {
        [`& p`]: {
            fontSize: 16
        },
    },
    [`& .${classes.cartWrapper}`]: {
        margin: theme.spacing(5, 0)
    },
    [`& .${classes.divider}`]: {
        background: theme.palette.divider
    },
    [`& .${classes.default}`]: {
        background: theme.palette.background.default
    },
}));

const Cart = () => {
    const { t } = useTranslation()
    const [error, setError] = useState(false)
    const { data, loading, updateQueries } = useCartData()

    return (
        <Root spacing={2} alignItems={"center"}>
            <BreadCrumb pageLink={"cart"} />
            {loading ? (<Loading />) :
                <Container maxWidth="lg" className={classes.cartWrapper}>
                    <Stack alignItems={"center"} mb={1}>
                        {error && <Button variant='outlined' onClick={() => window.location.reload()}>{t("reloadWindow")}</Button>}
                    </Stack>
                    <Stack spacing={2}>
                        {data?.cart.items.length! > 0 ? data?.cart.items.map((e) =>
                            <CartCard
                                setError={setError}
                                key={e?.id}
                                item={{
                                    id: e?.id!,
                                    name: e?.product.name!,
                                    productId: e?.product.id!,
                                    price: e?.product.price!,
                                    quantity: e?.quantity!,
                                    image: e?.product.image?.path,
                                    totalPrice: e?.price!,
                                    options: e?.options,
                                    addons: e?.addons,
                                    ifAddons: e?.product.addons?.length! > 0
                                }}
                                updateQueries={updateQueries}
                            />
                        ) :
                            <Stack justifyContent={"center"} alignItems={"center"} height={300} spacing={2}>
                                <ShoppingCart fontSize='large' color='primary' />
                                <Typography fontSize={20}>{t("emptyCart")}</Typography>
                            </Stack>
                        }
                    </Stack>
                    {data?.cart.items.length! > 0 && <>
                        <Divider />
                        <Stack alignItems={"flex-end"}>
                            <Stack mt={5} width={"300px"} spacing={1} className={classes.paymentWrapper}>
                                <Typography py={2}>{t("cartTotals")}</Typography>
                                <PaymentStack loading={loading} price={data?.cart.price!} title={t('subtotal')} position={0} />
                                <ButtonLink variant='contained' text={t("proceedToBuy")} to='/checkout' fullWidth />
                            </Stack>
                        </Stack>
                    </>}
                </Container>
            }
        </Root>
    )
}

export default Cart


const PaymentStack = ({
    title,
    price,
    position,
    loading
}: {
    title: string,
    price: number
    position: number
    loading: boolean
}) => {
    return <Stack
        component={Paper}
        direction={"row"}
        justifyContent={"space-between"}
        p={2}
        className={isEven(position) ? classes.default : classes.divider}
    >
        <Typography color='text.secondary'>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : title}</Typography>
        <Typography color='text.secondary'>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(price)}</Typography>
    </Stack>
}