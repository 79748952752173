import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Container from '@mui/material/Container';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { styled } from "@mui/material/styles";
// import LanguageMenu from './Language';
import { keyframes } from '@mui/system';
// import useWidth, { isWidthDown } from './helperFunctions/useWidth';
import logo from '../assets/images/logo.png'
import CartComponent from './NavbarComponent/CartComponent';
import LanguageMenu from './NavbarComponent/Language';
import ProfileIcon from './NavbarComponent/ProfileIcon';
import LoginIcon from './NavbarComponent/LoginIcon';
import { Link } from 'react-router-dom';
import Dashboard from './NavbarComponent/Dashboard';
import HomeButton from './NavbarComponent/HomeButton';
import KitchenBtn from './NavbarComponent/KitchenBtn';
import BranchesBtn from './NavbarComponent/BranchesBtn';

const PREFIX = "Navbar";
const classes = {
    stickyHeader: `${PREFIX}-stickyHeader`,
    animationFade: `${PREFIX}-animationFade`,
    activeLink: `${PREFIX}-activeLink`,
    navbar: `${PREFIX}-navbar`,
};

const animationFade = keyframes`
    0% {
        top: -50px;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        top: 0;
        -webkit-transform: none;
        transform: none;
    }
`;

const Root = styled(AppBar)(({ theme }) => ({
    // borderBottom: `none`,
    // boxShadow: "none",
    top: 0,
    [`&.${classes.animationFade}`]: {
        display: "flex !important",
        animation: `${animationFade} 1s both`,
    },
    [`&.${classes.stickyHeader}`]: {
        top: "-50px",
        display: "none",
        background: theme.palette.background.default,
        boxShadow: theme.shadows[5]
    },
    [`& .${classes.navbar}`]: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
}));



function Navbar() {
    // const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [shouldShowHeader, setShouldShowHeader] = useState<boolean>(false);
    const [animationClass, setAnimationClass] = useState<string>('');

    // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorElNav(event.currentTarget);
    // };

    // const handleCloseNavMenu = () => {
    //     setAnchorElNav(null);
    // };

    const listenToScroll = () => {
        setShouldShowHeader(window.pageYOffset > 150);
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", listenToScroll);
        };
    }, []);

    useEffect(() => {
        if (shouldShowHeader) {
            setAnimationClass(classes.animationFade);
        } else {
            setAnimationClass('');
        }
    }, [shouldShowHeader]);

    // const screenWidth = useWidth();
    // const isScreenSmall = isWidthDown("sm", screenWidth);

    return (
        <>
            <Root
                position={shouldShowHeader ? "fixed" : "absolute"}
                sx={{ background: "transparent" }}
                className={clsx({
                    [classes.stickyHeader]: shouldShowHeader,
                    [animationClass]: shouldShowHeader,
                })}
            >
                <Container maxWidth="xl" className={classes.navbar}>
                    <Toolbar disableGutters sx={{ justifyContent: "space-between", py: 0.5 }}>
                        <Stack component={Link} to={"/"} mx={2}>
                            <img src={logo} alt='logo' height={60} srcSet={logo} />
                        </Stack>
                        <Stack direction={"row"} spacing={1} useFlexGap alignItems={"center"}>
                            <Stack direction={"row"} alignItems={"center"} spacing={1} useFlexGap>
                                {/* {webLinks && webLinks()} */}

                                <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                                    <LanguageMenu />
                                    <ProfileIcon />
                                    <Dashboard />
                                    <LoginIcon />
                                    <CartComponent />
                                </Stack>
                            </Stack>
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                {/* <LanguageMenu />
                            <ProfileIcon />
                            <LoginIcon />
                            <CartComponent /> */}
                                {/* <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="default"
                            >
                                <MenuIcon />
                            </IconButton> */}

                                {/* <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <Stack p={2} spacing={1}>
                                    <Stack direction={"row"} spacing={1}>
                                    </Stack>
                                </Stack>
                            </Menu> */}
                            </Box>
                        </Stack>
                    </Toolbar>
                </Container>
                <Container maxWidth="xl">
                    <Stack alignItems={"center"} direction={"row"} justifyContent={"center"} spacing={2} py={1}>
                        <HomeButton />
                        <KitchenBtn />
                        <BranchesBtn />
                    </Stack>
                </Container>
            </Root>
        </>
    );
}

export default Navbar;
