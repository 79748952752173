import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Save_Cart_ItemMutationVariables = Types.Exact<{
  input: Types.CartItemInput;
}>;


export type Save_Cart_ItemMutation = { __typename?: 'Mutation', saveCartItem: { __typename?: 'CartItem', id: number, price: number, quantity: number, product: { __typename?: 'Product', name: string, price: number, weight?: number | null, id: number, image?: { __typename?: 'Image', path: string } | null }, options: Array<{ __typename?: 'CartItemOption', id: number, value: boolean } | null>, addons: Array<{ __typename?: 'CartItemAddon', id: number, addon: { __typename?: 'Addon', price: number } } | null> } };

export type DeleteCartItemMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteCartItemMutation = { __typename?: 'Mutation', deleteCartItem: boolean };

export type Adjust_CartMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type Adjust_CartMutation = { __typename?: 'Mutation', adjustCart: boolean };

export type Update_UserMutationVariables = Types.Exact<{
  input: Types.UserInput;
}>;


export type Update_UserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number } };

export type Update_User_ImageMutationVariables = Types.Exact<{
  input: Types.UserImageInput;
}>;


export type Update_User_ImageMutation = { __typename?: 'Mutation', updateUserImage: { __typename?: 'User', id: number, image?: { __typename?: 'Image', path: string } | null } };

export type Change_PasswordMutationVariables = Types.Exact<{
  input: Types.ChangePasswordInput;
}>;


export type Change_PasswordMutation = { __typename?: 'Mutation', changePassword: boolean };

export type Reset_PasswordMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type Reset_PasswordMutation = { __typename?: 'Mutation', resetPassword?: boolean | null };

export type Toggle_Activate_UserMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type Toggle_Activate_UserMutation = { __typename?: 'Mutation', toggleActivateUser: boolean };


export const Save_Cart_ItemDocument = gql`
    mutation SAVE_CART_ITEM($input: CartItemInput!) {
  saveCartItem(input: $input) {
    id
    price
    product {
      name
      price
      weight
      id
      image {
        path
      }
    }
    quantity
    price
    options {
      id
      value
    }
    addons {
      id
      addon {
        price
      }
    }
  }
}
    `;
export type Save_Cart_ItemMutationFn = Apollo.MutationFunction<Save_Cart_ItemMutation, Save_Cart_ItemMutationVariables>;

/**
 * __useSave_Cart_ItemMutation__
 *
 * To run a mutation, you first call `useSave_Cart_ItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSave_Cart_ItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCartItemMutation, { data, loading, error }] = useSave_Cart_ItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSave_Cart_ItemMutation(baseOptions?: Apollo.MutationHookOptions<Save_Cart_ItemMutation, Save_Cart_ItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Save_Cart_ItemMutation, Save_Cart_ItemMutationVariables>(Save_Cart_ItemDocument, options);
      }
export type Save_Cart_ItemMutationHookResult = ReturnType<typeof useSave_Cart_ItemMutation>;
export type Save_Cart_ItemMutationResult = Apollo.MutationResult<Save_Cart_ItemMutation>;
export type Save_Cart_ItemMutationOptions = Apollo.BaseMutationOptions<Save_Cart_ItemMutation, Save_Cart_ItemMutationVariables>;
export const DeleteCartItemDocument = gql`
    mutation deleteCartItem($id: Int!) {
  deleteCartItem(id: $id)
}
    `;
export type DeleteCartItemMutationFn = Apollo.MutationFunction<DeleteCartItemMutation, DeleteCartItemMutationVariables>;

/**
 * __useDeleteCartItemMutation__
 *
 * To run a mutation, you first call `useDeleteCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCartItemMutation, { data, loading, error }] = useDeleteCartItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCartItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCartItemMutation, DeleteCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCartItemMutation, DeleteCartItemMutationVariables>(DeleteCartItemDocument, options);
      }
export type DeleteCartItemMutationHookResult = ReturnType<typeof useDeleteCartItemMutation>;
export type DeleteCartItemMutationResult = Apollo.MutationResult<DeleteCartItemMutation>;
export type DeleteCartItemMutationOptions = Apollo.BaseMutationOptions<DeleteCartItemMutation, DeleteCartItemMutationVariables>;
export const Adjust_CartDocument = gql`
    mutation ADJUST_CART {
  adjustCart
}
    `;
export type Adjust_CartMutationFn = Apollo.MutationFunction<Adjust_CartMutation, Adjust_CartMutationVariables>;

/**
 * __useAdjust_CartMutation__
 *
 * To run a mutation, you first call `useAdjust_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdjust_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adjustCartMutation, { data, loading, error }] = useAdjust_CartMutation({
 *   variables: {
 *   },
 * });
 */
export function useAdjust_CartMutation(baseOptions?: Apollo.MutationHookOptions<Adjust_CartMutation, Adjust_CartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Adjust_CartMutation, Adjust_CartMutationVariables>(Adjust_CartDocument, options);
      }
export type Adjust_CartMutationHookResult = ReturnType<typeof useAdjust_CartMutation>;
export type Adjust_CartMutationResult = Apollo.MutationResult<Adjust_CartMutation>;
export type Adjust_CartMutationOptions = Apollo.BaseMutationOptions<Adjust_CartMutation, Adjust_CartMutationVariables>;
export const Update_UserDocument = gql`
    mutation UPDATE_USER($input: UserInput!) {
  updateUser(input: $input) {
    id
  }
}
    `;
export type Update_UserMutationFn = Apollo.MutationFunction<Update_UserMutation, Update_UserMutationVariables>;

/**
 * __useUpdate_UserMutation__
 *
 * To run a mutation, you first call `useUpdate_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdate_UserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_UserMutation(baseOptions?: Apollo.MutationHookOptions<Update_UserMutation, Update_UserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_UserMutation, Update_UserMutationVariables>(Update_UserDocument, options);
      }
export type Update_UserMutationHookResult = ReturnType<typeof useUpdate_UserMutation>;
export type Update_UserMutationResult = Apollo.MutationResult<Update_UserMutation>;
export type Update_UserMutationOptions = Apollo.BaseMutationOptions<Update_UserMutation, Update_UserMutationVariables>;
export const Update_User_ImageDocument = gql`
    mutation UPDATE_USER_IMAGE($input: UserImageInput!) {
  updateUserImage(input: $input) {
    id
    image {
      path
    }
  }
}
    `;
export type Update_User_ImageMutationFn = Apollo.MutationFunction<Update_User_ImageMutation, Update_User_ImageMutationVariables>;

/**
 * __useUpdate_User_ImageMutation__
 *
 * To run a mutation, you first call `useUpdate_User_ImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_User_ImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserImageMutation, { data, loading, error }] = useUpdate_User_ImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_User_ImageMutation(baseOptions?: Apollo.MutationHookOptions<Update_User_ImageMutation, Update_User_ImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_User_ImageMutation, Update_User_ImageMutationVariables>(Update_User_ImageDocument, options);
      }
export type Update_User_ImageMutationHookResult = ReturnType<typeof useUpdate_User_ImageMutation>;
export type Update_User_ImageMutationResult = Apollo.MutationResult<Update_User_ImageMutation>;
export type Update_User_ImageMutationOptions = Apollo.BaseMutationOptions<Update_User_ImageMutation, Update_User_ImageMutationVariables>;
export const Change_PasswordDocument = gql`
    mutation CHANGE_PASSWORD($input: ChangePasswordInput!) {
  changePassword(input: $input)
}
    `;
export type Change_PasswordMutationFn = Apollo.MutationFunction<Change_PasswordMutation, Change_PasswordMutationVariables>;

/**
 * __useChange_PasswordMutation__
 *
 * To run a mutation, you first call `useChange_PasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChange_PasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChange_PasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChange_PasswordMutation(baseOptions?: Apollo.MutationHookOptions<Change_PasswordMutation, Change_PasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Change_PasswordMutation, Change_PasswordMutationVariables>(Change_PasswordDocument, options);
      }
export type Change_PasswordMutationHookResult = ReturnType<typeof useChange_PasswordMutation>;
export type Change_PasswordMutationResult = Apollo.MutationResult<Change_PasswordMutation>;
export type Change_PasswordMutationOptions = Apollo.BaseMutationOptions<Change_PasswordMutation, Change_PasswordMutationVariables>;
export const Reset_PasswordDocument = gql`
    mutation RESET_PASSWORD($email: String!) {
  resetPassword(email: $email)
}
    `;
export type Reset_PasswordMutationFn = Apollo.MutationFunction<Reset_PasswordMutation, Reset_PasswordMutationVariables>;

/**
 * __useReset_PasswordMutation__
 *
 * To run a mutation, you first call `useReset_PasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReset_PasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useReset_PasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useReset_PasswordMutation(baseOptions?: Apollo.MutationHookOptions<Reset_PasswordMutation, Reset_PasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Reset_PasswordMutation, Reset_PasswordMutationVariables>(Reset_PasswordDocument, options);
      }
export type Reset_PasswordMutationHookResult = ReturnType<typeof useReset_PasswordMutation>;
export type Reset_PasswordMutationResult = Apollo.MutationResult<Reset_PasswordMutation>;
export type Reset_PasswordMutationOptions = Apollo.BaseMutationOptions<Reset_PasswordMutation, Reset_PasswordMutationVariables>;
export const Toggle_Activate_UserDocument = gql`
    mutation TOGGLE_ACTIVATE_USER($id: Int!) {
  toggleActivateUser(id: $id)
}
    `;
export type Toggle_Activate_UserMutationFn = Apollo.MutationFunction<Toggle_Activate_UserMutation, Toggle_Activate_UserMutationVariables>;

/**
 * __useToggle_Activate_UserMutation__
 *
 * To run a mutation, you first call `useToggle_Activate_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggle_Activate_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleActivateUserMutation, { data, loading, error }] = useToggle_Activate_UserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggle_Activate_UserMutation(baseOptions?: Apollo.MutationHookOptions<Toggle_Activate_UserMutation, Toggle_Activate_UserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Toggle_Activate_UserMutation, Toggle_Activate_UserMutationVariables>(Toggle_Activate_UserDocument, options);
      }
export type Toggle_Activate_UserMutationHookResult = ReturnType<typeof useToggle_Activate_UserMutation>;
export type Toggle_Activate_UserMutationResult = Apollo.MutationResult<Toggle_Activate_UserMutation>;
export type Toggle_Activate_UserMutationOptions = Apollo.BaseMutationOptions<Toggle_Activate_UserMutation, Toggle_Activate_UserMutationVariables>;