import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import ControlMUITextField from "../MUI/ControlMUItextField";
import CustomDialog from "../MUI/CustomDialog";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useReset_PasswordMutation } from "../../graphql/mutations/user.generated";
import { setValidationError } from "../helperFunctions/setValidationError";
import { toast } from "sonner";
import { LockReset } from "@mui/icons-material";

const ForgottenPassword = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)


    const handleOpenChangePassword = () => {
        setOpen(true)
    }
    const handleCloseChangePassword = () => {
        setOpen(false)
    }

    const { handleSubmit, control, setError } = useForm<{ email: string }>()

    const [resetPasswordMutation, { loading }] = useReset_PasswordMutation()

    const onSubmit = ({ email }: { email: string }) => {
        resetPasswordMutation({
            variables: {
                email
            }
        }).then(() => {
            toast.success(t("codeSentToEmail"))
            handleCloseChangePassword()
        }).catch(({ graphQLErrors }) => {
            setValidationError({
                graphQLErrors,
                setError
            })
        })
    }

    return (
        <>
            <Button
                size="small"
                variant="outlined"
                onClick={handleOpenChangePassword}
                color="info"
                endIcon={<LockReset />}
            >
                {t("forgottenPassword")}
            </Button>
            {open && <CustomDialog
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                    noValidate: true
                }}
                open={open}
                handleClose={handleCloseChangePassword}
                title={t("forgottenPassword")}
                content={
                    <Stack spacing={2}>
                        <ControlMUITextField
                            control={control}
                            name='email'
                            label={t('email')}
                            rules={{
                                required: t("fieldIsRequired"),
                            }}
                        />
                    </Stack>
                }
                buttonAction={
                    <LoadingButton
                        loading={loading}
                        variant='contained'
                        type='submit'
                    >
                        {t("submit")}
                    </LoadingButton>
                }
            />}
        </>
    )
}

export default ForgottenPassword