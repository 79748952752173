import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type List_BranchesQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  page: Types.Scalars['Int']['input'];
}>;


export type List_BranchesQuery = { __typename?: 'Query', listBranches: { __typename?: 'BranchPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number }, data: Array<{ __typename?: 'Branch', id: number, name: string, address: string, longitude: number, latitude: number, telephone?: string | null, whatsapp?: string | null, startTime: any, endTime: any, date: any, image?: { __typename?: 'Image', path: string } | null, tables: Array<{ __typename?: 'Table', id: number } | null> }> } };

export type List_Tables_BranchQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type List_Tables_BranchQuery = { __typename?: 'Query', branch: { __typename?: 'Branch', tables: Array<{ __typename?: 'Table', id: number, name: string, chairsNumber: number, date: any, times: Array<{ __typename?: 'TableTime', id: number, fromTime: any, toTime: any } | null> } | null> } };

export type BranchQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type BranchQuery = { __typename?: 'Query', branch: { __typename?: 'Branch', id: number, name: string, address: string, longitude: number, latitude: number, telephone?: string | null, whatsapp?: string | null, startTime: any, endTime: any, date: any, image?: { __typename?: 'Image', path: string } | null, tables: Array<{ __typename?: 'Table', id: number, name: string, chairsNumber: number, date: any } | null> } };

export type ListAppointmentsQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListAppointmentsFilterInput>;
  first: Types.Scalars['Int']['input'];
  page: Types.Scalars['Int']['input'];
}>;


export type ListAppointmentsQuery = { __typename?: 'Query', listAppointments: { __typename?: 'AppointmentPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number }, data: Array<{ __typename?: 'Appointment', id: number, appointmentDate: any, status: Types.AppointmentStatusCode, tableTime: { __typename?: 'TableTime', id: number, fromTime: any, toTime: any, table: { __typename?: 'Table', name: string, id: number, branch: { __typename?: 'Branch', id: number, name: string } } }, user: { __typename?: 'User', firstName: string, lastName: string, id: number }, order: { __typename?: 'Order', id: number, code: string } }> } };

export type List_Branches_DropdownQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type List_Branches_DropdownQuery = { __typename?: 'Query', listBranchesDropdown: Array<{ __typename?: 'DropdownEntry', id: number, name: string } | null> };

export type List_Tables_DropdownQueryVariables = Types.Exact<{
  branchId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type List_Tables_DropdownQuery = { __typename?: 'Query', listTablesDropdown: Array<{ __typename?: 'DropdownEntry', id: number, name: string } | null> };

export type List_Tables_Times_DropdownQueryVariables = Types.Exact<{
  input: Types.ListTableTimesFilterInput;
}>;


export type List_Tables_Times_DropdownQuery = { __typename?: 'Query', listTableTimesDropdown: Array<{ __typename?: 'DropdownEntry', id: number, name: string } | null> };


export const List_BranchesDocument = gql`
    query LIST_BRANCHES($first: Int!, $page: Int!) {
  listBranches(first: $first, page: $page) {
    paginatorInfo {
      total
    }
    data {
      id
      name
      address
      longitude
      latitude
      telephone
      whatsapp
      startTime
      endTime
      date
      image {
        path
      }
      tables {
        id
      }
    }
  }
}
    `;

/**
 * __useList_BranchesQuery__
 *
 * To run a query within a React component, call `useList_BranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_BranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_BranchesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useList_BranchesQuery(baseOptions: Apollo.QueryHookOptions<List_BranchesQuery, List_BranchesQueryVariables> & ({ variables: List_BranchesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_BranchesQuery, List_BranchesQueryVariables>(List_BranchesDocument, options);
      }
export function useList_BranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_BranchesQuery, List_BranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_BranchesQuery, List_BranchesQueryVariables>(List_BranchesDocument, options);
        }
export function useList_BranchesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_BranchesQuery, List_BranchesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_BranchesQuery, List_BranchesQueryVariables>(List_BranchesDocument, options);
        }
export type List_BranchesQueryHookResult = ReturnType<typeof useList_BranchesQuery>;
export type List_BranchesLazyQueryHookResult = ReturnType<typeof useList_BranchesLazyQuery>;
export type List_BranchesSuspenseQueryHookResult = ReturnType<typeof useList_BranchesSuspenseQuery>;
export type List_BranchesQueryResult = Apollo.QueryResult<List_BranchesQuery, List_BranchesQueryVariables>;
export const List_Tables_BranchDocument = gql`
    query LIST_TABLES_BRANCH($id: Int!) {
  branch(id: $id) {
    tables {
      id
      name
      chairsNumber
      date
      times {
        id
        fromTime
        toTime
      }
    }
  }
}
    `;

/**
 * __useList_Tables_BranchQuery__
 *
 * To run a query within a React component, call `useList_Tables_BranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Tables_BranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Tables_BranchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useList_Tables_BranchQuery(baseOptions: Apollo.QueryHookOptions<List_Tables_BranchQuery, List_Tables_BranchQueryVariables> & ({ variables: List_Tables_BranchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_Tables_BranchQuery, List_Tables_BranchQueryVariables>(List_Tables_BranchDocument, options);
      }
export function useList_Tables_BranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Tables_BranchQuery, List_Tables_BranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_Tables_BranchQuery, List_Tables_BranchQueryVariables>(List_Tables_BranchDocument, options);
        }
export function useList_Tables_BranchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_Tables_BranchQuery, List_Tables_BranchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_Tables_BranchQuery, List_Tables_BranchQueryVariables>(List_Tables_BranchDocument, options);
        }
export type List_Tables_BranchQueryHookResult = ReturnType<typeof useList_Tables_BranchQuery>;
export type List_Tables_BranchLazyQueryHookResult = ReturnType<typeof useList_Tables_BranchLazyQuery>;
export type List_Tables_BranchSuspenseQueryHookResult = ReturnType<typeof useList_Tables_BranchSuspenseQuery>;
export type List_Tables_BranchQueryResult = Apollo.QueryResult<List_Tables_BranchQuery, List_Tables_BranchQueryVariables>;
export const BranchDocument = gql`
    query BRANCH($id: Int!) {
  branch(id: $id) {
    id
    name
    address
    longitude
    latitude
    telephone
    whatsapp
    startTime
    endTime
    date
    image {
      path
    }
    tables {
      id
      name
      chairsNumber
      date
    }
  }
}
    `;

/**
 * __useBranchQuery__
 *
 * To run a query within a React component, call `useBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBranchQuery(baseOptions: Apollo.QueryHookOptions<BranchQuery, BranchQueryVariables> & ({ variables: BranchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchQuery, BranchQueryVariables>(BranchDocument, options);
      }
export function useBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchQuery, BranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchQuery, BranchQueryVariables>(BranchDocument, options);
        }
export function useBranchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchQuery, BranchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BranchQuery, BranchQueryVariables>(BranchDocument, options);
        }
export type BranchQueryHookResult = ReturnType<typeof useBranchQuery>;
export type BranchLazyQueryHookResult = ReturnType<typeof useBranchLazyQuery>;
export type BranchSuspenseQueryHookResult = ReturnType<typeof useBranchSuspenseQuery>;
export type BranchQueryResult = Apollo.QueryResult<BranchQuery, BranchQueryVariables>;
export const ListAppointmentsDocument = gql`
    query listAppointments($input: ListAppointmentsFilterInput, $first: Int!, $page: Int!) {
  listAppointments(input: $input, first: $first, page: $page) {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
    data {
      id
      appointmentDate
      status
      tableTime {
        id
        fromTime
        toTime
        table {
          name
          id
          branch {
            id
            name
          }
        }
      }
      user {
        firstName
        lastName
        id
      }
      order {
        id
        code
      }
    }
  }
}
    `;

/**
 * __useListAppointmentsQuery__
 *
 * To run a query within a React component, call `useListAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAppointmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<ListAppointmentsQuery, ListAppointmentsQueryVariables> & ({ variables: ListAppointmentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAppointmentsQuery, ListAppointmentsQueryVariables>(ListAppointmentsDocument, options);
      }
export function useListAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAppointmentsQuery, ListAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAppointmentsQuery, ListAppointmentsQueryVariables>(ListAppointmentsDocument, options);
        }
export function useListAppointmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAppointmentsQuery, ListAppointmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAppointmentsQuery, ListAppointmentsQueryVariables>(ListAppointmentsDocument, options);
        }
export type ListAppointmentsQueryHookResult = ReturnType<typeof useListAppointmentsQuery>;
export type ListAppointmentsLazyQueryHookResult = ReturnType<typeof useListAppointmentsLazyQuery>;
export type ListAppointmentsSuspenseQueryHookResult = ReturnType<typeof useListAppointmentsSuspenseQuery>;
export type ListAppointmentsQueryResult = Apollo.QueryResult<ListAppointmentsQuery, ListAppointmentsQueryVariables>;
export const List_Branches_DropdownDocument = gql`
    query LIST_BRANCHES_DROPDOWN {
  listBranchesDropdown {
    id
    name
  }
}
    `;

/**
 * __useList_Branches_DropdownQuery__
 *
 * To run a query within a React component, call `useList_Branches_DropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Branches_DropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Branches_DropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useList_Branches_DropdownQuery(baseOptions?: Apollo.QueryHookOptions<List_Branches_DropdownQuery, List_Branches_DropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_Branches_DropdownQuery, List_Branches_DropdownQueryVariables>(List_Branches_DropdownDocument, options);
      }
export function useList_Branches_DropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Branches_DropdownQuery, List_Branches_DropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_Branches_DropdownQuery, List_Branches_DropdownQueryVariables>(List_Branches_DropdownDocument, options);
        }
export function useList_Branches_DropdownSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_Branches_DropdownQuery, List_Branches_DropdownQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_Branches_DropdownQuery, List_Branches_DropdownQueryVariables>(List_Branches_DropdownDocument, options);
        }
export type List_Branches_DropdownQueryHookResult = ReturnType<typeof useList_Branches_DropdownQuery>;
export type List_Branches_DropdownLazyQueryHookResult = ReturnType<typeof useList_Branches_DropdownLazyQuery>;
export type List_Branches_DropdownSuspenseQueryHookResult = ReturnType<typeof useList_Branches_DropdownSuspenseQuery>;
export type List_Branches_DropdownQueryResult = Apollo.QueryResult<List_Branches_DropdownQuery, List_Branches_DropdownQueryVariables>;
export const List_Tables_DropdownDocument = gql`
    query LIST_TABLES_DROPDOWN($branchId: Int) {
  listTablesDropdown(branchId: $branchId) {
    id
    name
  }
}
    `;

/**
 * __useList_Tables_DropdownQuery__
 *
 * To run a query within a React component, call `useList_Tables_DropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Tables_DropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Tables_DropdownQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useList_Tables_DropdownQuery(baseOptions?: Apollo.QueryHookOptions<List_Tables_DropdownQuery, List_Tables_DropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_Tables_DropdownQuery, List_Tables_DropdownQueryVariables>(List_Tables_DropdownDocument, options);
      }
export function useList_Tables_DropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Tables_DropdownQuery, List_Tables_DropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_Tables_DropdownQuery, List_Tables_DropdownQueryVariables>(List_Tables_DropdownDocument, options);
        }
export function useList_Tables_DropdownSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_Tables_DropdownQuery, List_Tables_DropdownQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_Tables_DropdownQuery, List_Tables_DropdownQueryVariables>(List_Tables_DropdownDocument, options);
        }
export type List_Tables_DropdownQueryHookResult = ReturnType<typeof useList_Tables_DropdownQuery>;
export type List_Tables_DropdownLazyQueryHookResult = ReturnType<typeof useList_Tables_DropdownLazyQuery>;
export type List_Tables_DropdownSuspenseQueryHookResult = ReturnType<typeof useList_Tables_DropdownSuspenseQuery>;
export type List_Tables_DropdownQueryResult = Apollo.QueryResult<List_Tables_DropdownQuery, List_Tables_DropdownQueryVariables>;
export const List_Tables_Times_DropdownDocument = gql`
    query LIST_TABLES_TIMES_DROPDOWN($input: ListTableTimesFilterInput!) {
  listTableTimesDropdown(input: $input) {
    id
    name
  }
}
    `;

/**
 * __useList_Tables_Times_DropdownQuery__
 *
 * To run a query within a React component, call `useList_Tables_Times_DropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Tables_Times_DropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Tables_Times_DropdownQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useList_Tables_Times_DropdownQuery(baseOptions: Apollo.QueryHookOptions<List_Tables_Times_DropdownQuery, List_Tables_Times_DropdownQueryVariables> & ({ variables: List_Tables_Times_DropdownQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_Tables_Times_DropdownQuery, List_Tables_Times_DropdownQueryVariables>(List_Tables_Times_DropdownDocument, options);
      }
export function useList_Tables_Times_DropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Tables_Times_DropdownQuery, List_Tables_Times_DropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_Tables_Times_DropdownQuery, List_Tables_Times_DropdownQueryVariables>(List_Tables_Times_DropdownDocument, options);
        }
export function useList_Tables_Times_DropdownSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_Tables_Times_DropdownQuery, List_Tables_Times_DropdownQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_Tables_Times_DropdownQuery, List_Tables_Times_DropdownQueryVariables>(List_Tables_Times_DropdownDocument, options);
        }
export type List_Tables_Times_DropdownQueryHookResult = ReturnType<typeof useList_Tables_Times_DropdownQuery>;
export type List_Tables_Times_DropdownLazyQueryHookResult = ReturnType<typeof useList_Tables_Times_DropdownLazyQuery>;
export type List_Tables_Times_DropdownSuspenseQueryHookResult = ReturnType<typeof useList_Tables_Times_DropdownSuspenseQuery>;
export type List_Tables_Times_DropdownQueryResult = Apollo.QueryResult<List_Tables_Times_DropdownQuery, List_Tables_Times_DropdownQueryVariables>;