import { useTranslation } from 'react-i18next'
import ButtonLink from '../ButtonLink'
import { useLocation } from 'react-router-dom'
import StorefrontIcon from '@mui/icons-material/Storefront';
const BranchesBtn = () => {
    const { t } = useTranslation()

    const location = useLocation()
    return (
        <ButtonLink startIcon={<StorefrontIcon />} variant={location.pathname === "/branches" ? 'contained' : 'outlined'} text={t("branches")} to='/branches' size='small' />
    )
}

export default BranchesBtn