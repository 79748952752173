import { useTranslation } from 'react-i18next'
import ButtonLink from '../ButtonLink'
import { Home } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

const HomeButton = () => {
    const { t } = useTranslation()

    const location = useLocation()
    return (
        <ButtonLink startIcon={<Home />} variant={location.pathname === "/" ? 'contained' : 'outlined'} text={t("home")} to='/' size='small' />
    )
}

export default HomeButton