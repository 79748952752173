import { Button, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Paper, Stack, Switch, Typography } from '@mui/material'
import Imag from '../../../../assets/images/sandwich.jpg'
import TextFieldCustom from '../../../../Components/MUI/TextFieldCustom'
import { useTranslation } from 'react-i18next'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { formatted } from '../../../../Components/helperFunctions/function'
import { getBackendUri } from '../../../../Components/helperFunctions/getBackendUri'
import DeleteButton from './DeleteButton'
import { useSave_Cart_ItemMutation } from '../../../../graphql/mutations/user.generated'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { HiViewGridAdd } from "react-icons/hi";
import { useDelete_Cart_Item_AddonMutation } from '../../../../graphql/queries/user.generated'
import AddonsDialog from './AddonsDialog'
import { toast } from 'sonner'
import { Delete } from '@mui/icons-material'

type AddonsType = ({
    __typename?: "CartItemAddon";
    id: number;
    addon: {
        __typename?: "Addon";
        name: string;
        price: number;
        id: number;
    };
} | null)[] | undefined


type AddonType = {
    __typename?: "CartItemAddon";
    id: number;
    addon: {
        __typename?: "Addon";
        name: string;
        price: number;
        id: number;
    };
} | null

type itemTypes = {
    id: number
    name: string
    price: number
    totalPrice: number
    quantity: number
    image?: string
    productId: number,
    options?: ({
        __typename?: "CartItemOption";
        id: number;
        value: boolean;
        option: {
            __typename?: "Option";
            id: number;
            name: string;
        };
    } | null)[]
    addons: AddonsType
    ifAddons: boolean
}

let increaseTimer: NodeJS.Timeout | null = null;
var decreaseTimer: NodeJS.Timeout | null = null;
var changeTimer: NodeJS.Timeout | null = null;
var changeOption: NodeJS.Timeout | null = null;

const CartCard = ({
    item,
    setError,
    updateQueries
}: {
    item: itemTypes
    updateQueries: () => void; // Update type to match
    setError: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const [optionsChecked, setOptionsChecked] = useState(item.options?.map(e => ({ id: e?.id!, optionId: e?.option.id!, value: e?.value! })))
    const { t } = useTranslation()
    const [value, setValue] = useState<string | number>(item.quantity)

    const [openAddons, setOpenAddons] = useState(false)
    const [saveCartItemMutation, { loading: saveCartItemLoading }] = useSave_Cart_ItemMutation();

    const saveFunction = (newQuantity?: number, newOption?: any) => {
        saveCartItemMutation({
            variables: {
                input: {
                    id: item.id,
                    quantity: newQuantity ?? Number(value),
                    productId: item.productId,
                    addons: item.addons?.map(e => ({ id: e?.id!, addonId: e?.addon.id! })),
                    options: newOption
                }
            }
        }).then(() => updateQueries()).catch(() => {
            toast.error(t("cartWrong"))
            setError(true)
        })
    }

    const decreaseQuantity = (quantity: number) => {
        const currentQuantity = quantity;
        if (currentQuantity === 1) {
            return;
        }
        // Calculate new quantity, ensuring it does not exceed totalQuantity
        const newQuantity = currentQuantity - 1 < 1 ? 1 : currentQuantity - 1;
        // Update the form value

        setValue(newQuantity)
        // Clear the previous timer if it exists
        if (changeTimer) {
            clearTimeout(changeTimer);
        }
        if (decreaseTimer) {
            clearTimeout(decreaseTimer);
        }
        if (increaseTimer) {
            clearTimeout(increaseTimer);
        }
        if (changeOption) {
            clearTimeout(changeOption);
        }
        // Set a new timer to handle the mutation after a delay
        decreaseTimer = setTimeout(async () => {
            saveFunction(newQuantity)
        }, 1000);
    };

    const increaseQuantity = (
        quantity: number,
        totalQuantity: number
    ) => {
        const currentQuantity = quantity;
        if (currentQuantity === totalQuantity) {
            return;
        }
        // Calculate new quantity, ensuring it does not exceed totalQuantity
        const newQuantity =
            currentQuantity + 1 > totalQuantity ? totalQuantity : currentQuantity + 1;
        // Update the form value
        setValue(newQuantity)
        // Clear the previous timer if it exists
        if (changeTimer) {
            clearTimeout(changeTimer);
        }
        if (decreaseTimer) {
            clearTimeout(decreaseTimer);
        }
        if (increaseTimer) {
            clearTimeout(increaseTimer);
        }
        if (changeOption) {
            clearTimeout(changeOption);
        }
        // Set a new timer to handle the mutation after a delay
        increaseTimer = setTimeout(async () => {
            saveFunction(newQuantity)
        }, 1000);
    };

    const changeQuantity = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        totalQuantity: number
    ) => {
        if (changeTimer) {
            clearTimeout(changeTimer);
        }
        if (decreaseTimer) {
            clearTimeout(decreaseTimer);
        }
        if (increaseTimer) {
            clearTimeout(increaseTimer);
        }
        if (changeOption) {
            clearTimeout(changeOption);
        }
        // Set a new timer to handle the mutation after a delay
        changeTimer = setTimeout(async () => {
            const value = event.target.value;
            let newValue = "";
            if (+value < 1 || event.target.value === "") {

                newValue = "1";
                setValue(1);
            } else if (+value > totalQuantity) {
                newValue = `${totalQuantity}`;
                setValue(totalQuantity);
            } else {
                newValue = value;
            }

            saveFunction(Number(newValue))
        }, 1000);
    };

    useEffect(() => {
        setValue(item.quantity)
        return () => { }
    }, [item.quantity])


    const handleChangeOption = (id: number, sewValue: boolean) => {
        const updatedOptions = optionsChecked?.map((e) =>
            e.id === id
                ? { ...e, value: !sewValue } // Update the value when id matches
                : e // Keep the other objects unchanged
        );

        setOptionsChecked(updatedOptions);

        if (changeTimer) {
            clearTimeout(changeTimer);
        }
        if (decreaseTimer) {
            clearTimeout(decreaseTimer);
        }
        if (increaseTimer) {
            clearTimeout(increaseTimer);
        }
        if (changeOption) {
            clearTimeout(changeOption);
        }

        changeOption = setTimeout(async () => {
            saveFunction(Number(value), updatedOptions)
        }, 1000);
    };

    const handleCloseAddons = () => {
        setOpenAddons(false)
    }
    const handleOpenAddons = () => {
        setOpenAddons(true)
    }


    return (
        <Stack component={Paper} direction={"row"} justifyContent={"space-between"} p={2}>
            {item.addons && item.ifAddons && openAddons &&
                <AddonsDialog
                    open={openAddons}
                    handleClose={handleCloseAddons}
                    productId={item.productId}
                    addons={item.addons?.map(e => e?.addon.id!)}
                    option={optionsChecked}
                    quantity={value}
                    refetch={updateQueries}
                    itemId={item.id}
                />
            }
            <Stack direction={"row"} spacing={2} flexWrap={"wrap"} useFlexGap alignItems={"flex-start"}>
                <Link to={`/products/${item.productId}/${item.name}`}>
                    <Stack component={Paper} elevation={4} width={120} height={120} sx={{ borderRadius: "20px", overflow: "hidden" }}>
                        <img src={item.image ? getBackendUri(item.image) : Imag} alt="ds" width={"100%"} height={"100%"} style={{ objectFit: "fill" }} />
                    </Stack>
                </Link>
                <Stack spacing={1} justifyContent={"space-around"}>
                    <Typography fontSize={18} fontWeight={"bold"}>{item.name}</Typography>

                    {item.ifAddons && <Stack alignItems={"flex-start"}>
                        <Button endIcon={<HiViewGridAdd />} variant='outlined' onClick={handleOpenAddons} size='small'>
                            {t("addons")}
                        </Button>
                    </Stack>}
                    <Typography fontSize={18}>{t("price")}: {formatted(item.price)}</Typography>
                    {item.options && item.options.length > 0 && <Stack>
                        <Stack direction={"row"} spacing={1} alignItems={"center"} flexWrap={"wrap"} useFlexGap>
                            <FormControl component="fieldset" variant="standard">
                                <FormLabel component="legend">
                                    <Typography color="primary" fontSize={18}>{t("options")}</Typography>
                                </FormLabel>
                                <FormGroup>
                                    {optionsChecked?.map(option =>
                                        <Stack direction={"row"} useFlexGap key={option?.id}>
                                            {/* <Typography>{option?.option?.name}:</Typography> */}
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={option.value}
                                                        onChange={() => handleChangeOption(option?.id!, option?.value!)}
                                                    />
                                                }
                                                label={item.options?.find(e => e?.option.id === option.optionId)?.option.name} />
                                        </Stack>
                                    )}
                                </FormGroup>
                            </FormControl>

                        </Stack>
                    </Stack>
                    }
                    {item.addons && item.addons.length > 0 && <Stack>
                        <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">
                                <Typography color="primary" fontSize={18}>{t("addons")}</Typography>
                            </FormLabel>
                            {item.addons?.map(addon =>
                                <AddonsGroup addon={addon} refetch={updateQueries} key={addon?.id} />
                            )}
                        </FormControl>
                    </Stack>
                    }
                    <Stack>
                        <TextFieldCustom
                            disabled={saveCartItemLoading}
                            label={t("quantity")}
                            type='number'
                            // value={item?.quantity}
                            value={value}
                            sx={{ width: '20ch', "& .MuiInputBase-input": { textAlign: "center" } }}
                            onChange={(event) => {
                                setValue(event.target.value)
                                changeQuantity(event, 100)
                            }}
                            slotProps={{
                                input: {
                                    inputProps: {
                                        min: 0,
                                        max: 100,
                                    },
                                    startAdornment:
                                        <IconButton
                                            disabled={+value <= 1 || saveCartItemLoading}
                                            onClick={() => decreaseQuantity(+value)}
                                        >
                                            <FaMinus fontSize={15} />
                                        </IconButton>,
                                    endAdornment:
                                        <IconButton
                                            disabled={saveCartItemLoading || +value === 100}
                                            onClick={() => increaseQuantity(+value, 100)}
                                        >
                                            <FaPlus fontSize={15} />
                                        </IconButton>,
                                }
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Stack alignItems={"flex-end"} justifyContent={"space-between"}>
                <Typography color='text.secondary'>{t("totalPrice")}:
                    <Typography component={'span'} color='primary.main' px={1}>
                        {formatted(item.totalPrice)}
                    </Typography>
                </Typography>
                <DeleteButton id={item.id} refetch={updateQueries} />
            </Stack>
        </Stack >
    )
}

export default CartCard


const AddonsGroup = ({
    addon,
    refetch
}: {
    addon: AddonType,
    refetch: () => void
}) => {
    const [deleteCartItemAddonMutation, { data, loading }] = useDelete_Cart_Item_AddonMutation();

    const handleChangeAddon = (id: number) => {
        deleteCartItemAddonMutation({
            variables: {
                id
            }
        }).then(() => {
            refetch()
        }).catch(({ graphQLErrors }) => {
            console.log(graphQLErrors);
        })
    }

    return <Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <FaMinus size={12} />
            <Typography>{addon?.addon.name}:</Typography>
            <Typography>{formatted(addon?.addon.price ?? 0)}</Typography>
            {loading ?
                <CircularProgress size={15} /> :
                <IconButton onClick={() => handleChangeAddon(addon?.id!)} color='error' size='small' disabled={data?.deleteCartItemAddon}>
                    <Delete fontSize='inherit' />
                </IconButton>
            }
        </Stack>
    </Stack>
}