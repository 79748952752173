import { Avatar, Button, Grid2 as Grid, Menu, MenuItem, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useOrder_AdminQuery } from "../../../graphql/queries/orders.generated";
import Loading from "../../../Components/Loading";
import ButtonLink from "../../../Components/ButtonLink";
import { Article, Forward, MoreVert, Person, Place } from "@mui/icons-material";
import { AddressData } from "../../customer/Components/AddressPaper";
import { dateFormatLL, dateTimeFormatA } from "../../../Components/helperFunctions/dateFunctions";
import { DeliveryTypeNames, paymentTypeNames } from "../../../types";
import { getBackendUri } from "../../../Components/helperFunctions/getBackendUri";
import BooleanCell from "../../../Components/MUI/BooleanCell";
import noImage from "../../../assets/images/no-image-svgrepo-com.svg"
import { formatted } from "../../../Components/helperFunctions/function";
import { LoadingButton } from "@mui/lab";
import CustomDialog from "../../../Components/MUI/CustomDialog";
import { OrderStatusCode, UpdateOrderStatusInput } from "../../../graphql/types";
import { useUpdate_Order_StatusMutation } from "../../../graphql/mutations/orders.generated";
import { useState } from "react";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import LabelValue from "../../../Components/LabelValue";
import { TableCellColor } from "../../../Components/CellColor";

const PREFIX = "orders-view";
const classes = {
    image: `${PREFIX}-image`,
    imageWrapper: `${PREFIX}-imageWrapper`,
    divider: `${PREFIX}-divider`,
};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.imageWrapper}`]: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "8px",
        overflow: "hidden",
        padding: "2px"
    },
    [`& .${classes.image}`]: {
        objectFit: "cover",
        borderRadius: "5px",
    },
    [`& .${classes.divider}`]: {
        background: theme.palette.divider
    },
}));

const statusMenu = [
    {
        title: OrderStatusCode.Preparing.toLowerCase(),
        code: OrderStatusCode.Preparing,
    },
    {
        title: OrderStatusCode.Prepared.toLowerCase(),
        code: OrderStatusCode.Prepared,
    },
    {
        title: OrderStatusCode.Delivering.toLowerCase(),
        code: OrderStatusCode.Delivering,
    },
    {
        title: OrderStatusCode.Completed.toLowerCase(),
        code: OrderStatusCode.Completed,
    },
    {
        title: "cancel",
        code: OrderStatusCode.Cancelled,
    }
]

const OrderViewAdmin = () => {
    const { id } = useParams()
    const { t, i18n } = useTranslation()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const { data, loading, refetch } = useOrder_AdminQuery({
        fetchPolicy: "no-cache",
        variables: {
            id: parseInt(id!)
        },
    });

    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState<any>(null)

    const [updateOrderStatusMutation, { loading: updateOrderStatusLoading }] = useUpdate_Order_StatusMutation();

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = (status: any) => {
        setAnchorEl(null)
        setStatus(status)
        setOpen(true)
    }

    const updateStatusFun = () => {
        updateOrderStatusMutation({
            variables: {
                input: {
                    id: +id!,
                    status: status
                }
            }
        }).then(() => {
            refetch()
            handleClose()
        }).catch(({ graphQLErrors }) => toast.error(graphQLErrors[0].message))
    }

    const { handleSubmit } = useForm<UpdateOrderStatusInput>()

    return loading ? <Loading height="300px" /> : !data ? <div>no data</div> : (
        <Root mt={2} spacing={2}>
            {open && <CustomDialog
                open={open}
                handleClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(updateStatusFun),
                    noValidate: true
                }}
                content={
                    <Stack alignItems={"center"} py={2}>
                        <Typography>{t("areYouSure")}</Typography>
                    </Stack>
                }
                buttonAction={
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={updateOrderStatusLoading}
                        size="small"
                    >
                        {t("submit")}
                    </LoadingButton>
                }
            />}

            <Stack direction={"row"} spacing={1} flexWrap={"wrap"} alignItems={"center"} useFlexGap>
                <ButtonLink to="/dashboard/orders" text={t("orders")} size="small" startIcon={<Forward sx={{ transform: i18n.dir() === "rtl" ? "" : "rotate(180deg)" }} />} variant="outlined" />
                <Typography fontWeight={"bolder"} fontSize={18}>{t("orderDetails")}</Typography>
                <Typography fontSize={18}>( {data.order.code} )</Typography>
            </Stack>
            <Stack direction={"row"} spacing={1} flexWrap={"wrap"} alignItems={"center"} justifyContent={"space-between"} useFlexGap>
                <LabelValue
                    label={t("orderStatus") + ": "}
                    stringValue={false}
                    value={
                        <TableCellColor
                            cell={{
                                code: data.order.status,
                                label: t(data.order.status.toLowerCase())
                            }}
                            table={false}
                        />
                    }
                    direction="row"
                    alignItems="center"
                />
                <Stack direction={"row"} spacing={1}>
                    {data.order.status === OrderStatusCode.Pending && <Button
                        onClick={() => {
                            handleOpen(OrderStatusCode.Approved)
                        }}
                        variant="contained"
                        color="success"
                        size="small"
                    >
                        {t("approve")}
                    </Button>
                    }

                    {data.order.status === OrderStatusCode.Pending && <Button
                        onClick={() => handleOpen(OrderStatusCode.Cancelled)}
                        variant="contained"
                        color="error"
                        size="small"
                    >
                        {t("cancelOrder")}
                    </Button>}

                    {![OrderStatusCode.Cancelled, OrderStatusCode.Completed, OrderStatusCode.Pending].includes(data.order.status) && <div>
                        <Button
                            id="demo-positioned-button"
                            aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? 'true' : undefined}
                            onClick={handleClick}
                            variant="outlined"
                            size="small"
                            endIcon={
                                <MoreVert />
                            }
                        >
                            {t("actions")}
                        </Button>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            {statusMenu.map(e => {
                                return e.code !== data.order.status && (
                                    <MenuItem onClick={() => handleOpen(e.code)} key={e.code}>
                                        <Typography color={e.code === OrderStatusCode.Cancelled ? "error" : ""}>{t(e.title)}</Typography>
                                    </MenuItem>
                                )
                            }
                            )}
                        </Menu>
                    </div>}
                </Stack>
            </Stack>
            <Stack direction={"row"} spacing={1} flexWrap={"wrap"} alignItems={"center"} justifyContent={"space-between"} useFlexGap>
                <LabelValue
                    label={t("paymentStatus") + ": "}
                    stringValue={false}
                    value={
                        <TableCellColor
                            cell={{
                                code: data.order.paymentStatus,
                                label: t(data.order.paymentStatus.toLowerCase())
                            }}
                            table={false}
                        />
                    }
                    direction="row"
                    alignItems="center"
                />
            </Stack>
            <Stack direction={"row"} spacing={1} flexWrap={"wrap"} alignItems={"center"} justifyContent={"space-between"} useFlexGap>
                <LabelValue
                    label={t("deliveryDate") + ": "}
                    value={
                        data.order.deliveryDate
                    }
                    direction="row"
                    alignItems="center"
                />
            </Stack>


            <Grid container spacing={2}>
                <Grid size={{ sm: 6, xs: 12 }} display={"flex"} alignItems={"stretch"}>
                    <Stack component={Paper} p={2} spacing={2} direction={"row"} width={"100%"}>
                        <Avatar sx={{ width: "30px", height: "30px", bgcolor: "primary.main" }}>
                            <Person fontSize='small' />
                        </Avatar>
                        <Stack spacing={1}>
                            <LabelValue
                                label={t("name") + ": "}
                                value={data.order.user.firstName + " " + data.order.user.lastName}
                                direction="row"
                                alignItems="center"
                            />
                            <LabelValue
                                label={t("email") + ": "}
                                value={data.order.user.email}
                                direction="row"
                                alignItems="center"
                            />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid size={{ sm: 6, xs: 12 }} display={"flex"} alignItems={"stretch"}>
                    <Stack component={Paper} p={2} spacing={2} direction={"row"} width={"100%"}>
                        <Avatar sx={{ width: "30px", height: "30px", bgcolor: "primary.main" }}>
                            <Place fontSize='small' />
                        </Avatar>
                        <Stack>
                            {data.order.deliveryType === "DLV" && <AddressData
                                name={data.order.address?.name!}
                                address={data.order.address?.address!}
                                mobile={data.order.address?.mobile!}
                                phone={data.order.address?.phone!}
                            />}
                            {data.order.deliveryType === "RIB" && <Stack spacing={1}>
                                <LabelValue
                                    label={t("branch") + ": "}
                                    value={data.order.branch?.name}
                                    direction="row"
                                    alignItems="center"
                                />
                            </Stack>}
                            {data.order.deliveryType === "TRS" && <Stack spacing={1}>
                                <LabelValue
                                    label={t("branch") + ": "}
                                    value={data.order.branch?.name}
                                    direction="row"
                                    alignItems="center"
                                />
                                <LabelValue
                                    label={t("appointmentDate") + ": "}
                                    value={dateFormatLL(data.order.appointment?.appointmentDate, i18n.language)}
                                    direction="row"
                                    alignItems="center"
                                />
                                <LabelValue
                                    label={t("table") + ": "}
                                    value={data.order.appointment?.tableTime.table.name + "-" + data.order.appointment?.tableTime.table.chairsNumber}
                                    direction="row"
                                    alignItems="center"
                                />
                                <LabelValue
                                    label={t("table") + ": "}
                                    value={data.order.appointment?.tableTime.fromTime + " - " + data.order.appointment?.tableTime.toTime}
                                    direction="row"
                                    alignItems="center"
                                />
                            </Stack>}
                        </Stack>
                    </Stack>
                </Grid>
                <Grid size={{ sm: 6, xs: 12 }} display={"flex"}>
                    <Stack component={Paper} p={2} spacing={2} direction={"row"} width={"100%"}>
                        <Avatar sx={{ width: "30px", height: "30px", bgcolor: "primary.main" }}>
                            <Article fontSize='small' />
                        </Avatar>
                        <Stack spacing={1}>
                            <LabelValue
                                label={t("placedOn") + ": "}
                                value={data.order.status === "COMPLETED" ? dateFormatLL(data.order.updateDate, i18n.language) : t("placeholder")}
                                direction="row"
                                alignItems="center"
                            />
                            <LabelValue
                                label={t("paymentStatus") + ": "}
                                stringValue={false}
                                value={
                                    <TableCellColor
                                        cell={{
                                            code: DeliveryTypeNames[data.order.deliveryType as "TRS" | "DLV" | "RIB"],
                                            label: t(DeliveryTypeNames[data.order.deliveryType as "TRS" | "DLV" | "RIB"])
                                        }}
                                        table={false}
                                    />
                                }
                                direction="row"
                                alignItems="center"
                            />
                            <LabelValue
                                label={t("paymentType") + ": "}
                                stringValue={false}
                                value={
                                    <TableCellColor
                                        cell={{
                                            code: paymentTypeNames[data.order.paymentType as "COD" | "STRIPE"],
                                            label: t(paymentTypeNames[data.order.paymentType as "COD" | "STRIPE"])
                                        }}
                                        table={false}
                                    />
                                }
                                direction="row"
                                alignItems="center"
                            />
                            <LabelValue
                                label={t("notes") + ": "}
                                value={data.order.note ?? t("placeholder")}
                                direction="row"
                                alignItems="center"
                            />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Stack spacing={1}>
                        {data.order.items.map(e =>
                            <Item key={e.id} item={e} />
                        )}
                    </Stack>
                </Grid>
                <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"end"}>
                    <Stack spacing={1} width={280}>
                        <Stack component={Paper} p={1} className={classes.divider} direction={"row"} justifyContent={"space-between"}>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("subtotal")}</Typography>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(data?.order?.subtotal)}</Typography>
                        </Stack>
                        <Stack component={Paper} p={1} direction={"row"} justifyContent={"space-between"}>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("taxFees")}</Typography>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(data?.order?.taxFees)}</Typography>
                        </Stack>
                        {!!data?.order?.deliveryFees && <Stack component={Paper} p={1} className={classes.divider} direction={"row"} justifyContent={"space-between"}>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("deliveryFees")}</Typography>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(data?.order?.deliveryFees)}</Typography>
                        </Stack>}
                        <Stack component={Paper} p={1} className={!!data?.order?.deliveryFees ? "" : classes.divider} direction={"row"} justifyContent={"space-between"}>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("totalFees")}</Typography>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(data?.order?.price)}</Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Root>
    )
}

export default OrderViewAdmin



const Item = ({ item }: any) => {
    const { t } = useTranslation()

    return (
        <Stack component={Paper} direction={"row"} justifyContent={"space-between"} p={2}>
            <Stack direction={"row"} spacing={2} flexWrap={"wrap"} useFlexGap>
                <Link to={`/products/${item.product.id}/${item.product.name}`}>
                    <Stack component={Paper} elevation={4} width={120} height={120} sx={{ borderRadius: "20px", overflow: "hidden" }}>
                        <img src={item.product?.image?.path ? getBackendUri(item.product.image.path) : noImage} alt="ds" width={"100%"} height={"100%"} style={{ objectFit: "fill" }} />
                    </Stack>
                </Link>
                <Stack spacing={0.5}>
                    <Typography fontWeight={"bolder"} fontSize={18}>{item.product.name}</Typography>
                    <Typography >{t("quantity")}: {item.quantity}</Typography>
                    {item.options.length > 0 && <Stack>
                        <Typography color="primary" fontSize={18}>{t("options")}</Typography>
                        <Stack direction={"row"} spacing={1} alignItems={"center"} flexWrap={"wrap"} useFlexGap>
                            {item.options.map((option: any) =>
                                <Stack direction={"row"} useFlexGap key={option.id}>
                                    <Typography>{option.option.name}:</Typography>
                                    <BooleanCell value={option.value} />
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                    }
                    {item.addons.length > 0 && <Stack>
                        <Typography color="primary" fontSize={18}>{t("addons")}</Typography>
                        <Stack direction={"row"} spacing={1} alignItems={"center"} flexWrap={"wrap"} useFlexGap>
                            {item.addons.map((addon: any) =>
                                <Stack direction={"row"} useFlexGap key={addon.id}>
                                    <Typography>{addon?.addon?.name}:</Typography>
                                    <Typography>{formatted(addon?.addon?.price)}</Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                    }
                </Stack>
            </Stack>
            <Stack alignItems={"flex-end"} justifyContent={"space-between"}>
                <Typography color='error'>{t("totalPrice")} : {formatted(item.price)}</Typography>

            </Stack>
        </Stack>
    )
}