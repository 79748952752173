import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Button, Stack, Typography, styled } from '@mui/material';

import { useTranslation } from 'react-i18next';
import ListPaper from '../Layouts/ListPaper';
import { FixedTableCell } from '../Layouts/FixedTableCell';
import MUITablePagination from '../Layouts/TablePagination';
import TableBodyWithLoad from '../Layouts/TableBodyWithLoad';
import KitchensCreate from './KitchensCreate';
import { useList_CategoriesQuery } from '../../../graphql/queries/products.generated';
import { getBackendUri } from '../../../Components/helperFunctions/getBackendUri';
import BooleanCell from '../../../Components/MUI/BooleanCell';
import { Restaurant } from '@mui/icons-material';

const PREFIX = "kitchens";
const classes = {
  filter: `${PREFIX}-filter`,
  filterActive: `${PREFIX}-filterActive`,
  title: `${PREFIX}-title`,
};
const Root = styled("div")(({ theme }) => ({
  height: "100%",
  [`& .${classes.filter}`]: {
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    [`&:hover`]: {
      background: theme.palette.divider
    },
  },
  [`& .${classes.filterActive}`]: {
    background: theme.palette.divider
  },
  [`& .${classes.title}`]: {
    fontSize: "22px"
  },
}));

export interface DataByIdType {
  id: number
  name: string
  fileName: string | undefined
  active: boolean
}

export default function KitchensList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const { t } = useTranslation();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [createDialog, setCreateDialog] = React.useState(false);
  const [dataById, setDataById] = React.useState<DataByIdType | undefined>();

  const handleCloseCreateDialog = () => {
    setDataById(undefined)
    setCreateDialog(false);
  };

  const handleOpenCreateDialog = (dataById?: DataByIdType) => {
    if (dataById) {
      setDataById(dataById)
    }
    setCreateDialog(true);
  };

  const { data, loading, refetch } = useList_CategoriesQuery({
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    // notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        parentId: null
      },
      first: rowsPerPage,
      page: page + 1
    },
    onCompleted() {
      
    },
  });

  const tableCellHeader = [
    "image", "name", "active", "createdAt"
  ]

  return (
    <Root>
      {createDialog && <KitchensCreate open={createDialog} handleClose={handleCloseCreateDialog} dataById={dataById} refetch={refetch} />}
      <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
        <Typography className={classes.title}>{t(PREFIX)}</Typography>
        <Button variant='contained' onClick={() => handleOpenCreateDialog()}>{t("addKitchen")}</Button>
      </Stack>
      <ListPaper loading={loading} data={!!(data && data.listCategories.data.length)}  >
        <TableContainer sx={{ width: "100%", overflow: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableCellHeader.map(e =>
                  <TableCell align={'left'} key={e}>
                    {t(e)}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBodyWithLoad loading={loading} tableCellHeaderLength={tableCellHeader.length}>
              <TableBody>
                {data?.listCategories.data.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={() => handleOpenCreateDialog({
                      active: row.active,
                      fileName: row.image?.path,
                      id: row.id,
                      name: row.name
                    })}>
                      <FixedTableCell>
                        <Avatar>
                          {row.image?.path ?
                            <img
                              src={row.image?.path ? getBackendUri(row.image?.path) : ""}
                              alt='kitchen' width={"100%"}
                              height={"100%"} style={{ objectFit: "cover" }}
                            /> : <Restaurant />
                          }
                        </Avatar>
                      </FixedTableCell>
                      <FixedTableCell>
                        {row.name}
                      </FixedTableCell>
                      <FixedTableCell align={'left'}>
                        <BooleanCell value={row.active} />
                      </FixedTableCell>
                      <FixedTableCell align={'left'}>
                        {row.date}
                      </FixedTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </TableBodyWithLoad>
          </Table>
        </TableContainer>
        <MUITablePagination
          count={data?.listCategories.paginatorInfo.count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ListPaper>
    </Root>
  );
}
