import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_OrderMutationVariables = Types.Exact<{
  input: Types.OrderInput;
}>;


export type Create_OrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'Order', id: number, code: string } };

export type Create_Payment_IntentMutationVariables = Types.Exact<{
  input: Types.PaymentIntentInput;
}>;


export type Create_Payment_IntentMutation = { __typename?: 'Mutation', createPaymentIntent: { __typename?: 'PaymentIntent', id: string, clientSecret: string, amount: number, customerClientSecret?: string | null } };

export type Update_Order_StatusMutationVariables = Types.Exact<{
  input: Types.UpdateOrderStatusInput;
}>;


export type Update_Order_StatusMutation = { __typename?: 'Mutation', updateOrderStatus: { __typename?: 'Order', id: number } };

export type Cancel_OrderMutationVariables = Types.Exact<{
  input: Types.CancelOrderInput;
}>;


export type Cancel_OrderMutation = { __typename?: 'Mutation', cancelOrder: { __typename?: 'Order', id: number } };


export const Create_OrderDocument = gql`
    mutation CREATE_ORDER($input: OrderInput!) {
  createOrder(input: $input) {
    id
    code
  }
}
    `;
export type Create_OrderMutationFn = Apollo.MutationFunction<Create_OrderMutation, Create_OrderMutationVariables>;

/**
 * __useCreate_OrderMutation__
 *
 * To run a mutation, you first call `useCreate_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreate_OrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_OrderMutation(baseOptions?: Apollo.MutationHookOptions<Create_OrderMutation, Create_OrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_OrderMutation, Create_OrderMutationVariables>(Create_OrderDocument, options);
      }
export type Create_OrderMutationHookResult = ReturnType<typeof useCreate_OrderMutation>;
export type Create_OrderMutationResult = Apollo.MutationResult<Create_OrderMutation>;
export type Create_OrderMutationOptions = Apollo.BaseMutationOptions<Create_OrderMutation, Create_OrderMutationVariables>;
export const Create_Payment_IntentDocument = gql`
    mutation CREATE_PAYMENT_INTENT($input: PaymentIntentInput!) {
  createPaymentIntent(input: $input) {
    id
    clientSecret
    amount
    customerClientSecret
  }
}
    `;
export type Create_Payment_IntentMutationFn = Apollo.MutationFunction<Create_Payment_IntentMutation, Create_Payment_IntentMutationVariables>;

/**
 * __useCreate_Payment_IntentMutation__
 *
 * To run a mutation, you first call `useCreate_Payment_IntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Payment_IntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreate_Payment_IntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_Payment_IntentMutation(baseOptions?: Apollo.MutationHookOptions<Create_Payment_IntentMutation, Create_Payment_IntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_Payment_IntentMutation, Create_Payment_IntentMutationVariables>(Create_Payment_IntentDocument, options);
      }
export type Create_Payment_IntentMutationHookResult = ReturnType<typeof useCreate_Payment_IntentMutation>;
export type Create_Payment_IntentMutationResult = Apollo.MutationResult<Create_Payment_IntentMutation>;
export type Create_Payment_IntentMutationOptions = Apollo.BaseMutationOptions<Create_Payment_IntentMutation, Create_Payment_IntentMutationVariables>;
export const Update_Order_StatusDocument = gql`
    mutation UPDATE_ORDER_STATUS($input: UpdateOrderStatusInput!) {
  updateOrderStatus(input: $input) {
    id
  }
}
    `;
export type Update_Order_StatusMutationFn = Apollo.MutationFunction<Update_Order_StatusMutation, Update_Order_StatusMutationVariables>;

/**
 * __useUpdate_Order_StatusMutation__
 *
 * To run a mutation, you first call `useUpdate_Order_StatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Order_StatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdate_Order_StatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Order_StatusMutation(baseOptions?: Apollo.MutationHookOptions<Update_Order_StatusMutation, Update_Order_StatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Order_StatusMutation, Update_Order_StatusMutationVariables>(Update_Order_StatusDocument, options);
      }
export type Update_Order_StatusMutationHookResult = ReturnType<typeof useUpdate_Order_StatusMutation>;
export type Update_Order_StatusMutationResult = Apollo.MutationResult<Update_Order_StatusMutation>;
export type Update_Order_StatusMutationOptions = Apollo.BaseMutationOptions<Update_Order_StatusMutation, Update_Order_StatusMutationVariables>;
export const Cancel_OrderDocument = gql`
    mutation CANCEL_ORDER($input: CancelOrderInput!) {
  cancelOrder(input: $input) {
    id
  }
}
    `;
export type Cancel_OrderMutationFn = Apollo.MutationFunction<Cancel_OrderMutation, Cancel_OrderMutationVariables>;

/**
 * __useCancel_OrderMutation__
 *
 * To run a mutation, you first call `useCancel_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancel_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancel_OrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancel_OrderMutation(baseOptions?: Apollo.MutationHookOptions<Cancel_OrderMutation, Cancel_OrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Cancel_OrderMutation, Cancel_OrderMutationVariables>(Cancel_OrderDocument, options);
      }
export type Cancel_OrderMutationHookResult = ReturnType<typeof useCancel_OrderMutation>;
export type Cancel_OrderMutationResult = Apollo.MutationResult<Cancel_OrderMutation>;
export type Cancel_OrderMutationOptions = Apollo.BaseMutationOptions<Cancel_OrderMutation, Cancel_OrderMutationVariables>;