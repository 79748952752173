import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'
import { Box, Stack } from '@mui/material'
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: "120px",
  marginTop: "0 !important"
}));

const HomeLayout = () => {
  return (
    <Stack height={"100%"} >
      <Navbar />
      <StyledBox />
      <Stack justifyContent={"space-between"} height={"100%"}>
        <Outlet />
        <Footer />
      </Stack>
    </Stack>
  )
}

export default HomeLayout