import { styled } from '@mui/material/styles';
import { Container, Grid2 as Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { Inventory2Outlined, InventoryOutlined, PeopleOutlineOutlined } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import { useDashboardQuery } from '../../../../graphql/queries/dashboard.generated';
import { useNavigate } from 'react-router-dom';

const PREFIX = "Details";

const classes = {
    link: `${PREFIX}-link`,
};

// const drawerWidth = 240;

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Container)(({ theme }) => ({
    [`& .${classes.link}`]: {
        textDecoration: "none",
        cursor: "pointer",
    },
}));

const Details = () => {
    const { t } = useTranslation()
    const { data, loading } = useDashboardQuery({
        fetchPolicy: "no-cache"
    });

    const navigate = useNavigate();

    const HandleNavigate = (path: string) => {
        navigate(path)
    }

    return (
        <Root maxWidth={"xl"}>
            <Grid container spacing={2} width={"100%"} m={0}>
                <Grid size={{ xs: 12, sm: 6, md: 4 }} onClick={() => HandleNavigate("orders")} className={classes.link}>
                    <Paper sx={{ p: 4, width: "100%" }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack spacing={2}>
                                <Typography variant={"h5"} textTransform={"capitalize"}>{t("orders")}</Typography>
                                <Typography variant={"body2"} fontSize={18}>
                                    {loading ? <Skeleton sx={{ m: 0 }} animation="wave" width={"80%"} /> : data?.listOrders.paginatorInfo.total}
                                </Typography>
                            </Stack>
                            <Stack
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: (theme) => theme.palette.primary.main }}>
                                <InventoryOutlined fontSize='large' sx={{ color: "white" }} />
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4 }} onClick={() => HandleNavigate("products")}>
                    <Paper sx={{ p: 4, width: "100%" }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack spacing={2}>
                                <Typography variant={"h5"} textTransform={"capitalize"}>{t("products")}</Typography>
                                <Typography variant={"body2"} fontSize={18}>
                                    {loading ? <Skeleton animation="wave" width={"80%"} /> : data?.listProducts.paginatorInfo.total}
                                </Typography>
                            </Stack>
                            <Stack
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: (theme) => theme.palette.primary.main }}>
                                <Inventory2Outlined fontSize='large' sx={{ color: "white" }} />
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4 }} onClick={() => HandleNavigate("users")}>
                    <Paper sx={{ p: 4, width: "100%" }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack spacing={2}>
                                <Typography variant={"h5"} textTransform={"capitalize"}>{t("users")}</Typography>
                                <Typography variant={"body2"} fontSize={18}>
                                    {loading ? <Skeleton animation="wave" width={"80%"} /> : data?.listUsers.paginatorInfo.total}
                                </Typography>
                            </Stack>
                            <Stack
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: (theme) => theme.palette.primary.main }}>
                                <PeopleOutlineOutlined fontSize='large' sx={{ color: "white" }} />
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </Root>
    )
}

export default Details
