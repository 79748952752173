import { Avatar, Button, CircularProgress, IconButton, Paper, Stack } from '@mui/material'
import { CircleOutlined, Delete, Edit, Place, TaskAlt } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { AddressesTypes, defaultAddress, deleteAddress } from '../../../Store/slices/auth'
import { useDelete_AddressMutation, useSet_Address_DefaultMutation } from '../../../graphql/queries/user.generated'
import CustomDialog from '../../../Components/MUI/CustomDialog'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useAppDispatch } from '../../../Store/store'
import { styled } from "@mui/material/styles";
import clsx from 'clsx'
import LabelValue from '../../../Components/LabelValue'

const PREFIX = "addressPaper";

const classes = {
    default: `${PREFIX}-default`,
};

const Root = styled("div")(({ theme }) => ({
    [`& .${classes.default}`]: {
        border: `1px solid ${theme.palette.success.main}`,
    },
}));

const AddressPaper = ({
    address,
    handleOpenWithData
}: {
    address: AddressesTypes
    handleOpenWithData?: (address: AddressesTypes) => void
}) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [deleteAddressMutation, { loading: deleteAddressLoading }] = useDelete_AddressMutation();
    const [setAddressDefaultMutation, { loading: setAddressDefaultLoading }] = useSet_Address_DefaultMutation();

    const dispatch = useAppDispatch()

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const deleteFunction = () => {
        deleteAddressMutation({
            variables: {
                id: address.id
            }
        }).then(() => {
            dispatch(deleteAddress(address.id))
        })
    }

    const defaultFunction = () => {
        setAddressDefaultMutation({
            variables: {
                id: address.id
            }
        }).then(() => {
            dispatch(defaultAddress(address.id))
        })
    }

    return (
        <Root>
            <Stack className={clsx({ [classes.default]: address.default })} component={Paper} direction={"row"} alignItems={"center"} gap={2} p={2} >
                {open &&
                    <CustomDialog
                        open={open}
                        handleClose={handleClose}
                        title={t("deleteAddress")}
                        content={t("areYouSure")}
                        buttonAction={
                            <LoadingButton
                                color='error'
                                loading={deleteAddressLoading}
                                onClick={deleteFunction}
                                variant='contained'
                            >
                                {t("submit")}
                            </LoadingButton>
                        }
                    />
                }

                <Stack width={"100%"} spacing={1}>
                    <Stack direction={"row"} spacing={0.5} justifyContent={"space-between"}>
                        <Avatar sx={{ width: "30px", height: "30px", bgcolor: "primary.main" }}>
                            <Place fontSize='small' />
                        </Avatar>
                        {handleOpenWithData && <Stack direction={"row"} spacing={0.5} >
                            <Button
                                size="small"
                                color='success'
                                endIcon={
                                    address.default
                                        ? <TaskAlt />
                                        : (setAddressDefaultLoading ?
                                            <CircularProgress size={13} /> :
                                            <CircleOutlined fontSize='small' />)
                                }
                                onClick={defaultFunction}
                            >
                                {t("default")}
                            </Button>
                            <IconButton
                                color='info'
                                size="small"
                                sx={{ bgcolor: "InfoBackground" }}
                                onClick={() => handleOpenWithData(address)}
                            >
                                <Edit />
                            </IconButton>
                            {!address.default &&
                                <IconButton
                                    size="small"
                                    onClick={handleOpen}
                                    color='error'
                                >
                                    <Delete />
                                </IconButton>
                            }
                        </Stack>}
                    </Stack>
                    <AddressData address={address.address} mobile={address.mobile} phone={address.phone} name={address.name} />
                </Stack>
            </Stack>
        </Root>
    )
}

export default AddressPaper

export const AddressData = ({ phone, mobile, address, name }: { phone: string, mobile: string, address: string, name: string }) => {
    const { t } = useTranslation()
    return <>
        <LabelValue direction='row' alignItems='center' label={t("name") + ":"} value={name} />
        <LabelValue direction='row' alignItems='center' label={t("phone") + "1:"} value={phone} />
        <LabelValue direction='row' alignItems='center' label={t("phone") + "2:"} value={mobile} />
        <LabelValue direction='row' alignItems='center' label={t("address") + ":"} value={address} />
    </>

}