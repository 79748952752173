import React from 'react'
import CanUser from '../CanUser'
import { IconButton } from '@mui/material'
import { Person } from '@mui/icons-material'
import { Link, useLocation } from 'react-router-dom'

const ProfileIcon = () => {
    const { pathname } = useLocation()

    if (pathname === "/profile" || pathname === "/profile/orders" || pathname === "/profile/addresses") {
        return null
    }

    return (
        <CanUser>
            <Link to={"/profile"}>
                <IconButton
                    aria-label="profile"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="default"
                >
                    <Person />
                </IconButton>
            </Link>
        </CanUser>
    )
}

export default ProfileIcon