import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { styled } from "@mui/material/styles";
import { Avatar, Container, Paper, Stack, Tab, Tabs } from "@mui/material";
import image from "../../assets/images/profile.jpg"
import { Edit, Info, PersonPin, Toc } from "@mui/icons-material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import UpdateProfilePic from "./Components/UpdateProfilePic";
import { useAppSelector } from "../../Store/store";
import { getBackendUri } from "../../Components/helperFunctions/getBackendUri";

const PREFIX = "ProfileLayout";

const classes = {
    background: `${PREFIX}-background`,
    imageWrapper: `${PREFIX}-imageWrapper`,
    image: `${PREFIX}-image`,
    tabs: `${PREFIX}-tabs`,
    imageWrapperStack: `${PREFIX}-imageWrapperStack`,
};

const Root = styled("div")(({ theme }) => ({
    margin: theme.spacing(4, 0),
    [`& .${classes.background}`]: {
        background: theme.palette.primary.main,
        height: "120px"
    },
    [`& .${classes.imageWrapperStack}`]: {
        width: "200px",
        height: "200px",
        margin: "auto",
        marginTop: "-80px",
        position: "relative"
    },
    [`& .${classes.imageWrapper}`]: {
        border: "5px solid #fff",
        width: "200px",
        height: "200px",
        borderRadius: "50%",
        margin: "auto",
        overflow: "hidden",
    },
    [`& .${classes.image}`]: {
        objectFit: "fill"
    },
    [`& .${classes.tabs}`]: {
        [`& .MuiTabs-flexContainer`]: {
            justifyContent: "center"
        },
    },
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
    position: "absolute",
    background: theme.palette.info.main,
    width: "30px",
    height: "30px",
    bottom: 20,
    right: 10,
    zIndex: 10,
    cursor: "pointer"
}));

const TABS_NAME = ["profile", "addresses", "orders"]

const ProfileLayout = () => {
    const location = useLocation();
    const { user } = useAppSelector((state) => state.auth);

    // Split the path and get the second segment
    const secondSegment = location.pathname.split('/')[2];

    const [value, setValue] = useState(secondSegment ? TABS_NAME.indexOf(secondSegment) : 0);

    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        navigate(newValue === 0 ? `/${TABS_NAME[newValue]}` : `/profile/${TABS_NAME[newValue]}`)
    };

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }


    return (
        <Root>
            {open && <UpdateProfilePic open={open} handleClose={handleClose} />}
            <Container maxWidth="lg">
                <Paper className={classes.background} elevation={5} />
                <Stack className={classes.imageWrapperStack}>
                    <Paper className={classes.imageWrapper} elevation={5}>
                        <AvatarStyle onClick={handleOpen}>
                            <Edit />
                        </AvatarStyle>
                        <img src={user?.image ? getBackendUri(user.image) : image} alt="aaa" width={"100%"} height={"100%"} className={classes.image} />
                    </Paper>
                </Stack>

                <Stack mt={2}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="profile details"
                        className={classes.tabs}
                    >
                        <Tab icon={<Info />} label={t("details")} />
                        <Tab icon={<PersonPin />} label={t("addresses")} />
                        <Tab icon={<Toc />} label={t("orders")} />
                    </Tabs>
                    <Outlet />
                </Stack>
            </Container>
        </Root>
    )
}

export default memo(ProfileLayout)