import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const PREFIX = "Banner";
const classes = {
    stickyHeader: `${PREFIX}-stickyHeader`,
};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.stickyHeader}`]: {

    },
}));

const Banner = () => {
    const { i18n } = useTranslation();
    return (
        <Root py={4} spacing={3} alignItems={"center"}>
            <Stack
                direction={i18n.language === "en" ? "row" : "row-reverse"}
                spacing={2}
                color={"text.secondary"}
                sx={{
                    letterSpacing: "5px",
                }}
            >
                <Typography variant="body1" sx={{
                    fontFamily: '"Rubik Vinyl", serif',
                    fontOpticalSizing: "auto",
                    fontStyle: "normal",
                    [`&::first-letter`]: {
                        color: "secondary.main",
                    },
                }}
                    fontSize={80}
                >
                    For
                </Typography>
                <Typography variant="body1" sx={{
                    fontFamily: '"Rubik Vinyl", serif',
                    fontOpticalSizing: "auto",
                    fontStyle: "normal",
                    [`&::first-letter`]: {
                        color: "primary.main",
                    },
                }}
                    fontSize={80}
                >
                    You
                </Typography>
            </Stack>
            <Typography textAlign={"center"} width={"80%"} fontSize={20} color="text.secondary">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, dolore sed? Tenetur possimus quasi dolorem mollitia tempora rem pariatur quis cupiditate, accusamus dignissimos perspiciatis saepe. Accusamus cupiditate explicabo quis nisi!
            </Typography>
        </Root>
    )
}

export default Banner