import { Paper, Stack, Typography } from '@mui/material'
import { getBackendUri } from '../helperFunctions/getBackendUri'
import { formatted, slugify } from '../helperFunctions/function'
import notRestaurant from "../../assets/images/not-restaurant.svg";
import DeleteButton from '../../Pages/public/Cart/Component/DeleteButton'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BooleanCell from '../MUI/BooleanCell';
import { FaMinus } from 'react-icons/fa';

type itemTypes = {
    id: number
    productId: number
    name: string
    price: number
    totalPrice: number
    quantity: number
    image?: string
    options: ({
        __typename?: "CartItemOption";
        id: number;
        value: boolean;
        option: {
            __typename?: "Option";
            id: number;
            name: string;
        };
    } | null)[] | undefined
    addons: ({
        __typename?: "CartItemAddon";
        id: number;
        addon: {
            __typename?: "Addon";
            name: string;
            price: number;
            id: number;
        };
    } | null)[] | undefined
}

const CartItem = ({
    item,
    refetch
}: {
    item: itemTypes
    refetch: () => void
}) => {
    const { t } = useTranslation()
    return (
        <Stack spacing={1} useFlexGap justifyContent={"space-between"}>
            <Stack direction={"row"} spacing={1} useFlexGap>
                <Link to={`/products/${item.productId}/${slugify(item.name ?? "")}`}>
                    <Stack component={Paper} elevation={4} width={70} height={70} sx={{ borderRadius: "20px", overflow: "hidden" }}>
                        <img
                            src={item.image ? getBackendUri(item.image) : notRestaurant}
                            alt={item.name}
                            width={"100%"}
                            height={"100%"}
                            style={{ objectFit: "fill" }}
                        />
                    </Stack>
                </Link>

                <Stack spacing={0.5}>
                    <Typography>{item.name}</Typography>
                    <Typography>{t("quantity")}: {item.quantity}</Typography>
                    {item.options && item.options.length > 0 && <Stack>
                        <Typography color="primary" fontSize={14}>{t("options")}</Typography>
                        <Stack spacing={1}>
                            {item.options.map(option =>
                                <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                                    <FaMinus fontSize={10} />
                                    <Typography>{option?.option.name}:</Typography>
                                    <BooleanCell value={option?.value!} />
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                    }
                    {item.addons && item.addons.length > 0 && <Stack>
                        <Typography color="primary" fontSize={14}>{t("addons")}</Typography>
                        <Stack spacing={1}>
                            {item.addons.map(addon =>
                                <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                                    <FaMinus fontSize={10} />
                                    <Typography>{addon?.addon?.name}:</Typography>
                                    <Typography>{formatted(addon?.addon.price ?? 0)}</Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                    }
                </Stack>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography fontSize={15}>
                    {t("totalPrice")}:
                    <Typography component={"span"} color='primary.main' mx={1}>
                        {formatted(item.price)}
                    </Typography>
                </Typography>
                <DeleteButton id={item.id} refetch={refetch} />
            </Stack>
        </Stack>
    )
}

export default CartItem