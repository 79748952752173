import React, { useState } from 'react';
import CustomDialog from '../../../../Components/MUI/CustomDialog';
import { useProduct_Addons_By_IdQuery } from '../../../../graphql/queries/products.generated';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Stack,
    CircularProgress,
    Typography,
} from '@mui/material';
import { formatted } from '../../../../Components/helperFunctions/function';
import { useTranslation } from 'react-i18next';
import { useSave_Cart_ItemMutation } from '../../../../graphql/mutations/user.generated';
import { LoadingButton } from '@mui/lab';
import { toast } from 'sonner';

const AddonsDialog = ({
    handleClose,
    open,
    productId,
    addons,
    option,
    quantity,
    refetch,
    itemId
}: {
    open: boolean; // Corrected to boolean type
    handleClose: () => void;
    productId: number;
    addons: number[]
    option: {
        id: number;
        optionId: number;
        value: boolean;
    }[] | undefined
    quantity: number | string
    refetch: () => void
    itemId: number
}) => {
    const { t } = useTranslation()
    // Query for fetching addons
    const { data, loading, error } = useProduct_Addons_By_IdQuery({
        fetchPolicy: "no-cache",
        variables: {
            id: productId,
        },
    });

    const [saveCartItemMutation, { loading: saveCartItemLoading }] = useSave_Cart_ItemMutation();

    // State to manage selected addons
    const [selectedAddons, setSelectedAddons] = useState<number[]>([]);

    // Handle checkbox state change
    const handleChange = (id: number) => {
        setSelectedAddons((prev) =>
            prev.includes(id) ? prev.filter((addonId) => addonId !== id) : [...prev, id]
        );
    };

    const handleSave = () => {
        saveCartItemMutation({
            variables: {
                input: {
                    productId: productId,
                    quantity: Number(quantity),
                    addons: selectedAddons.map(e => ({ addonId: e })),
                    options: option,
                    id: itemId
                }
            }
        }).then(() => {
            refetch()
            handleClose()
        }).catch((err) => {
            toast.error(err)
        })
    };

    // Display error state
    if (error) {
        return (
            <CustomDialog
                open={open}
                handleClose={handleClose}
                content={
                    <Typography color="error">
                        {error.message || 'Something went wrong while fetching addons.'}
                    </Typography>
                }
            />
        );
    }

    return (
        <CustomDialog
            open={open}
            handleClose={handleClose}
            title={<Typography fontSize={18} color='primary.main'>{t("addons")}</Typography>}
            content={
                loading ? <Stack alignItems="center" justifyContent="center" height="200px">
                    <CircularProgress />
                </Stack> :
                    <Stack spacing={1}>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormGroup>
                                {data?.product.addons?.map((addon) => (
                                    <FormControlLabel
                                        key={addon?.id!}
                                        control={
                                            <Checkbox
                                                disabled={addons.includes(addon?.id!)}
                                                checked={selectedAddons.includes(addon?.id!) || addons.includes(addon?.id!)}
                                                onChange={() => handleChange(addon?.id!)}
                                                name={`addon-${addon?.id}`}
                                            />
                                        }
                                        label={
                                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                <Typography>{addon?.name!}</Typography>
                                                <Typography>{formatted(addon?.price)}</Typography>
                                            </Stack>
                                        } // Dynamically display addon name
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Stack>
            }
            buttonAction={
                <LoadingButton variant='contained' loading={saveCartItemLoading} onClick={handleSave}>{t("submit")}</LoadingButton>
            }
        />
    );
};

export default AddonsDialog;
