import { Typography, TypographyProps } from '@mui/material'
import { styled } from "@mui/material/styles";

interface TypographyPropsS extends TypographyProps {
    text: string;
}

const Root = styled(Typography)(({ theme }) => ({
    [`&::first-letter`]: {
        textTransform: "uppercase" 
    },
}));

const CapitalFirstLetter = ({ text, ...typographyProps }: TypographyPropsS) => {
    return (
        <Root {...typographyProps}>{text}</Root>
    )
}

export default CapitalFirstLetter