import { useTranslation } from 'react-i18next'
import ButtonLink from '../ButtonLink'
import { useLocation } from 'react-router-dom'
import RestaurantIcon from '@mui/icons-material/Restaurant';
const KitchenBtn = () => {
    const { t } = useTranslation()

    const location = useLocation()
    return (
        <ButtonLink startIcon={<RestaurantIcon />} variant={location.pathname === "/kitchens" ? 'contained' : 'outlined'} text={t("kitchens")} to='/kitchens' size='small' />
    )
}

export default KitchenBtn