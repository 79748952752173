import { Avatar, Button, Grid2 as Grid, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useParams } from "react-router-dom";
import { useOrderQuery } from "../../graphql/queries/orders.generated";
import Loading from "../../Components/Loading";
import { AddressData } from "./Components/AddressPaper";
import { Article, Forward, Place } from "@mui/icons-material";
import { formatted } from "../../Components/helperFunctions/function";
import { getBackendUri } from "../../Components/helperFunctions/getBackendUri";
import noImage from "../../assets/images/no-image-svgrepo-com.svg"
import BooleanCell from "../../Components/MUI/BooleanCell";
import { dateFormatLL, dateTimeFormatA } from "../../Components/helperFunctions/dateFunctions";
import { useTranslation } from "react-i18next";
import { DeliveryTypeNames, paymentTypeNames } from "../../types";
import ButtonLink from "../../Components/ButtonLink";
import { LoadingButton } from "@mui/lab";
import { useCancel_OrderMutation } from "../../graphql/mutations/orders.generated";
import { CancelOrderInput, PaymentStatusCode, PaymentTypeCode } from "../../graphql/types";
import { useState } from "react";
import CustomDialog from "../../Components/MUI/CustomDialog";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import ControlMUITextField from "../../Components/MUI/ControlMUItextField";
import { OrderStatusCode } from "../../graphql/types";
import LabelValue from "../../Components/LabelValue";
import { TableCellColor } from "../../Components/CellColor";

const PREFIX = "orders-view";
const classes = {
    image: `${PREFIX}-image`,
    imageWrapper: `${PREFIX}-imageWrapper`,
    divider: `${PREFIX}-divider`,

};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.imageWrapper}`]: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "8px",
        overflow: "hidden",
        padding: "2px"
    },
    [`& .${classes.image}`]: {
        objectFit: "cover",
        borderRadius: "5px",
    },
    [`& .${classes.divider}`]: {
        background: theme.palette.divider
    },

}));

const OrderView = () => {
    const { id } = useParams()
    const { t, i18n } = useTranslation()
    const { data, loading, refetch } = useOrderQuery({
        fetchPolicy: "no-cache",
        variables: {
            id: parseInt(id!)
        },
    });

    const [open, setOpen] = useState(false)

    const [cancelOrderMutation, { loading: cancelOrderLoading }] = useCancel_OrderMutation();

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }
    const cancelOrder = (data: CancelOrderInput) => {
        cancelOrderMutation({
            variables: {
                input: {
                    id: +id!,
                    cancellationReason: data.cancellationReason
                }
            }
        }).then(() => {
            handleClose()
            refetch()
        }).catch(({ graphQLErrors }) => toast.error(graphQLErrors[0].message))
    }

    const { control, handleSubmit } = useForm<CancelOrderInput>()

    return loading ? <Loading height="300px" /> : !data ? <div>no data</div> : (
        <Root mt={2} spacing={2}>
            {open && <CustomDialog
                open={open}
                handleClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(cancelOrder),
                    noValidate: true
                }}
                title={t("areYouSureToCancel")}
                content={
                    <Stack alignItems={"center"} py={2}>
                        <ControlMUITextField
                            label={t("cancellationReason")}
                            control={control}
                            name="cancellationReason"
                            rules={{
                                required: t("fieldIsRequired"),
                            }}
                            rows={3}
                        />
                    </Stack>
                }
                buttonAction={
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={cancelOrderLoading}
                        color="error"
                        size="small"
                    >
                        {t("cancelOrder")}
                    </LoadingButton>
                }
            />}
            <Stack direction={"row"} spacing={1} flexWrap={"wrap"} alignItems={"center"} useFlexGap>
                <ButtonLink to="/profile/orders" text={t("orders")} size="small" startIcon={<Forward sx={{ transform: i18n.dir() === "rtl" ? "" : "rotate(180deg)" }} />} variant="outlined" />
                <Typography fontWeight={"bolder"} fontSize={18}>{t("orderDetails")}</Typography>
                <Typography fontSize={18}>( {data.order.code} )</Typography>
            </Stack>
            <Stack direction={"row"} spacing={1} flexWrap={"wrap"} alignItems={"center"} justifyContent={"space-between"} useFlexGap>
                <Stack direction={"row"} spacing={0.5}>
                    <LabelValue
                        label={t("orderStatus") + ": "}
                        stringValue={false}
                        value={
                            <TableCellColor
                                cell={{
                                    code: data.order.status,
                                    label: t(data.order.status.toLowerCase())
                                }}
                                table={false}
                            />
                        }
                        direction="row"
                        alignItems="center"
                    />
                </Stack>
                <Stack direction={"row"} spacing={1}>
                    {data.order.paymentStatus === PaymentStatusCode.Pending &&
                        data.order.paymentType === PaymentTypeCode.Stripe && <ButtonLink
                            text={t("payNow")}
                            to={`/checkout/payment/${data.order.id}`}
                            variant="contained"
                            color="success"
                            size="small"
                        />}
                    {data.order.status === OrderStatusCode.Pending && <Button
                        onClick={handleOpen}
                        variant="contained"
                        color="error"
                        size="small"
                    >
                        {t("cancelOrder")}
                    </Button>}
                </Stack>
            </Stack>
            <Stack direction={"row"} spacing={1} flexWrap={"wrap"} alignItems={"center"} justifyContent={"space-between"} useFlexGap>
                <Stack direction={"row"} spacing={0.5}>
                    <LabelValue
                        label={t("paymentStatus") + ": "}
                        stringValue={false}
                        value={
                            <TableCellColor
                                cell={{
                                    code: data.order.paymentStatus,
                                    label: t(data.order.paymentStatus.toLowerCase())
                                }}
                                table={false}
                            />
                        }
                        direction="row"
                        alignItems="center"
                    />
                </Stack>
            </Stack>
            <Stack direction={"row"} spacing={1} flexWrap={"wrap"} alignItems={"center"} justifyContent={"space-between"} useFlexGap>
                <LabelValue
                    label={t("deliveryDate") + ": "}
                    value={
                        data.order.deliveryDate
                    }
                    direction="row"
                    alignItems="center"
                />
            </Stack>

            <Grid container spacing={2}>
                <Grid size={{ sm: 6, xs: 12 }} display={"flex"} alignItems={"stretch"}>
                    <Stack component={Paper} p={2} spacing={2} direction={"row"} width={"100%"}>
                        <Avatar sx={{ width: "30px", height: "30px", bgcolor: "primary.main" }}>
                            <Place fontSize='small' />
                        </Avatar>
                        <Stack>
                            {data.order.deliveryType === "DLV" && <AddressData
                                name={data.order.address?.name!}
                                address={data.order.address?.address!}
                                mobile={data.order.address?.mobile!}
                                phone={data.order.address?.phone!}
                            />}
                            {data.order.deliveryType === "RIB" && <Stack spacing={1}>
                                <LabelValue
                                    label={t("branch") + ": "}
                                    value={data.order.branch?.name}
                                    direction="row"
                                    alignItems="center"
                                />
                            </Stack>}
                            {data.order.deliveryType === "TRS" && <Stack spacing={1}>
                                <LabelValue
                                    label={t("branch") + ": "}
                                    value={data.order.branch?.name}
                                    direction="row"
                                    alignItems="center"
                                />
                                <LabelValue
                                    label={t("appointmentDate") + ": "}
                                    value={dateFormatLL(data.order.appointment?.appointmentDate, i18n.language)}
                                    direction="row"
                                    alignItems="center"
                                />
                                <LabelValue
                                    label={t("table") + ": "}
                                    value={data.order.appointment?.tableTime.table.name + "-" + data.order.appointment?.tableTime.table.chairsNumber}
                                    direction="row"
                                    alignItems="center"
                                />
                                <LabelValue
                                    label={t("tableTime") + ": "}
                                    value={data.order.appointment?.tableTime.fromTime + " - " + data.order.appointment?.tableTime.toTime}
                                    direction="row"
                                    alignItems="center"
                                />
                            </Stack>}
                        </Stack>
                    </Stack>
                </Grid>
                <Grid size={{ sm: 6, xs: 12 }} display={"flex"}>
                    <Stack component={Paper} p={2} spacing={2} direction={"row"} width={"100%"}>
                        <Avatar sx={{ width: "30px", height: "30px", bgcolor: "primary.main" }}>
                            <Article fontSize='small' />
                        </Avatar>
                        <Stack spacing={1}>
                            <LabelValue
                                label={t("placedOn") + ": "}
                                value={data.order.status === "COMPLETED" ? dateFormatLL(data.order.updateDate, i18n.language) : t("placeholder")}
                                direction="row"
                                alignItems="center"
                            />
                            <LabelValue
                                label={t("paymentStatus") + ": "}
                                stringValue={false}
                                value={
                                    <TableCellColor
                                        cell={{
                                            code: DeliveryTypeNames[data.order.deliveryType as "TRS" | "DLV" | "RIB"],
                                            label: t(DeliveryTypeNames[data.order.deliveryType as "TRS" | "DLV" | "RIB"])
                                        }}
                                        table={false}
                                    />
                                }
                                direction="row"
                                alignItems="center"
                            />
                            <LabelValue
                                label={t("paymentType") + ": "}
                                stringValue={false}
                                value={
                                    <TableCellColor
                                        cell={{
                                            code: paymentTypeNames[data.order.paymentType as "COD" | "STRIPE"],
                                            label: t(paymentTypeNames[data.order.paymentType as "COD" | "STRIPE"])
                                        }}
                                        table={false}
                                    />
                                }
                                direction="row"
                                alignItems="center"
                            />
                            <LabelValue
                                label={t("notes") + ": "}
                                value={data.order.note ?? t("placeholder")}
                                direction="row"
                                alignItems="center"
                            />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Stack spacing={1}>
                        {data.order.items.map(e =>
                            <Item key={e.id} item={e} />
                        )}
                    </Stack>
                </Grid>
                <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"end"}>
                    <Stack spacing={1} width={280}>
                        <Stack component={Paper} p={1} className={classes.divider} direction={"row"} justifyContent={"space-between"}>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("subtotal")}</Typography>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(data?.order?.subtotal)}</Typography>
                        </Stack>
                        <Stack component={Paper} p={1} direction={"row"} justifyContent={"space-between"}>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("taxFees")}</Typography>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(data?.order?.taxFees)}</Typography>
                        </Stack>
                        {!!data?.order?.deliveryFees && <Stack component={Paper} p={1} className={classes.divider} direction={"row"} justifyContent={"space-between"}>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("deliveryFees")}</Typography>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(data?.order?.deliveryFees)}</Typography>
                        </Stack>}
                        <Stack component={Paper} p={1} className={!!data?.order?.deliveryFees ? "" : classes.divider} direction={"row"} justifyContent={"space-between"}>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("totalFees")}</Typography>
                            <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(data?.order?.price)}</Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Root>
    )
}

export default OrderView



const Item = ({ item }: any) => {
    const { t } = useTranslation()

    return (
        <Stack component={Paper} direction={"row"} justifyContent={"space-between"} p={2}>
            <Stack direction={"row"} spacing={2} flexWrap={"wrap"} useFlexGap>
                <Link to={`/products/${item.product.id}/${item.product.name}`}>
                    <Stack component={Paper} elevation={4} width={120} height={120} sx={{ borderRadius: "20px", overflow: "hidden" }}>
                        <img src={item.product?.image?.path ? getBackendUri(item.product.image.path) : noImage} alt="ds" width={"100%"} height={"100%"} style={{ objectFit: "fill" }} />
                    </Stack>
                </Link>
                <Stack spacing={0.5}>
                    <Typography fontWeight={"bolder"} fontSize={18}>{item.product.name}</Typography>
                    <Typography >{t("quantity")}: {item.quantity}</Typography>
                    {item.options.length > 0 && <Stack>
                        <Typography color="primary" fontSize={18}>{t("options")}</Typography>
                        <Stack direction={"row"} spacing={1} alignItems={"center"} flexWrap={"wrap"} useFlexGap>
                            {item.options.map((option: any) =>
                                <Stack direction={"row"} useFlexGap>
                                    <Typography>{option.option.name}:</Typography>
                                    <BooleanCell value={option.value} />
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                    }
                    {item.addons.length > 0 && <Stack>
                        <Typography color="primary" fontSize={18}>{t("addons")}</Typography>
                        <Stack direction={"row"} spacing={1} alignItems={"center"} flexWrap={"wrap"} useFlexGap>
                            {item.addons.map((addon: any) =>
                                <Stack direction={"row"} useFlexGap>
                                    <Typography>{addon?.addon?.name}:</Typography>
                                    <Typography>{formatted(addon?.price)}</Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                    }
                </Stack>
            </Stack>
            <Stack alignItems={"flex-end"} justifyContent={"space-between"}>
                <Typography color='error'>{t("totalPrice")} : {formatted(item.price)}</Typography>

            </Stack>
        </Stack>
    )
}