import Logo from "../assets/images/logo.png"
import { Stack, useTheme } from '@mui/material'

const Footer = () => {
  const theme = useTheme()
  return (
    <footer>
      <Stack alignItems={"center"} bgcolor={"background.default"} py={3} borderTop={`1px solid ${theme.palette.divider}`}>
        <img src={Logo} alt='ff' height={120} />
        {/* <Typography
          variant='h6'
          fontSize={30}
          color='primary.main'
          sx={{
            fontFamily: '"Rubik Vinyl", serif',
            fontOpticalSizing: "auto",
            fontStyle: "normal"
          }}
        >
          <span>For</span> <span>You</span>
        </Typography> */}
      </Stack>
    </footer>
  )
}

export default Footer