import { RouterProvider } from 'react-router-dom';
import router from './Routes/routerConfig';
import { useTranslation } from 'react-i18next';
import withRoot from './Components/HOC/MuiTheme';
import { useEffect } from 'react';

function App() {

  const { i18n } = useTranslation();
  document.getElementsByTagName("html")[0].setAttribute("dir", i18n.dir());

  useEffect(() => {
    const handleStorage = (event: StorageEvent) => {
      if (event.key === 'refresh') {
        window.location.reload(); // Refresh the current tab
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true
      }}
    />
  );
}

export default withRoot(App);
