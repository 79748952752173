import { styled } from "@mui/material/styles";
import { Stack, Typography } from '@mui/material';

import BGImage from "../assets/images/fixedBackground.webp"
import { useTranslation } from 'react-i18next';

const Root = styled(Stack)(({ theme }) => ({
    minHeight: 400,
    background: `url(${BGImage})`,
    // backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // backgroundSize: "",
}));

const FixedBackground = () => {
    const { t } = useTranslation()
    return (
        <Root justifyContent={"center"} alignItems={"center"}>
            <Typography fontSize={35} color='#fff' fontWeight={"bolder"} width={{ md: "30%", sm: "70%" }} textAlign={"center"}>
                {t("fixedBackgroundTitle")}
            </Typography>
        </Root>
    )
}

export default FixedBackground