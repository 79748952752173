import { Card, CardContent, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useWidth, { isWidthDown } from './helperFunctions/useWidth';
import { Restaurant } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { styled } from "@mui/material/styles";

const CategoryCard = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: "100%",
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    position: "relative",
    '&:hover': {
        transform: 'translateY(-10px)',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    },
});

// const CategoryImage = styled(CardMedia)({
//     height: 350,
//     width: '100%',
//     objectFit: 'contain',
//     borderRadius: '10px 10px 0 0',
// });

// const CategoryTitle = styled(Typography)({
//     fontWeight: 'bold',
//     fontSize: '18px',
//     textAlign: 'center',
// });

const CardContentStyle = styled(CardContent)({
    padding: "0 !important",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

const LinkStyle = styled(Link)({
    textDecoration: "none"
});

// const SoonStyle = styled(Stack)(({ theme }) => ({
//     position: "absolute",
//     background: "#000000b5",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "calc(100% - 50px)",
//     justifyContent: "center",
//     alignItems: "center",
//     color: theme.palette.primary.main,
//     textTransform: "uppercase",
//     fontWeight: "bolder",
//     fontSize: 25,
//     letterSpacing: "5px"
// }));

const SoonStyleTwo = styled(Stack)(({ theme }) => ({
    position: "absolute",
    background: "#000000b5",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontWeight: "bolder",
    fontSize: 20,
    letterSpacing: "2px"
}));

export const KitchenCard = ({ title, imageUrl, to, soon }: { title: string; imageUrl?: string, to?: string, soon?: boolean }) => {
    const screenWidth = useWidth();
    const isScreenSmall = isWidthDown("xs", screenWidth);
    const { t } = useTranslation()
    const DATA = isScreenSmall ?
        <Stack spacing={2} alignItems={"center"}>
            <Stack width={"150px"} height={"150px"} sx={{ overflow: "hidden", borderRadius: "50%", position: "relative" }}>
                {soon && <SoonStyleTwo>{t("soon")}</SoonStyleTwo>}
                {imageUrl ? <img src={imageUrl} alt='sdf' width={"100%"} height={"100%"} style={{ objectFit: "cover" }} /> : <Restaurant />}
            </Stack>
            <Stack component={Paper} p={1} width={"100%"}>
                <Typography color="primary" fontSize={15} textAlign={"center"}>{title}</Typography>
            </Stack>
        </Stack>
        : <Stack component={Paper} overflow={"hidden"} alignItems={"center"} elevation={4} position={"relative"}>
            <Stack height={300} width={"100%"}>
                {soon && <SoonStyleTwo>{t("soon")}</SoonStyleTwo>}
                <img src={imageUrl} alt={title} width={"100%"} height={"100%"} style={{ objectFit: "cover" }} />
            </Stack>
            <Stack
                width={"80%"}
                component={Paper}
                bgcolor={"Background"}
                elevation={5}
                justifyContent={"center"}
                alignItems={"center"}
                position={"absolute"}
                bottom={10}
                py={1}
            >
                <Typography color="primary" fontSize={18} textAlign={"center"}>{title}</Typography>
            </Stack>
        </Stack>

    return to ? (
        <LinkStyle to={to}>
            {DATA}
        </LinkStyle>
    ) : (DATA)
};

export const KitchenCardLoading = () => {
    const screenWidth = useWidth();
    const isScreenSmall = isWidthDown("xs", screenWidth);

    return isScreenSmall ?
        <Stack p={2} spacing={2} component={Paper} alignItems={"center"}>
            <Stack width={"80px"} height={"80px"} sx={{ overflow: "hidden", borderRadius: "50%" }}>
                <Skeleton variant="circular" width={"100%"} height={"100%"} />
            </Stack>
            <Typography>
                <Skeleton variant="text" width={"40px"} />
            </Typography>
        </Stack>
        : <CategoryCard>
            <Stack width={"100%"} height={250} >
                <Skeleton variant="rounded" width={"100%"} height={"100%"} />
            </Stack>
            <CardContentStyle>
                <Typography>
                    <Skeleton variant="text" width={"40px"} />
                </Typography>
            </CardContentStyle>
        </CategoryCard>

};


