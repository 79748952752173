import { Button, Grid2 as Grid, Paper, Stack, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import ControlMUITextField from "../../../Components/MUI/ControlMUItextField"
import UploadFile from "../../../Components/MUI/UploadFile"
import { styled } from "@mui/material/styles";
import { Place } from "@mui/icons-material"
import { BranchInput } from "../../../graphql/types"
import { LoadingButton } from "@mui/lab"
import Loading from "../../../Components/Loading"
import { useSave_BranchMutation } from "../../../graphql/mutations/branches.generated"
import { useBranchQuery } from "../../../graphql/queries/branches.generated"
import MuiTimePicker from "../../../Components/MUI/MuiTimePicker"
import TablesTable from "./Component/TablesTable"
import { useReducer, useState } from "react"
import moment from "moment"
import LocationPickerDialog from "./Component/LocationPickerDialog"
import TableCreate from "./Component/TableCreate"
import { toast } from "sonner"
import { setValidationError } from "../../../Components/helperFunctions/setValidationError"



// const PREFIX = "products-created";
// const classes = {
//     editor: `${PREFIX}-editor`,
//     editorError: `${PREFIX}-editorError`,
// };

const Root = styled(Stack)(({ theme }) => ({

}));

type State = {
    branchDetails: any; // Replace `any` with the appropriate type
    dialog: boolean;
    locationDialog: boolean;
    skipTablesList: boolean;
    tableData: any; // Replace `any` with the appropriate type
    endTime: any; // Replace `any` with the appropriate type
    startTime: any; // Replace `any` with the appropriate type
};

export type Action = {
    payload: Partial<State>; // Ensures the payload matches part of the state shape
};

const initialState: State = {
    branchDetails: null,
    dialog: false,
    locationDialog: false,
    skipTablesList: false,
    tableData: null,
    startTime: null,
    endTime: null,
};

function reducer(state: State, action: Action): State {
    return {
        ...state,
        ...action.payload,
    };
}

type SetValueKeys =
    | "id"
    | "name"
    | "telephone"
    | "whatsapp"
    | "address"

const getTime = (time: string) => moment(time).locale("en").format('HH:mm');
const stringFormatToDate = (date: string) => moment(date, "HH:mm").locale("en").toDate();

export const BranchesCreate = () => {
    const { id } = useParams()
    const navigate = useNavigate();

    const { t } = useTranslation()
    const { handleSubmit, control, setValue, watch, setError } = useForm<BranchInput>()
    const [saveBranchMutation, { loading }] = useSave_BranchMutation();

    const branchId = parseInt(id!) || watch("id");

    const [BranchData, dispatch] = useReducer(reducer, initialState)
    const [location, setLocation] = useState<[number, number]>([52.665400146559, 8.822021484375002])
    const [refetchFunction, setRefetchFunction] = useState<null | (() => void)>(null);
    // Call the child's function when the button is clicked
    const callRefetchFunction = () => {
        if (refetchFunction) {
            refetchFunction(); // Call the child function
        }
    };

    const setLocationPickedFun = (newLocation: [number, number]) => {
        setLocation(newLocation);
    };

    const handleCloseTableDialog = () => {
        dispatch({
            payload: {
                tableData: null,
                dialog: false
            }
        })
    }

    const handleCloseLocationDialog = () => {
        dispatch({
            payload: {
                locationDialog: false
            }
        })
    }

    const handleOpenLocationDialog = () => {
        dispatch({
            payload: {
                locationDialog: true
            }
        })
    }

    const { loading: branchByIdLoading } = useBranchQuery({
        skip: !parseInt(id!),
        variables: {
            id: parseInt(id!)
        },
        onCompleted(data) {
            const productData = data.branch;
            setLocation([productData.latitude, productData.longitude])
            const wareHouseParams: SetValueKeys[] = ["id", "name", "telephone", "whatsapp", "address"];

            wareHouseParams.forEach((i) => {
                if (productData[i] !== undefined) {
                    setValue(i, productData[i]);
                }
            });

            setValue("endTime", stringFormatToDate(productData.endTime))
            setValue("startTime", stringFormatToDate(productData.startTime))

            dispatch({
                payload: {
                    endTime: stringFormatToDate(productData.endTime)
                }
            })
        },
    });

    const onSubmit = (data: BranchInput) => {
        const endTime = getTime(data.endTime)
        const startTime = getTime(data.startTime)

        saveBranchMutation({
            variables: {
                input: {
                    ...(branchId && { id: data.id }),
                    address: data.address,
                    endTime: endTime,
                    ...(data.image && {
                        image: {
                            image: data.image
                        },
                    }),
                    latitude: location[0],
                    longitude: location[1],
                    name: data.name,
                    startTime: startTime,
                    whatsapp: !data.whatsapp ? null : data.whatsapp,
                    telephone: !data.telephone ? null : data.telephone,
                }
            }
        })
            .then((data) => {
                setValue("id", data?.data?.saveBranch?.id);
                const newUrl = `/dashboard/branches/${data?.data?.saveBranch?.id}/edit`;

                navigate(newUrl, {
                    replace: true, // Replaces the current entry in the history stack
                    state: { prevUrl: `${window.location.pathname}${window.location.search}` },
                });
                toast.success(t("saveSuccessful"))
            })
            .catch(({ graphQLErrors }) => {
                setValidationError({ graphQLErrors, setError })
            })
    }

    return branchByIdLoading ? <Loading /> : (
        <Root spacing={2}>
            <LocationPickerDialog
                open={BranchData.locationDialog}
                handleClose={handleCloseLocationDialog}
                setLocationPickedFun={setLocationPickedFun}
                location={location}
            />
            {BranchData.dialog && <TableCreate
                open={BranchData.dialog}
                handleClose={handleCloseTableDialog}
                branchId={branchId!}
                callRefetchFunction={callRefetchFunction}
                updateData={BranchData.tableData}
            />}
            <Typography fontSize={20}>{t("newBranch")}</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack component={Paper} p={2} spacing={2}>
                    <Grid container spacing={2} m={0}>
                        <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"flex-end"}>
                            <Button
                                variant="outlined"
                                endIcon={<Place />}
                                onClick={handleOpenLocationDialog}
                            >
                                {t("branchLocation")}
                            </Button>
                        </Grid>
                        <Grid size={{ md: 4, sm: 6, xs: 12 }}>
                            <ControlMUITextField
                                label={t("name")}
                                control={control}
                                name="name"
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                            />
                        </Grid>
                        <Grid size={{ md: 4, sm: 6, xs: 12 }}>
                            <UploadFile
                                control={control}
                                setValue={setValue}
                                name="image"
                                icon={"add_photo_alternate"}
                                label={t("image")}
                                accept=".png,.jpg"
                                maxSize={1000 * 1024}
                            />
                        </Grid>
                        <Grid size={{ md: 4, sm: 6, xs: 12 }}>
                            <ControlMUITextField
                                label={t("telephone")}
                                control={control}
                                name="telephone"
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                                type="number"
                            />
                        </Grid>
                        <Grid size={{ md: 4, sm: 6, xs: 12 }}>
                            <ControlMUITextField
                                label={t("whatsapp")}
                                control={control}
                                name="whatsapp"
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                                type="number"
                            />
                        </Grid>
                        <Grid size={{ md: 4, sm: 6, xs: 12 }}>
                            <MuiTimePicker
                                label={t("startTime")}
                                control={control}
                                name="startTime"
                            />
                        </Grid>
                        <Grid size={{ md: 4, sm: 6, xs: 12 }}>
                            <MuiTimePicker
                                label={t("endTime")}
                                control={control}
                                name="endTime"
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <ControlMUITextField
                                label={t("address")}
                                control={control}
                                name="address"
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                    <Stack alignItems={"flex-end"}>
                        <LoadingButton loading={loading} variant="contained" color="success" type="submit">
                            {t("save")}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </form>
            <TablesTable
                branchId={branchId!}
                setExposeRefetchFunction={setRefetchFunction}
                dispatch={dispatch}
            />
        </Root>
    )
}
