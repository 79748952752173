import React from 'react'
import CustomDialog from '../../../Components/MUI/CustomDialog'
import { Stack } from '@mui/material'
import ControlMUITextField from '../../../Components/MUI/ControlMUItextField'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { useLoginMutation, useResend_Verification_CodeMutation, useVerify_User_EmailMutation } from '../../../graphql/mutations/auth.generated'
import { VerifyUserEmailInput } from '../../../graphql/types'
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner'
import useAfterLogin, { DataTypes } from './useAfterLogin'
import { setValidationError } from '../../../Components/helperFunctions/setValidationError'

const VerifyDialog = ({
    handleClose,
    open,
    password,
    email
}: {
    open: boolean,
    handleClose: () => void,
    password: string
    email: string
}) => {
    const { control, handleSubmit, setError } = useForm<VerifyUserEmailInput>()
    const { t } = useTranslation()
    const { callFunction } = useAfterLogin()

    const [verifyUserEmailMutation, { loading }] = useVerify_User_EmailMutation();
    const [loginMutation, { loading: loginLoading }] = useLoginMutation();

    const [resendVerificationCodeMutation, { loading: resendCodeLoading }] = useResend_Verification_CodeMutation();

    const onSubmit = (data: VerifyUserEmailInput) => {
        verifyUserEmailMutation({
            variables: {
                input: {
                    code: data.code,
                    email
                }
            }
        }).then((data) => {
            toast.success(t("emailVerified"))
            if (data.data?.verifyUserEmail) {
                loginMutation({
                    variables: {
                        input: {
                            email,
                            password
                        }
                    }
                }).then((data) => {
                    const userData: DataTypes = {
                        token: data.data?.login.token!,
                        user: {
                            addresses: data.data?.login.user.addresses as any,
                            birthdate: data.data?.login.user.birthdate!,
                            email: data.data?.login.user.email!,
                            isSuper: data.data?.login.user.isSuper!,
                            firstName: data.data?.login.user.firstName!,
                            lastName: data.data?.login.user.lastName!,
                            cartCount: data.data?.login.user.cart?.items.length ?? 0,
                            isAdmin: data.data?.login.user.isAdmin!
                        }
                    }
                    callFunction(userData)
                }).catch(({ graphQLErrors }) => {
                    console.log(graphQLErrors);
                })
            }
        }).catch(({ graphQLErrors }) => {
            if (graphQLErrors[0].extensions.code === "INVALID_VERIFICATION_CODE") {
                toast.error(t("invalidVerificationCode"))
                return
            }
            setValidationError({
                graphQLErrors,
                setError
            })
        })
    }

    const resendCodeFunc = () => {
        resendVerificationCodeMutation({
            variables: {
                email
            }
        }).then(() => toast.success(t("codeSent")))
            .catch((err) => toast.success(err))
    }

    return (
        <CustomDialog
            open={open}
            handleClose={handleClose}
            title={t("verification")}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
                noValidate: true,
            }}
            content={
                <Stack>
                    <ControlMUITextField
                        control={control}
                        name='code'
                        label={t("code")}
                        rules={{
                            required: t("fieldIsRequired"),
                        }}
                    />
                </Stack>
            }
            buttonAction={
                <Stack direction={"row"} spacing={1}>
                    <LoadingButton loading={resendCodeLoading} variant='outlined' onClick={resendCodeFunc}>{t("resend")}</LoadingButton>
                    <LoadingButton loading={loading || loginLoading} variant='contained' type='submit'>{t("submit")}</LoadingButton>
                </Stack>
            }
        />
    )
}

export default VerifyDialog