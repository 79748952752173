import { useTranslation } from "react-i18next"
import { Paper, Stack, Typography } from "@mui/material"

const ListPaper = ({
    children,
    loading,
    data,
    filters = false,
    height,
    minHeight
}: {
    children: React.ReactNode,
    loading?: boolean
    data?: boolean
    filters?: boolean
    height?: string
    minHeight?: string
}) => {
    const { t } = useTranslation()
    const totalHeight = filters ? "16px + 33px + 16px + 38px" : "16px + 33px + 16px"
    return (
        <Paper sx={{
            width: '100%',
            display: "grid",
            height: height ?? `calc(100% - (${totalHeight}))`,
            gridTemplateRows: "1fr",
            ...(minHeight && { minHeight: minHeight })
        }}>
            {loading ? children :
                data ? children :
                    <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
                        <Typography textTransform={"capitalize"} py={5}>{t("emptyData")}</Typography>
                        {/* {restFilter && <ButtonLink href={restFilter} linkLabel="reset List" />} */}
                    </Stack>
            }
        </Paper>
    )
}

export default ListPaper