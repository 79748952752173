import React, { useEffect } from 'react'
import CustomDialog from '../../../Components/MUI/CustomDialog'
import { Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ControlMUITextField from '../../../Components/MUI/ControlMUItextField'
import { LoadingButton } from '@mui/lab'
import { useSave_AddressMutation } from '../../../graphql/queries/user.generated'
import { AddressInput } from '../../../graphql/types'
import { useAppDispatch } from '../../../Store/store'
import { addNewAddress, AddressesTypes, updateAddress } from '../../../Store/slices/auth'
import { setValidationError } from '../../../Components/helperFunctions/setValidationError'
import config from "../../../config.json";

const AddressDialog = ({
    open,
    handleClose,
    addressData,
    handleAddNew
}: {
    open: boolean
    handleClose: () => void
    addressData?: AddressesTypes
    handleAddNew?: (id: number) => void
}) => {
    const { handleSubmit, control, setValue, setError } = useForm<AddressInput>()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [saveAddressMutation, { loading }] = useSave_AddressMutation();

    const onSubmit = (data: AddressInput) => {
        saveAddressMutation({
            variables: {
                input: {
                    name: data.name,
                    ...(addressData && { id: addressData.id }),
                    ...(addressData && {
                        default: addressData.default,
                    }),
                    ...(data.mobile && { mobile: config.app.countryCode + data.mobile }),
                    ...(data.phone && { phone: config.app.countryCode + data.phone }),
                    address: data.address
                }
            }
        }).then((data) => {
            if (addressData) {
                dispatch(updateAddress(data.data?.saveAddress as any))
            } else {
                dispatch(addNewAddress(data.data?.saveAddress as any))
            }
            handleAddNew && handleAddNew(data.data?.saveAddress.id!)
            handleClose()
        }).catch(({ graphQLErrors }) => setValidationError({ graphQLErrors, setError }))
    }

    useEffect(() => {
        if (addressData) {
            setValue("address", addressData.address)
            setValue("phone", addressData.phone)
            setValue("mobile", addressData.mobile)
        }
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressData])


    return (
        <CustomDialog
            open={open}
            handleClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
                noValidate: true
            }}
            title={t(addressData ? "editAddress" : "addAddress")}
            content={
                <Stack spacing={2}>
                    <ControlMUITextField
                        label={t("address")}
                        control={control}
                        name="name"
                        rules={{
                            required: t("fieldIsRequired"),
                        }}
                    />
                    <ControlMUITextField
                        label={t("phone") + " 1"}
                        control={control}
                        name="phone"
                    />
                    <ControlMUITextField
                        label={t("phone") + " 2"}
                        control={control}
                        name="mobile"
                    />
                    <ControlMUITextField
                        label={t("address")}
                        control={control}
                        name="address"
                        rules={{
                            required: t("fieldIsRequired"),
                        }}
                        rows={2}
                    />
                </Stack>
            }
            buttonAction={
                <LoadingButton type='submit' variant='contained' loading={loading}>{t("submit")}</LoadingButton>
            }
        />
    )
}

export default AddressDialog