import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

// Define a type for the slice state
interface User {
    firstName: string,
    lastName: string,
    email: string,
    mobile?: string,
    image?: string,
    birthdate: string
}

export interface AddressesTypes {
    id: number
    address: string
    name: string
    default: boolean
    phone: string
    mobile: string
    date: string
}
export interface SettingsTypes {
    tax: number
    serviceTax: number
    lastOrder: string
    stripe: {
        active: boolean
        publicKey: string
    }
}

export interface AuthState {
    isLoggedIn: boolean,
    role: string
    user: User | null,
    addresses: AddressesTypes[]
    settings: SettingsTypes
}


// Define the initial state using that type
const initialState: AuthState = {
    isLoggedIn: localStorage.getItem("token") ? true : false,
    role: "",
    user: null,
    addresses: [],
    settings: {
        tax: 0,
        serviceTax: 0,
        lastOrder: "",
        stripe: {
            active: false,
            publicKey: ""
        }
    }
}

export const authSlice = createSlice({
    name: 'auth',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        changeIsLogged: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload
        },
        changeRole: (state, action: PayloadAction<string>) => {
            state.role = action.payload
        },
        changeUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload
        },
        addNewAddress: (state, action: PayloadAction<AddressesTypes>) => {
            state.addresses.push(action.payload)
        },
        deleteAddress: (state, action: PayloadAction<number>) => {
            state.addresses = state.addresses.filter(e => e.id !== action.payload)
        },
        defaultAddress: (state, action: PayloadAction<number>) => {
            const targetId = action.payload;

            state.addresses = state.addresses.map((address) => ({
                ...address,
                default: address.id === targetId,
            }));
        },
        updateAddress: (state, action: PayloadAction<AddressesTypes>) => {
            const index = state.addresses.findIndex((e) => e.id === action.payload.id);

            if (index !== -1) {
                state.addresses[index] = {
                    ...state.addresses[index],
                    ...action.payload,
                };
            }
        },
        updateSettings: (state, action: PayloadAction<SettingsTypes>) => {
            state.settings = action.payload
        },
        changeAuthData: (state, action: PayloadAction<Partial<AuthState>>) => {
            if (action.payload.user !== undefined) {
                state.user = action.payload.user;
            }
            if (action.payload.isLoggedIn !== undefined) {
                state.isLoggedIn = action.payload.isLoggedIn;
            }
            if (action.payload.role !== undefined) {
                state.role = action.payload.role;
            }
            if (action.payload.addresses !== undefined) {
                state.addresses = action.payload.addresses;
            }
        },
        resetAuthData: (state) => {
            state.isLoggedIn = false;
            state.role = initialState.role;
            state.user = initialState.user
            state.addresses = initialState.addresses
        }
    },
})

export const {
    changeIsLogged,
    changeRole,
    resetAuthData,
    changeUser,
    changeAuthData,
    addNewAddress,
    deleteAddress,
    defaultAddress,
    updateAddress,
    updateSettings
} = authSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth

export default authSlice.reducer