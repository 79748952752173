import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { t } from 'i18next';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';

const CheckoutForm = ({
    orderId
}: { orderId?: string }) => {
    const stripe = useStripe();
    const [loading, setLoading] = useState(false);
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setLoading(true)

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/profile/orders/${orderId}`, // Redirect here
            },
        });


        if (error) {
            setLoading(false)
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message as any);
            // window.location.href = `${window.location.origin}/profile/orders/${orderId}`;
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <Stack component={"form"} onSubmit={handleSubmit} spacing={2}>
            <PaymentElement />
            <LoadingButton loading={loading} disabled={!stripe} variant='contained' type='submit'>
                {t("submit")}
            </LoadingButton>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </Stack>
    )
};

export default CheckoutForm;