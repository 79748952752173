import { Grid2 as Grid, IconButton, Paper, Stack, Typography } from "@mui/material"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import ControlMUITextField from "../../../Components/MUI/ControlMUItextField"
import UploadFile from "../../../Components/MUI/UploadFile"
import { styled } from "@mui/material/styles";
import ReactQuill from 'react-quill'
import clsx from "clsx"
import MuiSwitch from "../../../Components/MUI/MuiSwitch"
import MuiSelect from "../../../Components/MUI/MuiSelect"
import { useList_Categories_DropdownQuery, useList_Product_DropdownQuery, useProduct_By_IdQuery } from "../../../graphql/queries/products.generated"
import { AddCircleOutline, Delete } from "@mui/icons-material"
import { useDelete_AddonMutation, useDelete_OptionMutation, useSave_ProductMutation } from "../../../graphql/mutations/products.generated"
import { ProductInput } from "../../../graphql/types"
import { LoadingButton } from "@mui/lab"
import Loading from "../../../Components/Loading"
import MUIAutocomplete from "../../../Components/MUI/MUIAutocomplete"
import { useEffect, useState } from "react"
import CustomDialog from "../../../Components/MUI/CustomDialog"
import { toast } from "sonner"
import { setValidationError } from "../../../Components/helperFunctions/setValidationError"

const PREFIX = "products-created";
const classes = {
    editor: `${PREFIX}-editor`,
    editorError: `${PREFIX}-editorError`,
};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.editor}`]: {
        ".ql-toolbar": {
            background: "#fff",
            direction: theme.direction,
        },
        ".ql-editor": {
            minHeight: "250px",
            direction: theme.direction,
        },
    },
    [`& .${classes.editorError}`]: {
        border: "1px solid red",
    },
}));

type SetValueKeys =
    | "id"
    | "name"
    | "price"
    | "weight"
    | "active"
    | "description"
    ;

const ProductCreate = () => {
    const { id } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [recommendedProducts, setRecommendedProducts] = useState<number[]>([])
    const [optionsIds, setOptionsIds] = useState<number[] | undefined>([])
    const [addonsIds, setAddonsIds] = useState<number[] | undefined>([])
    const [deleteOptionMutation, { loading: deleteOptionLoading }] = useDelete_OptionMutation();
    const [deleteAddonMutation, { loading: deleteAddonLoading }] = useDelete_AddonMutation();
    const [deleteOpen, setDeleteOpen] = useState({
        state: false,
        type: "",
        id: NaN,
        index: NaN,
    })

    const { control, handleSubmit, setValue, watch, setError } = useForm<Partial<ProductInput>>({
        defaultValues: {
            description: "",
            active: true
        }
    })

    const { data, loading: productByIdLoading, refetch } = useProduct_By_IdQuery({
        skip: !id,
        variables: {
            id: parseInt(id!)
        },
        fetchPolicy: "no-cache",
        onCompleted(data) {
            const productData = data.product;
            const wareHouseParams: SetValueKeys[] = ["id", "name", "price", "weight", "description"];

            wareHouseParams.forEach((i) => {
                if (productData[i] !== undefined) {
                    setValue(i, productData[i]);
                }
            });

            setValue("active", productData["active"]);


            setOptionsIds(productData.options?.map(e => e?.id!))
            setAddonsIds(productData.addons?.map(e => e?.id!))
            productData.recommendations && setRecommendedProducts(productData.recommendations?.map((e) => e?.recommended.id!))

            productData.recommendations && productData.recommendations.length > 0 &&
                setValue("recommendedProducts", productData.recommendations.map((e) => e?.recommended.id!))
        },
    });

    useEffect(() => {
        setValue("options", data?.product.options)
        setValue("addons", data?.product.addons)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])


    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],

            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],

            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': ["#6999d5", "#bc161a", "red", "green", "blue", "black"] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']
        ]
    };

    const { data: kitchensDropdownData } = useList_Categories_DropdownQuery({
        fetchPolicy: "no-cache",
        skip: id ? !data : false,
        variables: {
            input: {
                parentId: null
            }
        },
        onCompleted() {
            if (id) {
                setValue("categoryId", data?.product.category.id);
            }
        },
    });

    const { data: categoriesDropdownData } = useList_Categories_DropdownQuery({
        skip: !watch("categoryId"),
        fetchPolicy: "no-cache",
        variables: {
            input: {
                parentId: watch("categoryId")
            }
        },
        onCompleted() {
            if (id) {
                if (data?.product.category.id === watch("categoryId")) {
                    setValue("subCategoryId", data?.product.subCategory.id);
                }
            }
        },
    });

    const { data: productDropdownData } = useList_Product_DropdownQuery({
        skip: !watch("categoryId"),
        fetchPolicy: "no-cache",
        variables: {
            input: {
                categoryId: watch("categoryId")
            }
        },
    });

    const { fields, append, remove: removeOption } = useFieldArray({
        control,
        name: "options",
    });

    const { fields: fieldsAddons, append: appendAddons, remove: removeAddons } = useFieldArray({
        control,
        name: "addons",
    });

    const [saveProductMutation, { loading }] = useSave_ProductMutation();

    const onSubmit = (data: Partial<ProductInput>) => {
        const recommendedProducts = data.recommendedProducts as []
        saveProductMutation({
            variables: {
                input: {
                    ...(id && { id: parseInt(id) }),
                    name: data.name!,
                    price: Number(data.price),
                    categoryId: data.categoryId!,
                    subCategoryId: data.subCategoryId!,
                    active: data.active,
                    addons: data.addons?.map((ele) => ({
                        ...ele!,
                        price: Number(ele?.price!)
                    })),
                    description: data.description,
                    ...(data.image && {
                        image: {
                            image: data.image
                        }
                    }),
                    options: data.options,
                    weight: Number(data.weight),
                    recommendedProducts: recommendedProducts
                }
            }
        }).then(() => {
            id ? toast.success(t("updatedSuccessfully")) : toast.success(t("savedSuccessfully"))
            navigate("/dashboard/products")
        }).catch(({ graphQLErrors }) => {
            setValidationError({
                graphQLErrors,
                setError
            })
        })
    }

    const handleDeleteClose = () => {
        setDeleteOpen({
            state: false,
            type: "",
            id: NaN,
            index: NaN
        })
    }

    const handleDeleteOption = () => {
        deleteOptionMutation({
            variables: {
                id: deleteOpen.id
            }
        }).then(() => {
            refetch()
            toast.success(t("itemDeleted"))
            removeOption(deleteOpen.index)
            handleDeleteClose()
        }).catch(({ graphQLErrors }) => console.log(graphQLErrors))
    }

    const handleDeleteAddon = () => {
        deleteAddonMutation({
            variables: {
                id: deleteOpen.id
            }
        }).then(() => {
            refetch()
            toast.success(t("itemDeleted"))
            removeOption(deleteOpen.index)
            handleDeleteClose()
        }).catch(({ graphQLErrors }) => console.log(graphQLErrors))
    }

    const handleDeleteOpen = (index: number, type: string) => {
        if (type === "option") {
            if (id) {
                setDeleteOpen({
                    state: true,
                    type: type,
                    id: optionsIds?.[index]!,
                    index: index
                })
            } else {
                removeOption(index)
            }
        }
        if (type === "addon") {
            if (id) {
                setDeleteOpen({
                    state: true,
                    type: type,
                    id: addonsIds?.[index]!,
                    index: index
                })
            } else {
                removeAddons(index)
            }

        }
    }

    // const [deleteProductMutation, { loading: deleteProductLoading }] = useDelete_ProductMutation();

    // const [deleteProduct, setDeleteProduct] = useState(false)
    // const handleOpen = () => {
    //     setDeleteProduct(true)
    // }
    // const handleClose = () => {
    //     setDeleteProduct(false)
    // }
    // const handleDeleteProduct = () => {
    //     deleteProductMutation({
    //         variables: {
    //             id: parseInt(id!)
    //         }
    //     }).then(() => {
    //         toast.success(t("itemDeleted"))
    //         navigate("/dashboard/products")
    //     }).catch(({ graphQLErrors }) => console.log(graphQLErrors))
    // }

    return productByIdLoading ? <Loading /> : (
        <Root spacing={2}>
            {/* {deleteProduct && <CustomDialog
                open={deleteProduct}
                handleClose={handleClose}
                content={
                    <Stack alignItems={"center"} py={2}>
                        {t("areYouSure")}
                    </Stack>
                }
                buttonAction={
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={deleteProductLoading}
                        color="error"
                        size="small"
                        onClick={handleDeleteProduct}
                    >
                        {t("delete")}
                    </LoadingButton>
                }
            />} */}
            <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                <Typography fontSize={20}>{t("newProduct")}</Typography>
                {/* <Button size="small" variant="contained" onClick={handleOpen}>
                    {t("delete")}
                </Button> */}
            </Stack>
            <CustomDialog
                open={deleteOpen.state}
                handleClose={handleDeleteClose}
                content={t("areYouSure")}
                buttonAction={
                    <LoadingButton
                        variant="contained"
                        loading={deleteOptionLoading || deleteAddonLoading}
                        onClick={deleteOpen.type === "option" ? handleDeleteOption : handleDeleteAddon}
                    >
                        {t("delete")}
                    </LoadingButton>
                }
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack component={Paper} p={2} spacing={2}>
                    <Grid container spacing={2} m={0}>
                        <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"flex-end"}>
                            <MuiSwitch
                                edge="end"
                                name="active"
                                label={t("active")}
                                control={control}
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <ControlMUITextField
                                label={t("name")}
                                control={control}
                                name="name"
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <UploadFile
                                control={control}
                                setValue={setValue}
                                name="image"
                                icon={"add_photo_alternate"}
                                label={t("image")}
                                accept=".png,.jpg,.jpeg,.webp"
                                maxSize={1000 * 1024}
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <ControlMUITextField
                                label={t("price")}
                                control={control}
                                name="price"
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                                type="number"
                                slotProps={{
                                    input: {
                                        inputProps: {
                                            min: 0,
                                        },
                                    }
                                }}
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <ControlMUITextField
                                label={t("minWeight")}
                                control={control}
                                name="weight"
                                type="number"
                                slotProps={{
                                    input: {
                                        inputProps: {
                                            min: 0,
                                        },
                                    }
                                }}
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <MuiSelect
                                name='categoryId'
                                label={t('kitchen')}
                                control={control}
                                data={!kitchensDropdownData ?
                                    [] : kitchensDropdownData?.listCategoriesDropdown.map((item) => ({
                                        key: item?.name!,
                                        value: item?.id!,
                                    }))}
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                                onChanges={() => {
                                    setValue("subCategoryId", undefined)
                                    setValue("recommendedProducts", [])
                                }}
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <MuiSelect
                                name='subCategoryId'
                                disabled={!watch("categoryId")}
                                label={t('category')}
                                control={control}
                                data={!categoriesDropdownData ?
                                    [] : categoriesDropdownData?.listCategoriesDropdown.map((item) => ({
                                        key: item?.name!,
                                        value: item?.id!,
                                    }))}
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <MUIAutocomplete
                                name='recommendedProducts'
                                label={t('recommendedProducts')}
                                disabled={!watch("categoryId")}
                                control={control}
                                defaultValue={recommendedProducts}
                                data={!productDropdownData ?
                                    [] : productDropdownData?.listProductsDropdown.filter(e => e?.id !== parseInt(id!)).map((item) => ({
                                        key: item?.name!,
                                        value: item?.id!,
                                    }))}
                            />
                        </Grid>
                        {/* <Grid size={{ xs: 6 }}>
                            <MuiSelect
                                name='productId'
                                label={t('products')}
                                control={control}
                                data={!productDropdownData ?
                                    [] : productDropdownData?.listProductsDropdown.map((item) => ({
                                        key: item?.name!,
                                        value: item?.id!,
                                    }))}
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                            />
                        </Grid> */}
                        <Grid size={{ md: 6, xs: 12 }}>
                            <Stack component={Paper} alignItems={"center"} spacing={2} px={2} py={1}>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                                    <Typography fontSize={18}>{t("options")}</Typography>
                                    <IconButton
                                        onClick={() => {
                                            append({ name: "", value: true });
                                        }}
                                    >
                                        <AddCircleOutline color="success" />
                                    </IconButton>
                                </Stack>
                                {fields.length > 0 && <Stack spacing={2} width={"100%"}>
                                    {fields.map((item, index) => {
                                        return (
                                            <Stack key={item.id} direction={"row"} justifyContent={"space-between"} >
                                                <ControlMUITextField
                                                    label={t("name")}
                                                    control={control}
                                                    name={`options.${index}.name`}
                                                    rules={{
                                                        required: t("fieldIsRequired"),
                                                    }}
                                                />
                                                <Stack>
                                                    <MuiSwitch
                                                        edge="end"
                                                        name={`options.${index}.value`}
                                                        label={""}
                                                        control={control}
                                                    />
                                                </Stack>
                                                <IconButton onClick={() => handleDeleteOpen(index, "option")} color="error">
                                                    <Delete />
                                                </IconButton>
                                            </Stack>
                                        );
                                    })}
                                </Stack>}
                            </Stack>
                        </Grid>
                        <Grid size={{ md: 6, xs: 12 }}>
                            <Stack component={Paper} alignItems={"center"} spacing={2} px={2} py={1}>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                                    <Typography fontSize={18}>{t("addons")}</Typography>
                                    <IconButton
                                        onClick={() => {
                                            appendAddons({ name: "", price: 0 });
                                        }}
                                    >
                                        <AddCircleOutline color="success" />
                                    </IconButton>
                                </Stack>
                                {fieldsAddons.length > 0 && <Stack spacing={2} width={"100%"}>
                                    {fieldsAddons.map((item, index) => {
                                        return (
                                            <Stack key={item.id} direction={"row"} spacing={1}>
                                                <ControlMUITextField
                                                    label={t("name")}
                                                    control={control}
                                                    name={`addons.${index}.name`}
                                                    rules={{
                                                        required: t("fieldIsRequired"),
                                                    }}
                                                />
                                                <ControlMUITextField
                                                    label={t("price")}
                                                    control={control}
                                                    name={`addons.${index}.price`}
                                                    rules={{
                                                        required: t("fieldIsRequired"),
                                                    }}
                                                />
                                                <IconButton onClick={() => handleDeleteOpen(index, "addon")} color="error">
                                                    <Delete />
                                                </IconButton>
                                            </Stack>
                                        );
                                    })}
                                </Stack>}
                            </Stack>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <Typography variant='h5' mb={2}>{t("description")}</Typography>
                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                                render={({ field, fieldState }) => (
                                    <div>
                                        <ReactQuill
                                            theme="snow"
                                            value={field.value!}
                                            onChange={field.onChange}
                                            modules={modules}
                                            className={clsx(classes.editor, {
                                                [classes.editorError]: fieldState.error,
                                            })}
                                        />
                                        {fieldState.error && (
                                            <Typography color="error">
                                                {fieldState.error.message}
                                            </Typography>
                                        )}
                                    </div>
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Stack alignItems={"flex-end"}>
                        <LoadingButton loading={loading} variant="contained" color="success" type="submit">
                            {t("save")}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </form>
        </Root>
    )
}

export default ProductCreate