import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Stack, Typography, styled } from '@mui/material';

import { useTranslation } from 'react-i18next';
import ListPaper from '../Layouts/ListPaper';
import { CellLink } from '../Layouts/CellLink';
import { FixedTableCell } from '../Layouts/FixedTableCell';
import MUITablePagination from '../Layouts/TablePagination';
import TableBodyWithLoad from '../Layouts/TableBodyWithLoad';
import ButtonLink from '../../../Components/ButtonLink';
import { useList_ProductsQuery } from '../../../graphql/queries/products.generated';
import { RamenDining } from '@mui/icons-material';
import BooleanCell from '../../../Components/MUI/BooleanCell';
import { getBackendUri } from '../../../Components/helperFunctions/getBackendUri';

const PREFIX = "products";
const classes = {
  filter: `${PREFIX}-filter`,
  filterActive: `${PREFIX}-filterActive`,
  title: `${PREFIX}-title`,
};
const Root = styled("div")(({ theme }) => ({
  height: "100%",
  [`& .${classes.filter}`]: {
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    [`&:hover`]: {
      background: theme.palette.divider
    },
  },
  [`& .${classes.filterActive}`]: {
    background: theme.palette.divider
  },
  [`& .${classes.title}`]: {
    fontSize: "22px"
  },
}));

export default function ProductsList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const { t } = useTranslation();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data, loading } = useList_ProductsQuery({
    fetchPolicy: "no-cache",
    variables: {
      first: rowsPerPage,
      page: page + 1
    },
  });

  const tableCellHeader = [
    "image", "name", "price", "kitchen", "category", "active", "weight"
  ]

  return (
    <Root>
      <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
        <Typography className={classes.title}>{t(PREFIX)}</Typography>
        <ButtonLink to={`create`} text={t("addProduct")} />
      </Stack>
      <ListPaper loading={loading} data={!!(data && data.listProducts.data.length)}  >
        <TableContainer sx={{ width: "100%", overflow: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableCellHeader.map(e =>
                  <TableCell align={'left'} key={e}>
                    {t(e)}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBodyWithLoad loading={loading} tableCellHeaderLength={tableCellHeader.length}>
              <TableBody>
                {data?.listProducts.data.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <CellLink to={`${row.id}`}>
                        <Avatar>
                          {row.image?.path ? <img src={getBackendUri(row.image?.path)} alt='product' width={50} height={50} />
                            : <RamenDining />}
                        </Avatar>
                      </CellLink>
                      <FixedTableCell>
                        {row.name}
                      </FixedTableCell>
                      <FixedTableCell align={'left'}>
                        {row.price}
                      </FixedTableCell>
                      <FixedTableCell align={'left'}>
                        {row.category?.name}
                      </FixedTableCell>
                      <FixedTableCell align={'left'}>
                        {row.subCategory?.name}
                      </FixedTableCell>
                      <FixedTableCell align={'left'}>
                        <BooleanCell value={row.active} />
                      </FixedTableCell>
                      <FixedTableCell align={'left'}>
                        {row.weight}
                      </FixedTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </TableBodyWithLoad>
          </Table>
        </TableContainer>
        <MUITablePagination
          count={data?.listProducts.paginatorInfo.total}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ListPaper>
    </Root>
  );
}
