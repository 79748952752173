import { Grid2 as Grid, Paper, Stack, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { styled } from "@mui/material/styles";
import { SettingsInput } from "../../../graphql/types"
import { LoadingButton } from "@mui/lab"
import Loading from "../../../Components/Loading"
import ControlMUITextField from "../../../Components/MUI/ControlMUItextField";
import { useUpdate_SettingsMutation } from "../../../graphql/mutations/settings.generated";
import { toast } from "sonner";
import moment from "moment";
import MuiTimePicker from "../../../Components/MUI/MuiTimePicker";
import MuiSwitch from "../../../Components/MUI/MuiSwitch";
import { ROLE } from "../../../types";
import { useAppSelector } from "../../../Store/store";
import * as gqlb from "gql-query-builder";
import { gql, useQuery } from "@apollo/client";

const PREFIX = "products-created";
const classes = {
    editor: `${PREFIX}-editor`,
    editorError: `${PREFIX}-editorError`,
};

export const SETTINGS = (isSuper: boolean) => {
    return gqlb.query({
        operation: "settings",
        fields: [
            "lastOrder",
            "tax",
            "deliveryFess",
            "serviceTax",
            "reservationHours",
            isSuper ? {
                stripe: [
                    "publicKey",
                    "secretKey",
                    "webhookSecret",
                    "active"
                ]
            } : ""
        ],
        variables: {},
    });
};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.editor}`]: {
        ".ql-toolbar": {
            background: "#fff",
            direction: theme.direction,
        },
        ".ql-editor": {
            minHeight: "250px",
            direction: theme.direction,
        },
    },
    [`& .${classes.editorError}`]: {
        border: "1px solid red",
    },
}));

type SetValueKeys =
    | "tax"
    | "deliveryFess"
    | "serviceTax"
    | "reservationHours";

const getTime = (time: string) => moment(time).locale("en").format('HH:mm');
const stringFormatToDate = (date: string) => moment(date, "HH:mm").locale("en").toDate();

const Settings = () => {
    const { t } = useTranslation()
    const { role } = useAppSelector((e) => e.auth)
    const { control, handleSubmit, setValue } = useForm<SettingsInput>()
    const isSuper = role === ROLE.SUPER


    const { loading: settingsLoading } = useQuery(
        gql`
      ${SETTINGS(isSuper).query}
    `,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            onCompleted(data) {
                const settings = data.settings;
                const settingsParams: SetValueKeys[] = ["tax", "deliveryFess", "reservationHours", "serviceTax"];

                settingsParams.forEach((i) => {
                    if (settings[i]) {
                        setValue(i, settings[i]);
                    }
                });

                if (isSuper) {
                    setValue("stripe.active", settings.stripe?.active);
                    setValue("stripe.publicKey", settings.stripe?.publicKey);
                    setValue("stripe.secretKey", settings.stripe?.secretKey);
                    setValue("stripe.webhookSecret", settings.stripe?.webhookSecret);
                }

                setValue("lastOrder", stringFormatToDate(data.settings.lastOrder))
            },
        }
    );

    const [updateSettingsMutation, { loading }] = useUpdate_SettingsMutation();

    const onSubmit = (data: SettingsInput) => {
        updateSettingsMutation({
            variables: {
                input: {
                    deliveryFess: Number(data.deliveryFess),
                    tax: Number(data.tax),
                    lastOrder: getTime(data.lastOrder),
                    reservationHours: 2,
                    serviceTax: Number(data.serviceTax),
                    ...(isSuper && {
                        stripe: {
                            ...data.stripe
                        }
                    })
                }
            }
        }).then(() => {
            toast.success(t("savedSuccessfully"))
        }).catch(({ graphQLErrors }) => {
            toast.error(graphQLErrors[0].message)
        })
    }

    return settingsLoading ? <Loading /> : (
        <Root spacing={2}>
            <Typography fontSize={20}>{t("settings")}</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack component={Paper} p={2} spacing={2}>
                    <Grid container spacing={2} m={0}>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <ControlMUITextField
                                label={t("deliveryFees")}
                                control={control}
                                name="deliveryFess"
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                                type="number"
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <ControlMUITextField
                                label={t("taxFees")}
                                control={control}
                                name="tax"
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                                type="number"
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <ControlMUITextField
                                label={t("serviceTax")}
                                control={control}
                                name="serviceTax"
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                                type="number"
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <MuiTimePicker
                                label={t("lastOrder")}
                                control={control}
                                name="lastOrder"
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>

                        </Grid>
                        {isSuper && <>
                            <Grid size={{ xs: 6 }}>
                                <Typography>{t("stripe")}</Typography>
                            </Grid>
                            <Grid size={{ xs: 6 }}>
                                <MuiSwitch
                                    edge="end"
                                    name="stripe.active"
                                    label={t("active")}
                                    control={control}
                                />
                            </Grid>
                            <Grid size={{ sm: 6, xs: 12 }}>
                                <ControlMUITextField
                                    label={t("publicKey")}
                                    control={control}
                                    name="stripe.publicKey"
                                />
                            </Grid>
                            <Grid size={{ sm: 6, xs: 12 }}>
                                <ControlMUITextField
                                    label={t("secretKey")}
                                    control={control}
                                    name="stripe.secretKey"
                                />
                            </Grid>
                            <Grid size={{ sm: 6, xs: 12 }}>
                                <ControlMUITextField
                                    label={t("webhookSecret")}
                                    control={control}
                                    name="stripe.webhookSecret"
                                />
                            </Grid>
                        </>}
                    </Grid>
                    <Stack alignItems={"flex-end"}>
                        <LoadingButton loading={loading} variant="contained" color="success" type="submit">
                            {t("save")}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </form>
        </Root>
    )
}

export default Settings