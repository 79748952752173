import { Avatar, Container, Grid2 as Grid, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SectionTitle from "../Components/SectionTitle";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { useList_BranchesQuery } from "../graphql/queries/branches.generated";
import ButtonLink from "../Components/ButtonLink";
import { useLocation } from "react-router-dom";
import { slugify } from "../Components/helperFunctions/function";
import { getBackendUri } from "../Components/helperFunctions/getBackendUri";
import noImage from "../assets/images/no-image-svgrepo-com.svg"

const PREFIX = "products-created";
const classes = {
    avatar: `${PREFIX}-avatar`,
    social: `${PREFIX}-social`,
};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.social}`]: {
        left: "50%",
        transform: "translateX(-50%)",
        position: "absolute",
        bottom: -20
    },
    [`& .${classes.avatar}`]: {
        background: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
    },
}));

const Branches = () => {
    const { t } = useTranslation()
    const { pathname } = useLocation()

    const { data, loading } = useList_BranchesQuery({
        variables: {
            first: 100,
            page: 1
        },
    });

    const newPathname = pathname.substring(1); // Removes the first character

    return (
        <Root py={7}>
            <Container maxWidth={"lg"}>
                <SectionTitle sectionTitle={t("branches")} />
                <Grid container spacing={2} m={0} justifyContent={"center"}>
                    {!loading && data?.listBranches.data.map((branch) =>
                        <Grid size={{ md: 4, sm: 6, xs: 12 }} key={branch.id}>
                            <Stack component={Paper} overflow={"hidden"} alignItems={"center"} spacing={1} pb={2} elevation={4}>
                                <Stack height={300} width={"100%"} position={"relative"} mb={"30px !important"}>
                                    <img src={branch.image?.path ? getBackendUri(branch.image?.path) : noImage} alt="" width={"100%"} height={"100%"} style={{ objectFit: "fill" }} />
                                    <Stack direction={"row"} spacing={2} className={classes.social}>
                                        <Avatar className={classes.avatar}>
                                            <a
                                                href={`https://wa.me/${branch.whatsapp}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                title="whatsApp"
                                            >
                                                <FaWhatsapp color='#25D366' size={25} />
                                            </a>
                                        </Avatar>
                                        <Avatar className={classes.avatar}>
                                            <a
                                                href={`tel:${branch.telephone}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                title="whatsApp"
                                            >
                                                <FaPhoneAlt color='#0088cc' size={20} />
                                            </a>
                                        </Avatar>
                                    </Stack>
                                </Stack>
                                <Typography color="primary">{branch.name}</Typography>
                                <Typography>{branch.address}</Typography>
                                <ButtonLink
                                    variant="outlined"
                                    color="secondary"
                                    text={t("moreDetails")}
                                    to={`${newPathname ? "" : "branches/"}${branch.id}/${slugify(branch.name ?? "")}`}
                                />
                            </Stack>
                        </Grid>
                    )}
                    {loading &&
                        [1, 2, 3].map((e) => <Grid size={{ md: 4, sm: 6, xs: 12 }} key={e}>
                            <Stack component={Paper} overflow={"hidden"} alignItems={"center"} spacing={1} pb={2} elevation={4}>
                                <Stack height={300} width={"100%"} position={"relative"} mb={"30px !important"}>
                                    <Skeleton variant="rounded" animation="wave" width={"100%"} height={"100%"} />
                                    <Stack direction={"row"} spacing={2} className={classes.social}>
                                        <Avatar className={classes.avatar}>
                                            <Skeleton animation="wave" variant="circular" width={"100%"} height={"100%"} />
                                        </Avatar>
                                        <Avatar className={classes.avatar}>
                                            <Skeleton animation="wave" variant="circular" width={"100%"} height={"100%"} />
                                        </Avatar>
                                    </Stack>
                                </Stack>
                                <Skeleton animation="wave" variant="text" width={"60px"} />
                                <Skeleton animation="wave" variant="text" width={"200px"} />
                                <Skeleton animation="wave" variant="rounded" width={"80px"} height={35} />
                            </Stack>
                        </Grid>)
                    }
                </Grid>
            </Container>
        </Root>
    )
}

export default Branches