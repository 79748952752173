import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type List_CategoriesQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListCategoriesFilterInput>;
  first: Types.Scalars['Int']['input'];
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type List_CategoriesQuery = { __typename?: 'Query', listCategories: { __typename?: 'CategoryPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, total: number }, data: Array<{ __typename?: 'Category', id: number, name: string, active: boolean, date: any, productsCount: number, parent?: { __typename?: 'Category', name: string, id: number } | null, image?: { __typename?: 'Image', path: string } | null }> } };

export type List_Categories_DropdownQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListCategoriesFilterInput>;
}>;


export type List_Categories_DropdownQuery = { __typename?: 'Query', listCategoriesDropdown: Array<{ __typename?: 'DropdownEntry', id: number, name: string } | null> };

export type List_Product_DropdownQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListProductsFilterInput>;
}>;


export type List_Product_DropdownQuery = { __typename?: 'Query', listProductsDropdown: Array<{ __typename?: 'DropdownEntry', id: number, name: string } | null> };

export type List_ProductsQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListProductsFilterInput>;
  first: Types.Scalars['Int']['input'];
  page: Types.Scalars['Int']['input'];
}>;


export type List_ProductsQuery = { __typename?: 'Query', listProducts: { __typename?: 'ProductPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, total: number }, data: Array<{ __typename?: 'Product', id: number, name: string, active: boolean, date: any, weight?: number | null, price: number, category: { __typename?: 'Category', name: string }, subCategory: { __typename?: 'Category', name: string }, image?: { __typename?: 'Image', path: string } | null }> } };

export type List_Products_PublicQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListProductsFilterInput>;
  first: Types.Scalars['Int']['input'];
  page: Types.Scalars['Int']['input'];
}>;


export type List_Products_PublicQuery = { __typename?: 'Query', listProducts: { __typename?: 'ProductPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, total: number }, data: Array<{ __typename?: 'Product', id: number, name: string, active: boolean, date: any, image?: { __typename?: 'Image', path: string } | null, category: { __typename?: 'Category', name: string, id: number }, subCategory: { __typename?: 'Category', name: string, id: number } }> } };

export type Category_By_IdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type Category_By_IdQuery = { __typename?: 'Query', category: { __typename?: 'Category', id: number, name: string } };

export type Product_By_IdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type Product_By_IdQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: number, name: string, description?: string | null, active: boolean, date: any, weight?: number | null, price: number, addons?: Array<{ __typename?: 'Addon', id: number, name: string, price: number } | null> | null, category: { __typename?: 'Category', id: number, name: string }, subCategory: { __typename?: 'Category', id: number, name: string }, image?: { __typename?: 'Image', path: string } | null, options: Array<{ __typename?: 'Option', id: number, name: string, value: boolean } | null>, recommendations?: Array<{ __typename?: 'Recommendation', id: number, recommended: { __typename?: 'Product', id: number, name: string, price: number, image?: { __typename?: 'Image', path: string } | null } } | null> | null } };

export type Product_Addons_By_IdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type Product_Addons_By_IdQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: number, addons?: Array<{ __typename?: 'Addon', id: number, name: string, price: number } | null> | null } };


export const List_CategoriesDocument = gql`
    query LIST_CATEGORIES($input: ListCategoriesFilterInput, $first: Int!, $page: Int) {
  listCategories(input: $input, first: $first, page: $page) {
    paginatorInfo {
      count
      total
    }
    data {
      id
      name
      active
      date
      productsCount
      parent {
        name
        id
      }
      image {
        path
      }
    }
  }
}
    `;

/**
 * __useList_CategoriesQuery__
 *
 * To run a query within a React component, call `useList_CategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_CategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_CategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useList_CategoriesQuery(baseOptions: Apollo.QueryHookOptions<List_CategoriesQuery, List_CategoriesQueryVariables> & ({ variables: List_CategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_CategoriesQuery, List_CategoriesQueryVariables>(List_CategoriesDocument, options);
      }
export function useList_CategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_CategoriesQuery, List_CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_CategoriesQuery, List_CategoriesQueryVariables>(List_CategoriesDocument, options);
        }
export function useList_CategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_CategoriesQuery, List_CategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_CategoriesQuery, List_CategoriesQueryVariables>(List_CategoriesDocument, options);
        }
export type List_CategoriesQueryHookResult = ReturnType<typeof useList_CategoriesQuery>;
export type List_CategoriesLazyQueryHookResult = ReturnType<typeof useList_CategoriesLazyQuery>;
export type List_CategoriesSuspenseQueryHookResult = ReturnType<typeof useList_CategoriesSuspenseQuery>;
export type List_CategoriesQueryResult = Apollo.QueryResult<List_CategoriesQuery, List_CategoriesQueryVariables>;
export const List_Categories_DropdownDocument = gql`
    query LIST_CATEGORIES_DROPDOWN($input: ListCategoriesFilterInput) {
  listCategoriesDropdown(input: $input) {
    id
    name
  }
}
    `;

/**
 * __useList_Categories_DropdownQuery__
 *
 * To run a query within a React component, call `useList_Categories_DropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Categories_DropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Categories_DropdownQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useList_Categories_DropdownQuery(baseOptions?: Apollo.QueryHookOptions<List_Categories_DropdownQuery, List_Categories_DropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_Categories_DropdownQuery, List_Categories_DropdownQueryVariables>(List_Categories_DropdownDocument, options);
      }
export function useList_Categories_DropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Categories_DropdownQuery, List_Categories_DropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_Categories_DropdownQuery, List_Categories_DropdownQueryVariables>(List_Categories_DropdownDocument, options);
        }
export function useList_Categories_DropdownSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_Categories_DropdownQuery, List_Categories_DropdownQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_Categories_DropdownQuery, List_Categories_DropdownQueryVariables>(List_Categories_DropdownDocument, options);
        }
export type List_Categories_DropdownQueryHookResult = ReturnType<typeof useList_Categories_DropdownQuery>;
export type List_Categories_DropdownLazyQueryHookResult = ReturnType<typeof useList_Categories_DropdownLazyQuery>;
export type List_Categories_DropdownSuspenseQueryHookResult = ReturnType<typeof useList_Categories_DropdownSuspenseQuery>;
export type List_Categories_DropdownQueryResult = Apollo.QueryResult<List_Categories_DropdownQuery, List_Categories_DropdownQueryVariables>;
export const List_Product_DropdownDocument = gql`
    query LIST_PRODUCT_DROPDOWN($input: ListProductsFilterInput) {
  listProductsDropdown(input: $input) {
    id
    name
  }
}
    `;

/**
 * __useList_Product_DropdownQuery__
 *
 * To run a query within a React component, call `useList_Product_DropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Product_DropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Product_DropdownQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useList_Product_DropdownQuery(baseOptions?: Apollo.QueryHookOptions<List_Product_DropdownQuery, List_Product_DropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_Product_DropdownQuery, List_Product_DropdownQueryVariables>(List_Product_DropdownDocument, options);
      }
export function useList_Product_DropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Product_DropdownQuery, List_Product_DropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_Product_DropdownQuery, List_Product_DropdownQueryVariables>(List_Product_DropdownDocument, options);
        }
export function useList_Product_DropdownSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_Product_DropdownQuery, List_Product_DropdownQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_Product_DropdownQuery, List_Product_DropdownQueryVariables>(List_Product_DropdownDocument, options);
        }
export type List_Product_DropdownQueryHookResult = ReturnType<typeof useList_Product_DropdownQuery>;
export type List_Product_DropdownLazyQueryHookResult = ReturnType<typeof useList_Product_DropdownLazyQuery>;
export type List_Product_DropdownSuspenseQueryHookResult = ReturnType<typeof useList_Product_DropdownSuspenseQuery>;
export type List_Product_DropdownQueryResult = Apollo.QueryResult<List_Product_DropdownQuery, List_Product_DropdownQueryVariables>;
export const List_ProductsDocument = gql`
    query LIST_PRODUCTS($input: ListProductsFilterInput, $first: Int!, $page: Int!) {
  listProducts(input: $input, first: $first, page: $page) {
    paginatorInfo {
      count
      total
    }
    data {
      id
      name
      category {
        name
      }
      subCategory {
        name
      }
      active
      date
      image {
        path
      }
      weight
      price
    }
  }
}
    `;

/**
 * __useList_ProductsQuery__
 *
 * To run a query within a React component, call `useList_ProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_ProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_ProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useList_ProductsQuery(baseOptions: Apollo.QueryHookOptions<List_ProductsQuery, List_ProductsQueryVariables> & ({ variables: List_ProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_ProductsQuery, List_ProductsQueryVariables>(List_ProductsDocument, options);
      }
export function useList_ProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_ProductsQuery, List_ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_ProductsQuery, List_ProductsQueryVariables>(List_ProductsDocument, options);
        }
export function useList_ProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_ProductsQuery, List_ProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_ProductsQuery, List_ProductsQueryVariables>(List_ProductsDocument, options);
        }
export type List_ProductsQueryHookResult = ReturnType<typeof useList_ProductsQuery>;
export type List_ProductsLazyQueryHookResult = ReturnType<typeof useList_ProductsLazyQuery>;
export type List_ProductsSuspenseQueryHookResult = ReturnType<typeof useList_ProductsSuspenseQuery>;
export type List_ProductsQueryResult = Apollo.QueryResult<List_ProductsQuery, List_ProductsQueryVariables>;
export const List_Products_PublicDocument = gql`
    query LIST_PRODUCTS_PUBLIC($input: ListProductsFilterInput, $first: Int!, $page: Int!) {
  listProducts(input: $input, first: $first, page: $page) {
    paginatorInfo {
      count
      total
    }
    data {
      id
      name
      active
      date
      image {
        path
      }
      category {
        name
        id
      }
      subCategory {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useList_Products_PublicQuery__
 *
 * To run a query within a React component, call `useList_Products_PublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Products_PublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Products_PublicQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useList_Products_PublicQuery(baseOptions: Apollo.QueryHookOptions<List_Products_PublicQuery, List_Products_PublicQueryVariables> & ({ variables: List_Products_PublicQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_Products_PublicQuery, List_Products_PublicQueryVariables>(List_Products_PublicDocument, options);
      }
export function useList_Products_PublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Products_PublicQuery, List_Products_PublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_Products_PublicQuery, List_Products_PublicQueryVariables>(List_Products_PublicDocument, options);
        }
export function useList_Products_PublicSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_Products_PublicQuery, List_Products_PublicQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_Products_PublicQuery, List_Products_PublicQueryVariables>(List_Products_PublicDocument, options);
        }
export type List_Products_PublicQueryHookResult = ReturnType<typeof useList_Products_PublicQuery>;
export type List_Products_PublicLazyQueryHookResult = ReturnType<typeof useList_Products_PublicLazyQuery>;
export type List_Products_PublicSuspenseQueryHookResult = ReturnType<typeof useList_Products_PublicSuspenseQuery>;
export type List_Products_PublicQueryResult = Apollo.QueryResult<List_Products_PublicQuery, List_Products_PublicQueryVariables>;
export const Category_By_IdDocument = gql`
    query CATEGORY_BY_ID($id: Int!) {
  category(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useCategory_By_IdQuery__
 *
 * To run a query within a React component, call `useCategory_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategory_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategory_By_IdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategory_By_IdQuery(baseOptions: Apollo.QueryHookOptions<Category_By_IdQuery, Category_By_IdQueryVariables> & ({ variables: Category_By_IdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Category_By_IdQuery, Category_By_IdQueryVariables>(Category_By_IdDocument, options);
      }
export function useCategory_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Category_By_IdQuery, Category_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Category_By_IdQuery, Category_By_IdQueryVariables>(Category_By_IdDocument, options);
        }
export function useCategory_By_IdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Category_By_IdQuery, Category_By_IdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Category_By_IdQuery, Category_By_IdQueryVariables>(Category_By_IdDocument, options);
        }
export type Category_By_IdQueryHookResult = ReturnType<typeof useCategory_By_IdQuery>;
export type Category_By_IdLazyQueryHookResult = ReturnType<typeof useCategory_By_IdLazyQuery>;
export type Category_By_IdSuspenseQueryHookResult = ReturnType<typeof useCategory_By_IdSuspenseQuery>;
export type Category_By_IdQueryResult = Apollo.QueryResult<Category_By_IdQuery, Category_By_IdQueryVariables>;
export const Product_By_IdDocument = gql`
    query PRODUCT_BY_ID($id: Int!) {
  product(id: $id) {
    id
    name
    description
    addons {
      id
      name
      price
    }
    category {
      id
      name
    }
    subCategory {
      id
      name
    }
    active
    date
    image {
      path
    }
    weight
    price
    options {
      id
      name
      value
    }
    recommendations {
      id
      recommended {
        id
        image {
          path
        }
        name
        price
      }
    }
  }
}
    `;

/**
 * __useProduct_By_IdQuery__
 *
 * To run a query within a React component, call `useProduct_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProduct_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProduct_By_IdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProduct_By_IdQuery(baseOptions: Apollo.QueryHookOptions<Product_By_IdQuery, Product_By_IdQueryVariables> & ({ variables: Product_By_IdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Product_By_IdQuery, Product_By_IdQueryVariables>(Product_By_IdDocument, options);
      }
export function useProduct_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Product_By_IdQuery, Product_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Product_By_IdQuery, Product_By_IdQueryVariables>(Product_By_IdDocument, options);
        }
export function useProduct_By_IdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Product_By_IdQuery, Product_By_IdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Product_By_IdQuery, Product_By_IdQueryVariables>(Product_By_IdDocument, options);
        }
export type Product_By_IdQueryHookResult = ReturnType<typeof useProduct_By_IdQuery>;
export type Product_By_IdLazyQueryHookResult = ReturnType<typeof useProduct_By_IdLazyQuery>;
export type Product_By_IdSuspenseQueryHookResult = ReturnType<typeof useProduct_By_IdSuspenseQuery>;
export type Product_By_IdQueryResult = Apollo.QueryResult<Product_By_IdQuery, Product_By_IdQueryVariables>;
export const Product_Addons_By_IdDocument = gql`
    query PRODUCT_ADDONS_BY_ID($id: Int!) {
  product(id: $id) {
    id
    addons {
      id
      name
      price
    }
  }
}
    `;

/**
 * __useProduct_Addons_By_IdQuery__
 *
 * To run a query within a React component, call `useProduct_Addons_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProduct_Addons_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProduct_Addons_By_IdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProduct_Addons_By_IdQuery(baseOptions: Apollo.QueryHookOptions<Product_Addons_By_IdQuery, Product_Addons_By_IdQueryVariables> & ({ variables: Product_Addons_By_IdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Product_Addons_By_IdQuery, Product_Addons_By_IdQueryVariables>(Product_Addons_By_IdDocument, options);
      }
export function useProduct_Addons_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Product_Addons_By_IdQuery, Product_Addons_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Product_Addons_By_IdQuery, Product_Addons_By_IdQueryVariables>(Product_Addons_By_IdDocument, options);
        }
export function useProduct_Addons_By_IdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Product_Addons_By_IdQuery, Product_Addons_By_IdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Product_Addons_By_IdQuery, Product_Addons_By_IdQueryVariables>(Product_Addons_By_IdDocument, options);
        }
export type Product_Addons_By_IdQueryHookResult = ReturnType<typeof useProduct_Addons_By_IdQuery>;
export type Product_Addons_By_IdLazyQueryHookResult = ReturnType<typeof useProduct_Addons_By_IdLazyQuery>;
export type Product_Addons_By_IdSuspenseQueryHookResult = ReturnType<typeof useProduct_Addons_By_IdSuspenseQuery>;
export type Product_Addons_By_IdQueryResult = Apollo.QueryResult<Product_Addons_By_IdQuery, Product_Addons_By_IdQueryVariables>;