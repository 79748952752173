import { Alert, Avatar, Button, FormControl, FormControlLabel, Grid2 as Grid, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { Add, Error, Place, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../Store/store';
import clsx from 'clsx';
import { AddressData } from './AddressPaper';
import { CustomTypography } from '../Checkout';
import { Controller } from 'react-hook-form';

const DeliveryStack = ({
    classes,
    addressError,
    control,
    handleOpen
}: {
    classes: any,
    addressError: string | null,
    control: any,
    handleOpen: () => void
}) => {
    const { addresses } = useAppSelector((state) => state.auth);
    const { t } = useTranslation()
    // const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setAddressValue(Number((event.target as HTMLInputElement).value));
    // };
    return (
        <Stack spacing={2} id="Delivery">

            <FormControl component={Stack} spacing={addresses.length === 0 ? 0 : 2}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <CustomTypography>{t("addresses")}</CustomTypography>
                    <Stack alignItems={"flex-start"}>
                        <Button
                            size="small"
                            variant="outlined"
                            endIcon={<Add fontSize="small" />}
                            onClick={handleOpen}
                        >
                            {t("addAddress")}
                        </Button>
                    </Stack>
                </Stack>
                <Controller
                    name="addressId"
                    control={control}
                    render={({ field, formState: { errors } }) => (
                        <RadioGroup
                            {...field}
                            row
                        >
                            {errors?.["addressId"]?.message && <Alert color='error' icon={<Error />}>{errors?.["addressId"]?.message as any | undefined}</Alert>}
                            <Grid container spacing={2} width={"100%"}>
                                {addresses.map(address => <Grid size={{ sm: 6, xs: 12 }} key={address.id}>
                                    <FormControlLabel
                                        className={clsx(classes.radioAddressStyle, { [classes.radioStyleActive]: +field.value === address.id })}
                                        value={address.id}
                                        control={<Radio sx={{ display: "none" }} />}
                                        label={
                                            <Stack p={1} spacing={1} width={"100%"}>
                                                <Stack direction={"row"} justifyContent={"space-between"}>
                                                    <Avatar sx={{ width: "30px", height: "30px", bgcolor: "primary.main" }}>
                                                        <Place fontSize='small' />
                                                    </Avatar>
                                                    {+field.value === address.id ? <RadioButtonChecked color="primary" /> : <RadioButtonUnchecked />}
                                                </Stack>
                                                <Stack>
                                                    <AddressData
                                                        address={address.address}
                                                        mobile={address.mobile}
                                                        phone={address.phone}
                                                        name={address.name}
                                                    />
                                                </Stack>
                                            </Stack>
                                        }
                                    />
                                </Grid>
                                )}
                            </Grid>
                        </RadioGroup>
                    )}
                />
            </FormControl>
            {addressError && <Alert color='error' icon={<Error />}>{addressError}</Alert>}
            {addresses.length === 0 &&
                <Grid size={{ xs: 12 }} >
                    <Stack component={Paper} minHeight={80} justifyContent={"center"} alignItems={"center"}>
                        <Typography>{t("noAddresses")}</Typography>
                    </Stack>
                </Grid>
            }
        </Stack>
    )
}

export default DeliveryStack