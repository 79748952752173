import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type List_OrdersQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListOrdersFilterInput>;
  first: Types.Scalars['Int']['input'];
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type List_OrdersQuery = { __typename?: 'Query', listOrders: { __typename?: 'OrderPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, total: number }, data: Array<{ __typename?: 'Order', id: number, code: string, status: Types.OrderStatusCode, deliveryType: Types.DeliveryTypeCode, paymentType: Types.PaymentTypeCode, deliveryDate?: any | null, paymentStatus: Types.PaymentStatusCode, cancellationReason?: string | null, note?: string | null, date: any, user: { __typename?: 'User', firstName: string, lastName: string }, branch?: { __typename?: 'Branch', id: number, name: string } | null }> } };

export type OrderQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type OrderQuery = { __typename?: 'Query', order: { __typename?: 'Order', id: number, code: string, status: Types.OrderStatusCode, deliveryType: Types.DeliveryTypeCode, paymentType: Types.PaymentTypeCode, updateDate: any, paymentStatus: Types.PaymentStatusCode, deliveryDate?: any | null, cancellationReason?: string | null, note?: string | null, date: any, price: number, subtotal: number, taxFees: number, deliveryFees: number, branch?: { __typename?: 'Branch', id: number, name: string } | null, appointment?: { __typename?: 'Appointment', appointmentDate: any, tableTime: { __typename?: 'TableTime', fromTime: any, toTime: any, table: { __typename?: 'Table', name: string, chairsNumber: number } } } | null, address?: { __typename?: 'Address', name: string, address: string, phone?: string | null, mobile?: string | null } | null, items: Array<{ __typename?: 'OrderItem', id: number, quantity: number, price: number, note?: string | null, product: { __typename?: 'Product', id: number, name: string, price: number, image?: { __typename?: 'Image', path: string } | null }, options: Array<{ __typename?: 'OrderItemOption', id: number, value: boolean, option: { __typename?: 'Option', name: string } } | null>, addons: Array<{ __typename?: 'OrderItemAddon', price: number, addon: { __typename?: 'Addon', name: string, price: number } } | null> }> } };

export type Order_AdminQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type Order_AdminQuery = { __typename?: 'Query', order: { __typename?: 'Order', id: number, code: string, status: Types.OrderStatusCode, deliveryType: Types.DeliveryTypeCode, paymentType: Types.PaymentTypeCode, updateDate: any, paymentStatus: Types.PaymentStatusCode, cancellationReason?: string | null, note?: string | null, date: any, price: number, subtotal: number, taxFees: number, deliveryFees: number, deliveryDate?: any | null, branch?: { __typename?: 'Branch', id: number, name: string } | null, appointment?: { __typename?: 'Appointment', appointmentDate: any, tableTime: { __typename?: 'TableTime', fromTime: any, toTime: any, table: { __typename?: 'Table', name: string, chairsNumber: number } } } | null, address?: { __typename?: 'Address', name: string, address: string, phone?: string | null, mobile?: string | null } | null, items: Array<{ __typename?: 'OrderItem', id: number, quantity: number, price: number, note?: string | null, product: { __typename?: 'Product', id: number, name: string, price: number, image?: { __typename?: 'Image', path: string } | null }, options: Array<{ __typename?: 'OrderItemOption', id: number, value: boolean, option: { __typename?: 'Option', name: string } } | null>, addons: Array<{ __typename?: 'OrderItemAddon', price: number, addon: { __typename?: 'Addon', name: string, price: number } } | null> }>, user: { __typename?: 'User', email: string, firstName: string, lastName: string } } };

export type Calculate_Order_FeesQueryVariables = Types.Exact<{
  input: Types.CalculateOrderFeesInput;
}>;


export type Calculate_Order_FeesQuery = { __typename?: 'Query', calculateOrderFees: { __typename?: 'OrderFees', subtotal: number, taxFees: number, deliveryFees: number, totalFees: number } };


export const List_OrdersDocument = gql`
    query LIST_ORDERS($input: ListOrdersFilterInput, $first: Int!, $page: Int) {
  listOrders(input: $input, first: $first, page: $page) {
    paginatorInfo {
      count
      total
    }
    data {
      id
      code
      status
      deliveryType
      paymentType
      deliveryDate
      user {
        firstName
        lastName
      }
      paymentStatus
      branch {
        id
        name
      }
      cancellationReason
      note
      date
    }
  }
}
    `;

/**
 * __useList_OrdersQuery__
 *
 * To run a query within a React component, call `useList_OrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_OrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_OrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useList_OrdersQuery(baseOptions: Apollo.QueryHookOptions<List_OrdersQuery, List_OrdersQueryVariables> & ({ variables: List_OrdersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_OrdersQuery, List_OrdersQueryVariables>(List_OrdersDocument, options);
      }
export function useList_OrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_OrdersQuery, List_OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_OrdersQuery, List_OrdersQueryVariables>(List_OrdersDocument, options);
        }
export function useList_OrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_OrdersQuery, List_OrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_OrdersQuery, List_OrdersQueryVariables>(List_OrdersDocument, options);
        }
export type List_OrdersQueryHookResult = ReturnType<typeof useList_OrdersQuery>;
export type List_OrdersLazyQueryHookResult = ReturnType<typeof useList_OrdersLazyQuery>;
export type List_OrdersSuspenseQueryHookResult = ReturnType<typeof useList_OrdersSuspenseQuery>;
export type List_OrdersQueryResult = Apollo.QueryResult<List_OrdersQuery, List_OrdersQueryVariables>;
export const OrderDocument = gql`
    query ORDER($id: Int!) {
  order(id: $id) {
    id
    code
    status
    deliveryType
    paymentType
    updateDate
    paymentStatus
    deliveryDate
    branch {
      id
      name
    }
    appointment {
      appointmentDate
      tableTime {
        fromTime
        toTime
        table {
          name
          chairsNumber
        }
      }
    }
    address {
      name
      address
      phone
      mobile
    }
    cancellationReason
    note
    items {
      id
      product {
        id
        image {
          path
        }
        name
        price
      }
      quantity
      price
      note
      options {
        id
        value
        option {
          name
        }
      }
      addons {
        price
        addon {
          name
          price
        }
      }
    }
    date
    price
    subtotal
    taxFees
    deliveryFees
  }
}
    `;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables> & ({ variables: OrderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export function useOrderSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderSuspenseQueryHookResult = ReturnType<typeof useOrderSuspenseQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const Order_AdminDocument = gql`
    query ORDER_ADMIN($id: Int!) {
  order(id: $id) {
    id
    code
    status
    deliveryType
    paymentType
    updateDate
    paymentStatus
    branch {
      id
      name
    }
    appointment {
      appointmentDate
      tableTime {
        fromTime
        toTime
        table {
          name
          chairsNumber
        }
      }
    }
    address {
      name
      address
      phone
      mobile
    }
    cancellationReason
    note
    items {
      id
      product {
        id
        image {
          path
        }
        name
        price
      }
      quantity
      price
      note
      options {
        id
        value
        option {
          name
        }
      }
      addons {
        price
        addon {
          name
          price
        }
      }
    }
    date
    user {
      email
      firstName
      lastName
    }
    price
    subtotal
    taxFees
    deliveryFees
    deliveryDate
  }
}
    `;

/**
 * __useOrder_AdminQuery__
 *
 * To run a query within a React component, call `useOrder_AdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrder_AdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrder_AdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrder_AdminQuery(baseOptions: Apollo.QueryHookOptions<Order_AdminQuery, Order_AdminQueryVariables> & ({ variables: Order_AdminQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Order_AdminQuery, Order_AdminQueryVariables>(Order_AdminDocument, options);
      }
export function useOrder_AdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Order_AdminQuery, Order_AdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Order_AdminQuery, Order_AdminQueryVariables>(Order_AdminDocument, options);
        }
export function useOrder_AdminSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Order_AdminQuery, Order_AdminQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Order_AdminQuery, Order_AdminQueryVariables>(Order_AdminDocument, options);
        }
export type Order_AdminQueryHookResult = ReturnType<typeof useOrder_AdminQuery>;
export type Order_AdminLazyQueryHookResult = ReturnType<typeof useOrder_AdminLazyQuery>;
export type Order_AdminSuspenseQueryHookResult = ReturnType<typeof useOrder_AdminSuspenseQuery>;
export type Order_AdminQueryResult = Apollo.QueryResult<Order_AdminQuery, Order_AdminQueryVariables>;
export const Calculate_Order_FeesDocument = gql`
    query CALCULATE_ORDER_FEES($input: CalculateOrderFeesInput!) {
  calculateOrderFees(input: $input) {
    subtotal
    taxFees
    deliveryFees
    totalFees
  }
}
    `;

/**
 * __useCalculate_Order_FeesQuery__
 *
 * To run a query within a React component, call `useCalculate_Order_FeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculate_Order_FeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculate_Order_FeesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculate_Order_FeesQuery(baseOptions: Apollo.QueryHookOptions<Calculate_Order_FeesQuery, Calculate_Order_FeesQueryVariables> & ({ variables: Calculate_Order_FeesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Calculate_Order_FeesQuery, Calculate_Order_FeesQueryVariables>(Calculate_Order_FeesDocument, options);
      }
export function useCalculate_Order_FeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Calculate_Order_FeesQuery, Calculate_Order_FeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Calculate_Order_FeesQuery, Calculate_Order_FeesQueryVariables>(Calculate_Order_FeesDocument, options);
        }
export function useCalculate_Order_FeesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Calculate_Order_FeesQuery, Calculate_Order_FeesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Calculate_Order_FeesQuery, Calculate_Order_FeesQueryVariables>(Calculate_Order_FeesDocument, options);
        }
export type Calculate_Order_FeesQueryHookResult = ReturnType<typeof useCalculate_Order_FeesQuery>;
export type Calculate_Order_FeesLazyQueryHookResult = ReturnType<typeof useCalculate_Order_FeesLazyQuery>;
export type Calculate_Order_FeesSuspenseQueryHookResult = ReturnType<typeof useCalculate_Order_FeesSuspenseQuery>;
export type Calculate_Order_FeesQueryResult = Apollo.QueryResult<Calculate_Order_FeesQuery, Calculate_Order_FeesQueryVariables>;