import { Grid2 as Grid, Stack } from '@mui/material'
import { CustomTypography } from '../Checkout'
import MuiSelect from '../../../Components/MUI/MuiSelect'
import { useList_Branches_DropdownQuery, useList_Tables_DropdownQuery, useList_Tables_Times_DropdownQuery } from '../../../graphql/queries/branches.generated';
import { useTranslation } from 'react-i18next';
import { DeliveryTypeCode } from '../../../graphql/types';
import MUIDate from '../../../Components/MUI/MuiDate';
import moment from 'moment';

const dateFormat = (date: string) => moment(date).locale("en").format("YYYY-MM-DD");

type ValidationErrors = {
    "input.date"?: string;
    [key: string]: string | undefined;
};

const BranchStack = ({
    control,
    watch,
    setValue,
    setError,
    clearErrors
}: {
    control: any,
    watch: any
    setValue: any
    setError: any
    clearErrors: any
}) => {
    const { t } = useTranslation()
    const { data: kitchensDropdownData } = useList_Branches_DropdownQuery();

    const receiveInBranch = watch("deliveryType") === DeliveryTypeCode.Rib

    const { data: tableDropdownData } = useList_Tables_DropdownQuery({
        fetchPolicy: "no-cache",
        skip: !watch("branchId") || receiveInBranch,
        variables: {
            branchId: watch("branchId")
        }
    });
    const { data: tableTimesDropdownData } = useList_Tables_Times_DropdownQuery({
        fetchPolicy: "no-cache",
        skip: !watch("appointmentDate") || !watch("tableId") || receiveInBranch,
        variables: {
            input: {
                tableId: watch("tableId"),
                date: dateFormat(watch("appointmentDate"))
            }
        },
        onCompleted() {
            clearErrors()
        },
        onError(error) {
            const validation = error.graphQLErrors[0]?.extensions?.validation as ValidationErrors;
            if (validation?.["input.date"]) {
                setError("appointmentDate", { type: "manual", message: validation["input.date"] });
            }
        },
    });

    return (
        <Stack spacing={2}>
            <CustomTypography>
                {t("chooseBranch")}
            </CustomTypography>
            <Grid container spacing={2}>
                <Grid size={{ sm: !receiveInBranch ? 6 : 12, xs: 12 }}>
                    <MuiSelect
                        name='branchId'
                        // disabled={!watch("categoryId")}
                        label={t('branch')}
                        control={control}
                        data={!kitchensDropdownData ?
                            [] : kitchensDropdownData?.listBranchesDropdown.map((item) => ({
                                key: item?.name!,
                                value: item?.id!,
                            }))}
                        rules={{
                            required: t("fieldIsRequired"),
                        }}
                        onChanges={() => {
                            setValue("tableId", null)
                        }}
                    />
                </Grid>
                {!receiveInBranch && <Grid size={{ sm: 6, xs: 12 }}>
                    <MUIDate
                        control={control}
                        name="appointmentDate"
                        label={t("appointmentDate")}
                        rules={{
                            required: t("fieldIsRequired"),
                        }}
                        disablePast
                        onChange={() => {
                            setValue("tableTimeId", null)
                        }}
                    />
                </Grid>}
                {!receiveInBranch && <Grid size={{ sm: 6, xs: 12 }}>
                    <MuiSelect
                        name='tableId'
                        disabled={!watch("branchId")}
                        label={t('table')}
                        control={control}
                        data={!tableDropdownData ?
                            [] : tableDropdownData?.listTablesDropdown.map((item) => ({
                                key: item?.name.replace("Chairs", t("chairs"))!,
                                value: item?.id!,
                            }))}
                        rules={{
                            required: t("fieldIsRequired"),
                        }}

                    />
                </Grid>}
                {!receiveInBranch && <Grid size={{ sm: 6, xs: 12 }}>
                    <MuiSelect
                        name='tableTimeId'
                        disabled={!watch("tableId") || !watch("appointmentDate")}
                        label={t('time')}
                        control={control}
                        data={!tableTimesDropdownData ?
                            [] : tableTimesDropdownData?.listTableTimesDropdown.map((item) => ({
                                key: item?.name!,
                                value: item?.id!,
                            }))}
                        rules={{
                            required: t("fieldIsRequired"),
                        }}
                    />
                </Grid>}
            </Grid>
        </Stack>
    )
}

export default BranchStack