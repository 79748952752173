import { useEffect } from 'react';
import { useAdjust_CartMutation } from '../../graphql/mutations/user.generated';
import { useCartLazyQuery } from '../../graphql/queries/user.generated';

const useCartData = () => {
    const [adjustCartMutation, { loading: adjustLoading }] = useAdjust_CartMutation();

    const [cart, { data, loading, refetch }] = useCartLazyQuery({
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        let isMounted = true; // Prevent state updates if component unmounts
        adjustCartMutation()
            .then(() => {
                if (isMounted) {
                    cart();
                }
            })
            .catch(({ graphQLErrors }) => console.log(graphQLErrors));

        return () => {
            isMounted = false; // Cleanup function
        };
    }, [adjustCartMutation, cart]);

    const updateQueries = () => {
        refetch()
    }

    return {
        data,
        loading: loading || adjustLoading,
        updateQueries,
    };
};

export default useCartData;
