import { useAppDispatch } from '../../../Store/store';
import { useNavigate } from 'react-router-dom';
import { changeAuthData } from '../../../Store/slices/auth';
import { changeCartCount } from '../../../Store/slices/cart';
import { ROLE } from '../../../types';

export interface DataTypes {
    token: string,
    user: {
        isAdmin: boolean
        isSuper: boolean
        birthdate: string
        email: string
        firstName: string
        lastName: string
        mobile?: string
        image?: string
        addresses?: {
            id: number
            name: string
            address: string
            default: boolean
            phone: string
            mobile: string
            date: string
        }
        cartCount: number
    }
}

const useAfterLogin = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const callFunction = (data: DataTypes) => {
        localStorage.setItem("token", data.token!)
        const isAdmin = data.user.isAdmin
        const isSuper = data.user.isSuper

        dispatch(changeAuthData({
            isLoggedIn: true,
            role: isSuper ? ROLE.SUPER : (isAdmin ? ROLE.ADMIN : ROLE.CUSTOMER),
            ...(!isAdmin && {
                user: {
                    birthdate: data.user.birthdate,
                    email: data.user.email!,
                    firstName: data.user.firstName!,
                    lastName: data.user.lastName!,
                    mobile: data.user.mobile,
                    image: data.user.image
                },
                addresses: data.user.addresses as any
            }),

        }))

        !isAdmin && dispatch(changeCartCount(data.user.cartCount))

        if (isAdmin) {
            navigate("/dashboard");
        } else {
            navigate("/profile");
        }
    }
    return { callFunction }
}

export default useAfterLogin