
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Stack } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { Link, Outlet } from 'react-router-dom';
import NavDrawer, { drawerWidth } from '../Dashboard/Component/NavDrawer';
import useWidth, { isWidthDown } from '../../../Components/helperFunctions/useWidth';
import LanguageMenu from '../../../Components/NavbarComponent/Language';
import logo from '../../../assets/images/logo.png'
import LogoutDialog from '../../../Components/LogoutDialog';
import ChangePassword from '../../../Components/dialogs/ChangePassword';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{ open?: boolean; }>(({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    height: "100dvh",
    // minHeight: "100%",
    overflow: "hidden",
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
    background: theme.palette.background.paper,
    zIndex: 1201,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),

}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function DashboardLayout() {
    const [open, setOpen] = React.useState<boolean>(localStorage.getItem("drawer") === "false" ? false : true);
    const [logoutDialog, setLogoutDialog] = React.useState(false)

    const handleDrawerOpen = () => {

        setOpen(prev => {
            localStorage.setItem("drawer", `${!prev}`)
            return !prev
        });
    };

    const screenWidth = useWidth();
    const isScreenSmall = isWidthDown("xs", screenWidth);
    const handleCloseLogoutDialog = () => {
        setLogoutDialog(false)
    }
    return (
        <Box sx={{ display: 'flex', minHeight: "100dvh", overflow: "hidden" }}>
            {logoutDialog && <LogoutDialog open={logoutDialog} handleClose={handleCloseLogoutDialog} />}
            <AppBar position='fixed' open={open}>
                <Toolbar>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                        <Stack component={Link} to={"/"} mx={2}>
                            <img src={logo} alt='logo' height={60} srcSet={logo} />
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Stack p={1}>
                                <ChangePassword />
                            </Stack>
                            <Stack alignItems={"center"}>
                                <LanguageMenu />
                            </Stack>
                            <Stack alignItems={"center"}>
                                <IconButton
                                    aria-label="Profile"
                                    size='medium'
                                    color='primary'
                                    onClick={() => setLogoutDialog(true)}
                                >
                                    <Logout fontSize='inherit' />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
            <NavDrawer
                open={open}
                DrawerHeader={DrawerHeader}
            />
            <Main open={isScreenSmall ? false : open}>
                <Stack height={"100dvh"}>
                    <DrawerHeader />
                    <Stack flexGrow={1} overflow={"auto"} p={2}>
                        <Outlet />
                    </Stack>
                </Stack>
            </Main>
        </Box>
    );
}