import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import { styled } from "@mui/material/styles";

import { useTranslation } from 'react-i18next';
import { useList_OrdersQuery } from '../../graphql/queries/orders.generated';
import ListPaper from '../admin/Layouts/ListPaper';
import TableBodyWithLoad from '../admin/Layouts/TableBodyWithLoad';
import { CellLink } from '../admin/Layouts/CellLink';
import { FixedTableCell } from '../admin/Layouts/FixedTableCell';
import MUITablePagination from '../admin/Layouts/TablePagination';
import { DeliveryTypeNames, paymentTypeNames } from '../../types';
import { dateFormatLL } from '../../Components/helperFunctions/dateFunctions';
import { TableCellColor } from '../../Components/CellColor';


const PREFIX = "orders";
const classes = {
  filter: `${PREFIX}-filter`,
  filterActive: `${PREFIX}-filterActive`,
  title: `${PREFIX}-title`,
};
const Root = styled(Stack)(({ theme }) => ({
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0, 2),
  },
  [`& .${classes.filter}`]: {
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    [`&:hover`]: {
      background: theme.palette.divider
    },
  },
  [`& .${classes.filterActive}`]: {
    background: theme.palette.divider
  },
  [`& .${classes.title}`]: {
    fontSize: "22px"
  },
}));

export default function Orders() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const { t, i18n } = useTranslation();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data, loading } = useList_OrdersQuery({
    fetchPolicy: "no-cache",
    variables: {
      first: rowsPerPage,
      page: page + 1
    },
  });

  const tableCellHeader = [
    "code", "status", "deliveryDate", "user", "deliveryType", "branch", "paymentType", "paymentStatus", "createdAt",
  ]

  return (
    <Root mt={2}>
      <ListPaper loading={loading} data={!!(data && data.listOrders.data.length)}  >
        <TableContainer sx={{ width: "100%", overflow: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableCellHeader.map(e =>
                  <TableCell align={'left'} key={e}>
                    {t(e)}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBodyWithLoad loadingLength={6} loading={loading} tableCellHeaderLength={tableCellHeader.length}>
              <TableBody>
                {data?.listOrders.data.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <CellLink to={`${row.id}`}>
                        {row.code}
                      </CellLink>
                      <TableCellColor
                        cell={{
                          code: row.status,
                          label: t(row.status.toLowerCase())
                        }}
                      />

                      <FixedTableCell align={'left'}>
                        {row.deliveryDate}
                      </FixedTableCell>
                      <FixedTableCell>
                        {row.user.firstName}
                      </FixedTableCell>
                      <TableCellColor
                        cell={{
                          code: row.deliveryType,
                          label: t(DeliveryTypeNames[row.deliveryType as "TRS" | "DLV" | "RIB"])
                        }}
                      />
                      <FixedTableCell align={'left'}>
                        {row.branch?.name}
                      </FixedTableCell>
                      <TableCellColor
                        cell={{
                          code: row.paymentType,
                          label: t(paymentTypeNames[row.paymentType as "COD" | "STRIPE"])
                        }}
                      />
                      <TableCellColor
                        cell={{
                          code: row.paymentStatus,
                          label: t(row.paymentStatus.toLowerCase())
                        }}
                      />
                      <FixedTableCell align={'left'}>
                        {dateFormatLL(row.date, i18n.language)}
                      </FixedTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </TableBodyWithLoad>
          </Table>
        </TableContainer>
        <MUITablePagination
          count={data?.listOrders.paginatorInfo.total}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ListPaper>
    </Root>
  );
}
