import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import * as colors from "@mui/material/colors";
import { FixedTableCell } from "../Pages/admin/Layouts/FixedTableCell";

// Define props for StyledFixedTableCell to include colorcode
interface StyledFixedTableCellProps {
    colorCode: string;
}

// Styled component with proper TypeScript typings
const StyledFixedTableCell = styled(Chip, {
    shouldForwardProp: (prop) => prop !== "colorCode",
})<StyledFixedTableCellProps>(({ theme, colorCode }) => ({
    color: theme.palette.getContrastText(colorCode),
    backgroundColor: `${colorCode} !important`,
}));

// Define the shape of the `shipment` prop
interface Cell {
    label: string;
    code: string
}

// Define props for TableCellColor
interface TableCellColorProps {
    cell: Cell;
    table?: boolean
}

export const TableCellColor: React.FC<TableCellColorProps> = memo(({ cell, table = true }) => {
    const color: Record<string, string> = {
        DLV: colors["cyan"]["500"],
        RIB: colors["brown"]["500"],
        TRS: colors["orange"]["500"],
        COD: colors["blue"]["500"],
        STRIPE: colors["deepPurple"]["500"],
        APPROVED: colors["grey"]["500"],
        CANCELLED: colors["red"]["500"],
        COMPLETED: colors["green"]["500"],
        DELIVERING: colors["cyan"]["500"],
        PENDING: colors["blueGrey"]["500"],
        PREPARED: colors["blue"]["500"],
        PREPARING: colors["orange"]["500"],
        REFUNDING: colors["orange"]["500"],
        REFUNDED: colors["red"]["500"],
        PAID: colors["green"]["500"],
    };

    // Determine color code based on shipment status or default to brown
    const cellCode = cell?.code;
    const colorCode = cellCode && color.hasOwnProperty(cellCode) ? color[cellCode] : colors["brown"]["500"];

    return table ? (
        <FixedTableCell>
            <StyledFixedTableCell
                colorCode={colorCode}
                size="small"
                label={cell.label}
            />
        </FixedTableCell>
    ) : (
        <StyledFixedTableCell
            colorCode={colorCode}
            size="small"
            label={cell.label}
        />
    );
});
