import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack, Typography, styled } from '@mui/material';

import { useTranslation } from 'react-i18next';
import ListPaper from '../Layouts/ListPaper';
import { FixedTableCell } from '../Layouts/FixedTableCell';
import MUITablePagination from '../Layouts/TablePagination';
import TableBodyWithLoad from '../Layouts/TableBodyWithLoad';
// import CategoriesCreate from './CategoriesCreate';
import { useListAppointmentsQuery } from '../../../graphql/queries/branches.generated';
import { CellLink } from '../Layouts/CellLink';
import { TableCellColor } from '../../../Components/CellColor';

const PREFIX = "appointments";
const classes = {
    filter: `${PREFIX}-filter`,
    filterActive: `${PREFIX}-filterActive`,
    title: `${PREFIX}-title`,
};
const Root = styled("div")(({ theme }) => ({
    height: "100%",
    [`& .${classes.filter}`]: {
        padding: theme.spacing(0.5, 1.5),
        borderRadius: theme.spacing(1),
        cursor: "pointer",
        [`&:hover`]: {
            background: theme.palette.divider
        },
    },
    [`& .${classes.filterActive}`]: {
        background: theme.palette.divider
    },
    [`& .${classes.title}`]: {
        fontSize: "22px"
    },
}));

export interface DataByIdType {
    id: number
    name: string
    fileName: string | undefined
    active: boolean
    parentId: number
}

export default function AppointmentsList() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const { t } = useTranslation();

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const { data, loading } = useListAppointmentsQuery({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
            first: rowsPerPage,
            page: page + 1
        },
    });

    const tableCellHeader = [
        "date", "status", "dateStartAndEnd", "order", "branch", "table", "reservationUnder"
    ]

    return (
        <Root>
            {/* {createDialog && <CategoriesCreate open={createDialog} handleClose={handleCloseCreateDialog} dataById={dataById} refetch={refetch} />} */}
            <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
                <Typography className={classes.title}>{t(PREFIX)}</Typography>
                {/* <ButtonLink to='create' variant='contained' text={t("addBranch")} /> */}
            </Stack>
            <ListPaper loading={loading} data={!!(data && data.listAppointments.data.length)}>
                <TableContainer sx={{ width: "100%", overflow: "auto" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {tableCellHeader.map(e =>
                                    <TableCell align={'left'} key={e}>
                                        {t(e)}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBodyWithLoad loading={loading} tableCellHeaderLength={tableCellHeader.length}>
                            <TableBody>
                                {data?.listAppointments.data.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            <FixedTableCell>
                                                {row.appointmentDate}
                                            </FixedTableCell>
                                            <TableCellColor
                                                cell={{
                                                    code: row.status,
                                                    label: t(row.status.toLowerCase())
                                                }}
                                            />
                                            <FixedTableCell align={'left'}>
                                                {row.tableTime.fromTime} - {row.tableTime.toTime}
                                            </FixedTableCell>
                                            <CellLink to={`/dashboard/orders/${row.order.id}`}>
                                                {row.order.code}
                                            </CellLink>
                                            <FixedTableCell align={'left'}>
                                                {row.tableTime.table.branch.name}
                                            </FixedTableCell>
                                            <FixedTableCell align={'left'}>
                                                {row.tableTime.table.name}
                                            </FixedTableCell>
                                            <FixedTableCell align={'left'}>
                                                {row.user.firstName}
                                            </FixedTableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </TableBodyWithLoad>
                    </Table>
                </TableContainer>
                <MUITablePagination
                    count={data?.listAppointments.paginatorInfo.total}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </ListPaper>
        </Root>
    );
}