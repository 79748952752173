import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegisterMutationVariables = Types.Exact<{
  input: Types.RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: boolean };

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type LoginMutationVariables = Types.Exact<{
  input: Types.LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'UserWithToken', token: string, user: { __typename?: 'User', id: number, firstName: string, lastName: string, mobile?: string | null, isAdmin: boolean, isSuper: boolean, birthdate?: any | null, email: string, image?: { __typename?: 'Image', path: string } | null, addresses: Array<{ __typename?: 'Address', id: number, name: string, address: string, default: boolean, phone?: string | null, mobile?: string | null, date: any } | null>, cart?: { __typename?: 'Cart', id: number, price: number, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, price: number, product: { __typename?: 'Product', name: string, price: number, weight?: number | null, id: number, image?: { __typename?: 'Image', path: string } | null }, options: Array<{ __typename?: 'CartItemOption', id: number, value: boolean } | null>, addons: Array<{ __typename?: 'CartItemAddon', id: number, addon: { __typename?: 'Addon', price: number } } | null> } | null> } | null } } };

export type Verify_User_EmailMutationVariables = Types.Exact<{
  input: Types.VerifyUserEmailInput;
}>;


export type Verify_User_EmailMutation = { __typename?: 'Mutation', verifyUserEmail: boolean };

export type Resend_Verification_CodeMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type Resend_Verification_CodeMutation = { __typename?: 'Mutation', resendVerificationCode: boolean };


export const RegisterDocument = gql`
    mutation REGISTER($input: RegisterInput!) {
  register(input: $input)
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const LogoutDocument = gql`
    mutation LOGOUT {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const LoginDocument = gql`
    mutation LOGIN($input: LoginInput!) {
  login(input: $input) {
    user {
      id
      firstName
      lastName
      mobile
      image {
        path
      }
      isAdmin
      isSuper
      birthdate
      email
      addresses {
        id
        name
        address
        default
        phone
        mobile
        date
      }
      cart {
        id
        price
        items {
          id
          product {
            name
            price
            weight
            id
            image {
              path
            }
          }
          quantity
          price
          options {
            id
            value
          }
          addons {
            id
            addon {
              price
            }
          }
        }
      }
    }
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const Verify_User_EmailDocument = gql`
    mutation VERIFY_USER_EMAIL($input: VerifyUserEmailInput!) {
  verifyUserEmail(input: $input)
}
    `;
export type Verify_User_EmailMutationFn = Apollo.MutationFunction<Verify_User_EmailMutation, Verify_User_EmailMutationVariables>;

/**
 * __useVerify_User_EmailMutation__
 *
 * To run a mutation, you first call `useVerify_User_EmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerify_User_EmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserEmailMutation, { data, loading, error }] = useVerify_User_EmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerify_User_EmailMutation(baseOptions?: Apollo.MutationHookOptions<Verify_User_EmailMutation, Verify_User_EmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Verify_User_EmailMutation, Verify_User_EmailMutationVariables>(Verify_User_EmailDocument, options);
      }
export type Verify_User_EmailMutationHookResult = ReturnType<typeof useVerify_User_EmailMutation>;
export type Verify_User_EmailMutationResult = Apollo.MutationResult<Verify_User_EmailMutation>;
export type Verify_User_EmailMutationOptions = Apollo.BaseMutationOptions<Verify_User_EmailMutation, Verify_User_EmailMutationVariables>;
export const Resend_Verification_CodeDocument = gql`
    mutation RESEND_VERIFICATION_CODE($email: String!) {
  resendVerificationCode(email: $email)
}
    `;
export type Resend_Verification_CodeMutationFn = Apollo.MutationFunction<Resend_Verification_CodeMutation, Resend_Verification_CodeMutationVariables>;

/**
 * __useResend_Verification_CodeMutation__
 *
 * To run a mutation, you first call `useResend_Verification_CodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResend_Verification_CodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationCodeMutation, { data, loading, error }] = useResend_Verification_CodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResend_Verification_CodeMutation(baseOptions?: Apollo.MutationHookOptions<Resend_Verification_CodeMutation, Resend_Verification_CodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Resend_Verification_CodeMutation, Resend_Verification_CodeMutationVariables>(Resend_Verification_CodeDocument, options);
      }
export type Resend_Verification_CodeMutationHookResult = ReturnType<typeof useResend_Verification_CodeMutation>;
export type Resend_Verification_CodeMutationResult = Apollo.MutationResult<Resend_Verification_CodeMutation>;
export type Resend_Verification_CodeMutationOptions = Apollo.BaseMutationOptions<Resend_Verification_CodeMutation, Resend_Verification_CodeMutationVariables>;