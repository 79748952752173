import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Stack, Typography, styled } from '@mui/material';

import { useTranslation } from 'react-i18next';
import ListPaper from '../Layouts/ListPaper';
import { CellLink } from '../Layouts/CellLink';
import { FixedTableCell } from '../Layouts/FixedTableCell';
import MUITablePagination from '../Layouts/TablePagination';
import TableBodyWithLoad from '../Layouts/TableBodyWithLoad';
import { useList_OrdersQuery } from '../../../graphql/queries/orders.generated';
import { DeliveryTypeNames, paymentTypeNames } from '../../../types';
import { dateFormat, dateFormatLL } from '../../../Components/helperFunctions/dateFunctions';
import ControlMUITextField from '../../../Components/MUI/ControlMUItextField';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import MuiSelect from '../../../Components/MUI/MuiSelect';
import { DeliveryTypeCode, OrderStatusCode } from '../../../graphql/types';
import { TableCellColor } from '../../../Components/CellColor';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MUIDate from '../../../Components/MUI/MuiDate';

const PREFIX = "orders";
const classes = {
  filter: `${PREFIX}-filter`,
  filterActive: `${PREFIX}-filterActive`,
  title: `${PREFIX}-title`,
  grid: `${PREFIX}-grid`,
};
const Root = styled(Stack)(({ theme }) => ({
  height: "100%",
  [`& .${classes.filter}`]: {
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    [`&:hover`]: {
      background: theme.palette.divider
    },
  },
  [`& .${classes.filterActive}`]: {
    background: theme.palette.divider
  },
  [`& .${classes.title}`]: {
    fontSize: "22px"
  },
  [`& .${classes.grid}`]: {
    display: "grid",
    gridAutoFlow: "column", /* Forces items to be laid out in a single row */
    gridGap: "10px", /* Optional: Adds spacing between items */
    overflowX: "scroll", /* Allows horizontal scrolling if items overflow */
    whiteSpace: "nowrap",
    width: "100%",
    MsOverflowStyle: "none", /* Internet Explorer 10+ */
    "&::-webkit-scrollbar": {
      display: "none"
    },
    justifyContent: "start",
    alignItems: "center"
  },
}));

type searchType = {
  code?: string
  status?: string
  date?: string
}

export default function OrdersList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const { t, i18n } = useTranslation();

  const { control, handleSubmit, setValue } = useForm<searchType>()

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const paramsObject = Object.fromEntries(searchParams.entries());


  React.useEffect(() => {
    if (paramsObject.status) {
      setValue("status", paramsObject.status as string);
    }
    if (paramsObject.code) {
      setValue("code", paramsObject.code as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsObject]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data, loading } = useList_OrdersQuery({
    fetchPolicy: "no-cache",
    variables: {
      input: {
        ...paramsObject,
        deliveryType: DeliveryTypeCode.Rib
      },
      first: rowsPerPage,
      page: page + 1
    },
  });

  const tableCellHeader = [
    "code", "status", "deliveryDate", "user", "deliveryType", "branch", "paymentType", "paymentStatus", "createdAt",
  ]

  const orderStatusArray = Object.entries(OrderStatusCode).map(([key, value]) => ({
    key,
    value,
  }));


  const onSubmit = (data: searchType) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null && value !== "")
    ) as searchType;

    if (Object.entries(filteredData).length === 0) {
      // Handle case with no filters
      navigate(`/dashboard/receive-in-branch`)
    } else {
      if (filteredData.date) {
        filteredData.date = dateFormat(filteredData.date);
      }
      // Convert the filtered data to URLSearchParams
      const searchParams = new URLSearchParams(filteredData).toString();
      // Navigate to the new URL with search parameters
      navigate(`?${searchParams}`);
    }
  };

  return (
    <Root spacing={1}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography className={classes.title}>{t(PREFIX)}</Typography>
        {/* <ButtonLink to={`create`} text={t("addProduct")} /> */}
      </Stack>
      <Stack direction={"row"} spacing={1} component={"form"} onSubmit={handleSubmit(onSubmit)} minHeight={50}>
        <Stack width={"100px"} justifyContent={"center"}>
          <LoadingButton loading={loading} variant='contained' type='submit'>{t("search")}</LoadingButton>
        </Stack>
        <Box className={classes.grid}>
          <Stack width={"180px"}>
            <MUIDate
              label={t("date")}
              control={control}
              name="date"
            />
          </Stack>
          <Stack width={"120px"}>
            <ControlMUITextField
              label={t("code")}
              control={control}
              name="code"
            />
          </Stack>
          <Stack width={"120px"}>
            <MuiSelect
              name='status'
              label={t('status')}
              control={control}
              data={orderStatusArray.map(e => ({ ...e, key: t(e.key.toLowerCase()) }))}
            />
          </Stack>
        </Box>
      </Stack>
      <ListPaper loading={loading} data={!!(data && data.listOrders.data.length)} filters>
        <TableContainer sx={{ width: "100%", overflow: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableCellHeader.map(e =>
                  <TableCell align={'left'} key={e}>
                    {t(e)}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBodyWithLoad loading={loading} tableCellHeaderLength={tableCellHeader.length}>
              <TableBody>
                {data?.listOrders.data.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <CellLink to={`/dashboard/orders/${row.id}`}>
                        {row.code}
                      </CellLink>
                      <TableCellColor
                        cell={{
                          code: row.status,
                          label: t(row.status.toLowerCase())
                        }}
                      />

                      <FixedTableCell align={'left'}>
                        {row.deliveryDate}
                      </FixedTableCell>
                      <FixedTableCell>
                        {row.user.firstName}
                      </FixedTableCell>
                      <TableCellColor
                        cell={{
                          code: row.deliveryType,
                          label: t(DeliveryTypeNames[row.deliveryType as "TRS" | "DLV" | "RIB"])
                        }}
                      />
                      <FixedTableCell align={'left'}>
                        {row.branch?.name}
                      </FixedTableCell>
                      <TableCellColor
                        cell={{
                          code: row.paymentType,
                          label: t(paymentTypeNames[row.paymentType as "COD" | "STRIPE"])
                        }}
                      />
                      <TableCellColor
                        cell={{
                          code: row.paymentStatus,
                          label: t(row.paymentStatus.toLowerCase())
                        }}
                      />
                      <FixedTableCell align={'left'}>
                        {dateFormatLL(row.date, i18n.language)}
                      </FixedTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </TableBodyWithLoad>
          </Table>
        </TableContainer>
        <MUITablePagination
          count={data?.listOrders.paginatorInfo.total}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ListPaper>
    </Root>
  );
}
