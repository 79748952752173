import { Switch } from '@mui/material';
import React from 'react'
import { useToggle_Activate_UserMutation } from '../../../../graphql/mutations/user.generated';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';

const ActivationUser = ({ id, value }: { id: number, value: boolean }) => {
    const [checked, setChecked] = React.useState(value);
    const { t } = useTranslation()
    const [toggleActivateUserMutation] = useToggle_Activate_UserMutation();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        toggleActivateUserMutation({
            variables: {
                id
            }
        }).then(() => toast.success(t("saveSuccessful"))).catch(({ graphQLErrors }) => toast.success(graphQLErrors[0].message))
        setChecked(event.target.checked);
    };

    return (
        <>
            <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </>
    )
}

export default ActivationUser