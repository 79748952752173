import React from 'react'
import { styled } from "@mui/material/styles";
import { Grid2 as Grid, Paper, Stack, Typography } from '@mui/material';

import mobileScreen from "../assets/images/mobileScreen.png"
import { useTranslation } from 'react-i18next';
import { FaApple, FaGooglePlay } from 'react-icons/fa';

const PREFIX = "products-created";
const classes = {
    BGImage: `${PREFIX}-BGImage`,
    mobileScreen: `${PREFIX}-mobileScreen`,
};

const Root = styled(Stack)(({ theme }) => ({
    backgroundImage: `radial-gradient(${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    minHeight: "500px",
    width: "100%",
    overflow: "hidden",
    position: "relative",
    [`& .${classes.BGImage}`]: {
        background: "#fadebc",
        position: "absolute",
        height: "2900px",
        borderRadius: "50%",
        top: "-2500px",
        width: "2900px",
        left: "-546px",
        zIndex: 1,
    },
    [`& .${classes.mobileScreen}`]: {
        [theme.breakpoints.down("md")]: {
            width: "auto",
            height: "300px"
        },
        [theme.breakpoints.down("sm")]: {
            height: "250px"
        },
    }
}));

const MobileAppDownload = () => {
    const { t } = useTranslation()
    return (
        <Root justifyContent={"end"} alignItems={"center"}>
            <div className={classes.BGImage} />
            <Grid
                container
                zIndex={10}
                position={"relative"}
                bottom={-10}
                spacing={2}
                flexDirection={{ xs: "column-reverse", md: "row" }}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Grid size={{ md: 6, xs: 12 }}>
                    <Stack alignItems={"center"} width={"100%"} height={"100%"}>
                        <img src={mobileScreen} alt='mobileScreen' width={"100%"} height={400} className={classes.mobileScreen} />
                    </Stack>
                </Grid>
                <Grid size={{ md: 6, xs: 12 }}>
                    <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
                        <Typography fontSize={30} color='primary' fontWeight={"bolder"}>
                            {t("installTheApp")}
                        </Typography>
                        <Typography textAlign={"center"} width={"70%"}>
                            {t("mobileScreenDesc")}
                        </Typography>
                        <Stack direction={"row"} spacing={2}>
                            <Stack component={Paper} direction={"row"} spacing={1} py={0.5} px={1} alignItems={"center"}>
                                <FaGooglePlay size={30} />
                                <Stack>
                                    <Typography fontSize={10}>
                                        {t("getItOn")}
                                    </Typography>
                                    <Typography fontSize={18}>
                                        Google Play
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack component={Paper} direction={"row"} spacing={1} py={0.5} px={1.5} alignItems={"center"}>
                                <FaApple size={30} />
                                <Stack>
                                    <Typography fontSize={9}>
                                        {t("downloadOnThe")}
                                    </Typography>
                                    <Typography fontSize={18}>
                                        App Store
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>

                </Grid>
            </Grid>
        </Root>
    )
}

export default MobileAppDownload