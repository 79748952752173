import { Button, Container, Grid2 as Grid, Paper, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import LabelValue from "../../Components/LabelValue";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../Store/store";
import { Edit } from "@mui/icons-material";
import { useState } from "react";
import UpdateProfile from "./Components/UpdateProfile";
import ChangePassword from "../../Components/dialogs/ChangePassword";

const PREFIX = "profile-info";

const classes = {
  background: `${PREFIX}-background`,
  imageWrapper: `${PREFIX}-imageWrapper`,
  image: `${PREFIX}-image`,
  tabs: `${PREFIX}-tabs`,
};

const Root = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2),
  [`& .${classes.background}`]: {
    background: theme.palette.primary.main,
    height: "120px"
  },
}));

const Profile = () => {
  const { t } = useTranslation()
  const { user } = useAppSelector((state) => state.auth);
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Root>
      {open && <UpdateProfile open={open} handleClose={handleClose} />}
      <Container maxWidth={"md"}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"flex-end"}>
            <Stack direction={"row"} spacing={1}>
              <Button
                size="small"
                variant="outlined"
                onClick={handleOpen}
                endIcon={<Edit fontSize="small" />}
              >
                {t("update")}
              </Button>
              <ChangePassword />
            </Stack>
          </Grid>
          <Grid size={{ sm: 8, xs: 12 }}>
            <LabelValue label={t("name")} value={user?.firstName! + " " + user?.lastName} icon="person" />
          </Grid>
          <Grid size={{ sm: 4, xs: 12 }}>
            <LabelValue label={t("mobile")} value={user?.mobile!} icon="phone" />
          </Grid>
          <Grid size={{ sm: 8, xs: 12 }}>
            <LabelValue label={t("email")} value={user?.email!} icon="email" />
          </Grid>
          <Grid size={{ sm: 4, xs: 12 }}>
            <LabelValue label={t("birthdate")} value={user?.birthdate!} icon="today" />
          </Grid>
        </Grid>
      </Container>
    </Root>
  )
}

export default Profile

