import React from "react";
import {
    FormHelperText,
} from "@mui/material";
import { useController, Control } from "react-hook-form";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

interface MUIDateProps {
    control: Control<any>;
    disabled?: boolean;
    name: string;
    label?: string;
    rules?: Record<string, any>;
    defaultValue?: Date | null;
    variant?: "filled" | "outlined" | "standard";
    onChange?: (value: Date | null) => void;
    value?: Date | null;
    InputProps?: Record<string, any>;
    disablePast?: boolean;
    [key: string]: any;
}

const MUIDateTime: React.FC<MUIDateProps> = ({
    control,
    disabled,
    name,
    label,
    rules,
    defaultValue,
    variant,
    onChange,
    value,
    InputProps,
    disablePast,
    ...restProps
}) => {
    const {
        formState: { errors },
        field: { value: fieldValue, onChange: fieldChange, },
    } = useController({
        name,
        control,
        rules: { ...rules },
        defaultValue: defaultValue ?? null,
    });


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker
                    {...restProps}
                    label={label}
                    value={value || fieldValue}
                    onChange={(newValue) => {
                        fieldChange(newValue);
                        onChange && onChange(newValue);
                    }}
                    disabled={disabled}
                    timeSteps={{ hours: 1, minutes: 1 }}
                    ampm={false} // Disable AM/PM format for 24-hour mode
                    minutesStep={1} // Allow minute selection in 1-minute intervals
                    disablePast={disablePast ?? false}

                />
            </DemoContainer>
            {errors[name] && (
                <FormHelperText error>
                    {errors[name]?.message as string}
                </FormHelperText>
            )}
        </LocalizationProvider>
    );
};

export default MUIDateTime;
