import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '../Store/store';

export default function ProtectedRoute({ checkRole, children, homeLayout = false }) {
  const { isLoggedIn, role } = useAppSelector((state) => state.auth);

  const location = useLocation()
  if (!isLoggedIn) {
    if (location.pathname === "/login" || location.pathname === "/register") {
      return children
    }
    return <Navigate to="/login" />;
  }

  if (isLoggedIn && Array.isArray(checkRole) ? !checkRole.includes(role) : role !== checkRole) {
    return <Navigate to="/" />;
  }

  return homeLayout ? children : <Outlet />
}