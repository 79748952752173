import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Save_CategoryMutationVariables = Types.Exact<{
  input: Types.CategoryInput;
}>;


export type Save_CategoryMutation = { __typename?: 'Mutation', saveCategory: { __typename?: 'Category', id: number, name: string } };

export type Save_ProductMutationVariables = Types.Exact<{
  input: Types.ProductInput;
}>;


export type Save_ProductMutation = { __typename?: 'Mutation', saveProduct: { __typename?: 'Product', id: number } };

export type Delete_OptionMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type Delete_OptionMutation = { __typename?: 'Mutation', deleteOption: boolean };

export type Delete_AddonMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type Delete_AddonMutation = { __typename?: 'Mutation', deleteAddon: boolean };

export type Delete_ProductMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type Delete_ProductMutation = { __typename?: 'Mutation', deleteProduct: boolean };


export const Save_CategoryDocument = gql`
    mutation SAVE_CATEGORY($input: CategoryInput!) {
  saveCategory(input: $input) {
    id
    name
  }
}
    `;
export type Save_CategoryMutationFn = Apollo.MutationFunction<Save_CategoryMutation, Save_CategoryMutationVariables>;

/**
 * __useSave_CategoryMutation__
 *
 * To run a mutation, you first call `useSave_CategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSave_CategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCategoryMutation, { data, loading, error }] = useSave_CategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSave_CategoryMutation(baseOptions?: Apollo.MutationHookOptions<Save_CategoryMutation, Save_CategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Save_CategoryMutation, Save_CategoryMutationVariables>(Save_CategoryDocument, options);
      }
export type Save_CategoryMutationHookResult = ReturnType<typeof useSave_CategoryMutation>;
export type Save_CategoryMutationResult = Apollo.MutationResult<Save_CategoryMutation>;
export type Save_CategoryMutationOptions = Apollo.BaseMutationOptions<Save_CategoryMutation, Save_CategoryMutationVariables>;
export const Save_ProductDocument = gql`
    mutation SAVE_PRODUCT($input: ProductInput!) {
  saveProduct(input: $input) {
    id
  }
}
    `;
export type Save_ProductMutationFn = Apollo.MutationFunction<Save_ProductMutation, Save_ProductMutationVariables>;

/**
 * __useSave_ProductMutation__
 *
 * To run a mutation, you first call `useSave_ProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSave_ProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProductMutation, { data, loading, error }] = useSave_ProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSave_ProductMutation(baseOptions?: Apollo.MutationHookOptions<Save_ProductMutation, Save_ProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Save_ProductMutation, Save_ProductMutationVariables>(Save_ProductDocument, options);
      }
export type Save_ProductMutationHookResult = ReturnType<typeof useSave_ProductMutation>;
export type Save_ProductMutationResult = Apollo.MutationResult<Save_ProductMutation>;
export type Save_ProductMutationOptions = Apollo.BaseMutationOptions<Save_ProductMutation, Save_ProductMutationVariables>;
export const Delete_OptionDocument = gql`
    mutation DELETE_OPTION($id: Int!) {
  deleteOption(id: $id)
}
    `;
export type Delete_OptionMutationFn = Apollo.MutationFunction<Delete_OptionMutation, Delete_OptionMutationVariables>;

/**
 * __useDelete_OptionMutation__
 *
 * To run a mutation, you first call `useDelete_OptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_OptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionMutation, { data, loading, error }] = useDelete_OptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_OptionMutation(baseOptions?: Apollo.MutationHookOptions<Delete_OptionMutation, Delete_OptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_OptionMutation, Delete_OptionMutationVariables>(Delete_OptionDocument, options);
      }
export type Delete_OptionMutationHookResult = ReturnType<typeof useDelete_OptionMutation>;
export type Delete_OptionMutationResult = Apollo.MutationResult<Delete_OptionMutation>;
export type Delete_OptionMutationOptions = Apollo.BaseMutationOptions<Delete_OptionMutation, Delete_OptionMutationVariables>;
export const Delete_AddonDocument = gql`
    mutation DELETE_ADDON($id: Int!) {
  deleteAddon(id: $id)
}
    `;
export type Delete_AddonMutationFn = Apollo.MutationFunction<Delete_AddonMutation, Delete_AddonMutationVariables>;

/**
 * __useDelete_AddonMutation__
 *
 * To run a mutation, you first call `useDelete_AddonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_AddonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddonMutation, { data, loading, error }] = useDelete_AddonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_AddonMutation(baseOptions?: Apollo.MutationHookOptions<Delete_AddonMutation, Delete_AddonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_AddonMutation, Delete_AddonMutationVariables>(Delete_AddonDocument, options);
      }
export type Delete_AddonMutationHookResult = ReturnType<typeof useDelete_AddonMutation>;
export type Delete_AddonMutationResult = Apollo.MutationResult<Delete_AddonMutation>;
export type Delete_AddonMutationOptions = Apollo.BaseMutationOptions<Delete_AddonMutation, Delete_AddonMutationVariables>;
export const Delete_ProductDocument = gql`
    mutation DELETE_PRODUCT($id: Int!) {
  deleteProduct(id: $id)
}
    `;
export type Delete_ProductMutationFn = Apollo.MutationFunction<Delete_ProductMutation, Delete_ProductMutationVariables>;

/**
 * __useDelete_ProductMutation__
 *
 * To run a mutation, you first call `useDelete_ProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_ProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDelete_ProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_ProductMutation(baseOptions?: Apollo.MutationHookOptions<Delete_ProductMutation, Delete_ProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_ProductMutation, Delete_ProductMutationVariables>(Delete_ProductDocument, options);
      }
export type Delete_ProductMutationHookResult = ReturnType<typeof useDelete_ProductMutation>;
export type Delete_ProductMutationResult = Apollo.MutationResult<Delete_ProductMutation>;
export type Delete_ProductMutationOptions = Apollo.BaseMutationOptions<Delete_ProductMutation, Delete_ProductMutationVariables>;