import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Stack, Typography, styled } from '@mui/material';

import { useTranslation } from 'react-i18next';
import ListPaper from '../Layouts/ListPaper';
import { FixedTableCell } from '../Layouts/FixedTableCell';
import MUITablePagination from '../Layouts/TablePagination';
import TableBodyWithLoad from '../Layouts/TableBodyWithLoad';
// import CategoriesCreate from './CategoriesCreate';
import { useList_UsersQuery } from '../../../graphql/queries/user.generated';
import ActivationUser from './components/ActivationUser';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ControlMUITextField from '../../../Components/MUI/ControlMUItextField';
import { useForm } from 'react-hook-form';

const PREFIX = "users";
const classes = {
    filter: `${PREFIX}-filter`,
    filterActive: `${PREFIX}-filterActive`,
    title: `${PREFIX}-title`,
    grid: `${PREFIX}-grid`,
};
const Root = styled("div")(({ theme }) => ({
    height: "100%",
    [`& .${classes.filter}`]: {
        padding: theme.spacing(0.5, 1.5),
        borderRadius: theme.spacing(1),
        cursor: "pointer",
        [`&:hover`]: {
            background: theme.palette.divider
        },
    },
    [`& .${classes.filterActive}`]: {
        background: theme.palette.divider
    },
    [`& .${classes.title}`]: {
        fontSize: "22px"
    },
    [`& .${classes.grid}`]: {
        display: "grid",
        gridAutoFlow: "column", /* Forces items to be laid out in a single row */
        gridGap: "10px", /* Optional: Adds spacing between items */
        overflowX: "scroll", /* Allows horizontal scrolling if items overflow */
        whiteSpace: "nowrap",
        width: "100%",
        MsOverflowStyle: "none", /* Internet Explorer 10+ */
        "&::-webkit-scrollbar": {
            display: "none"
        },
        justifyContent: "start",
        alignItems: "center"
    },
}));

export interface DataByIdType {
    id: number
    name: string
    fileName: string | undefined
    active: boolean
    parentId: number
}

type searchType = {
    name?: string
}

export default function UsersList() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { control, handleSubmit } = useForm<searchType>()

    const paramsObject = Object.fromEntries(searchParams.entries());

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const { data, loading } = useList_UsersQuery({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
            input: {
                ...paramsObject
            },
            first: rowsPerPage,
            page: page + 1
        },
    });

    const tableCellHeader = [
        "name", "email", "birthdate", "createdAt", "active"
    ]


    const onSubmit = (data: searchType) => {
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(([key, value]) => value !== null && value !== "")
        ) as searchType;

        if (Object.entries(filteredData).length === 0) {
            // Handle case with no filters]
            navigate(`/dashboard/users`)
        } else {
            // Convert the filtered data to URLSearchParams
            const searchParams = new URLSearchParams(filteredData).toString();
            // Navigate to the new URL with search parameters
            navigate(`?${searchParams}`);
        }
    };

    return (
        <Root>
            {/* {createDialog && <CategoriesCreate open={createDialog} handleClose={handleCloseCreateDialog} dataById={dataById} refetch={refetch} />} */}
            <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
                <Typography className={classes.title}>{t(PREFIX)}</Typography>
                {/* <ButtonLink to='create' variant='contained' text={t("addBranch")} /> */}
            </Stack>
            <Stack direction={"row"} spacing={1} component={"form"} onSubmit={handleSubmit(onSubmit)} minHeight={50}>
                <Stack width={"100px"} justifyContent={"center"}>
                    <LoadingButton loading={loading} variant='contained' type='submit'>{t("search")}</LoadingButton>
                </Stack>
                <Box className={classes.grid}>
                    <Stack width={"120px"}>
                        <ControlMUITextField
                            label={t("name")}
                            control={control}
                            name="name"
                        />
                    </Stack>
                </Box>
            </Stack>
            <ListPaper loading={loading} data={!!(data && data.listUsers.data.length)} filters>
                <TableContainer sx={{ width: "100%", overflow: "auto" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {tableCellHeader.map(e =>
                                    <TableCell align={'left'} key={e}>
                                        {t(e)}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBodyWithLoad loading={loading} tableCellHeaderLength={tableCellHeader.length}>
                            <TableBody>
                                {data?.listUsers.data.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            <FixedTableCell>
                                                {row.firstName}
                                            </FixedTableCell>
                                            <FixedTableCell>
                                                {row.email}
                                            </FixedTableCell>
                                            <FixedTableCell align={'left'}>
                                                {row.birthdate}
                                            </FixedTableCell>
                                            <FixedTableCell align={'left'}>
                                                {row.date}
                                            </FixedTableCell>
                                            <FixedTableCell align={'left'}>
                                                <ActivationUser id={row.id} value={row.active} />
                                            </FixedTableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </TableBodyWithLoad>
                    </Table>
                </TableContainer>
                <MUITablePagination
                    count={data?.listUsers.paginatorInfo.total}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </ListPaper>
        </Root>
    );
}
