import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: number, firstName: string, lastName: string, mobile?: string | null, email: string, date: any, birthdate?: any | null, isAdmin: boolean, isSuper: boolean, image?: { __typename?: 'Image', path: string } | null, addresses: Array<{ __typename?: 'Address', id: number, address: string, default: boolean, phone?: string | null, mobile?: string | null, date: any, name: string } | null>, cart?: { __typename?: 'Cart', id: number, price: number, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, price: number, product: { __typename?: 'Product', name: string, price: number, weight?: number | null, id: number, image?: { __typename?: 'Image', path: string } | null }, options: Array<{ __typename?: 'CartItemOption', id: number, value: boolean } | null>, addons: Array<{ __typename?: 'CartItemAddon', id: number, addon: { __typename?: 'Addon', price: number } } | null> } | null> } | null } | null };

export type CartQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CartQuery = { __typename?: 'Query', cart: { __typename?: 'Cart', id: number, price: number, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, price: number, product: { __typename?: 'Product', name: string, price: number, weight?: number | null, id: number, image?: { __typename?: 'Image', path: string } | null, addons?: Array<{ __typename?: 'Addon', id: number } | null> | null }, options: Array<{ __typename?: 'CartItemOption', id: number, value: boolean, option: { __typename?: 'Option', id: number, name: string } } | null>, addons: Array<{ __typename?: 'CartItemAddon', id: number, addon: { __typename?: 'Addon', name: string, price: number, id: number } } | null> } | null> } };

export type List_UsersQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListUsersFilterInput>;
  first: Types.Scalars['Int']['input'];
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type List_UsersQuery = { __typename?: 'Query', listUsers: { __typename?: 'UserPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number }, data: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string, email: string, date: any, birthdate?: any | null, isAdmin: boolean, active: boolean }> } };

export type Save_AddressMutationVariables = Types.Exact<{
  input: Types.AddressInput;
}>;


export type Save_AddressMutation = { __typename?: 'Mutation', saveAddress: { __typename?: 'Address', id: number, address: string, default: boolean, phone?: string | null, mobile?: string | null, date: any } };

export type Delete_AddressMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type Delete_AddressMutation = { __typename?: 'Mutation', deleteAddress: boolean };

export type Set_Address_DefaultMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type Set_Address_DefaultMutation = { __typename?: 'Mutation', setAddressDefault: { __typename?: 'Address', id: number } };

export type Delete_Cart_Item_AddonMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type Delete_Cart_Item_AddonMutation = { __typename?: 'Mutation', deleteCartItemAddon: boolean };


export const MeDocument = gql`
    query ME {
  me {
    id
    firstName
    lastName
    mobile
    email
    image {
      path
    }
    date
    birthdate
    addresses {
      id
      address
      default
      phone
      mobile
      date
      name
    }
    cart {
      id
      price
      items {
        id
        product {
          name
          price
          weight
          id
          image {
            path
          }
        }
        quantity
        price
        options {
          id
          value
        }
        addons {
          id
          addon {
            price
          }
        }
      }
    }
    isAdmin
    isSuper
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CartDocument = gql`
    query CART {
  cart {
    id
    price
    items {
      id
      product {
        name
        price
        weight
        id
        image {
          path
        }
        addons {
          id
        }
      }
      quantity
      price
      options {
        id
        value
        option {
          id
          name
        }
      }
      addons {
        id
        addon {
          name
          price
          id
        }
      }
    }
  }
}
    `;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartQuery(baseOptions?: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
      }
export function useCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, options);
        }
export function useCartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CartQuery, CartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CartQuery, CartQueryVariables>(CartDocument, options);
        }
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartSuspenseQueryHookResult = ReturnType<typeof useCartSuspenseQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const List_UsersDocument = gql`
    query LIST_USERS($input: ListUsersFilterInput, $first: Int!, $page: Int) {
  listUsers(input: $input, first: $first, page: $page) {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
    data {
      id
      firstName
      lastName
      email
      date
      birthdate
      isAdmin
      active
    }
  }
}
    `;

/**
 * __useList_UsersQuery__
 *
 * To run a query within a React component, call `useList_UsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_UsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_UsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useList_UsersQuery(baseOptions: Apollo.QueryHookOptions<List_UsersQuery, List_UsersQueryVariables> & ({ variables: List_UsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_UsersQuery, List_UsersQueryVariables>(List_UsersDocument, options);
      }
export function useList_UsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_UsersQuery, List_UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_UsersQuery, List_UsersQueryVariables>(List_UsersDocument, options);
        }
export function useList_UsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<List_UsersQuery, List_UsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<List_UsersQuery, List_UsersQueryVariables>(List_UsersDocument, options);
        }
export type List_UsersQueryHookResult = ReturnType<typeof useList_UsersQuery>;
export type List_UsersLazyQueryHookResult = ReturnType<typeof useList_UsersLazyQuery>;
export type List_UsersSuspenseQueryHookResult = ReturnType<typeof useList_UsersSuspenseQuery>;
export type List_UsersQueryResult = Apollo.QueryResult<List_UsersQuery, List_UsersQueryVariables>;
export const Save_AddressDocument = gql`
    mutation SAVE_ADDRESS($input: AddressInput!) {
  saveAddress(input: $input) {
    id
    address
    default
    phone
    mobile
    date
  }
}
    `;
export type Save_AddressMutationFn = Apollo.MutationFunction<Save_AddressMutation, Save_AddressMutationVariables>;

/**
 * __useSave_AddressMutation__
 *
 * To run a mutation, you first call `useSave_AddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSave_AddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAddressMutation, { data, loading, error }] = useSave_AddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSave_AddressMutation(baseOptions?: Apollo.MutationHookOptions<Save_AddressMutation, Save_AddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Save_AddressMutation, Save_AddressMutationVariables>(Save_AddressDocument, options);
      }
export type Save_AddressMutationHookResult = ReturnType<typeof useSave_AddressMutation>;
export type Save_AddressMutationResult = Apollo.MutationResult<Save_AddressMutation>;
export type Save_AddressMutationOptions = Apollo.BaseMutationOptions<Save_AddressMutation, Save_AddressMutationVariables>;
export const Delete_AddressDocument = gql`
    mutation DELETE_ADDRESS($id: Int!) {
  deleteAddress(id: $id)
}
    `;
export type Delete_AddressMutationFn = Apollo.MutationFunction<Delete_AddressMutation, Delete_AddressMutationVariables>;

/**
 * __useDelete_AddressMutation__
 *
 * To run a mutation, you first call `useDelete_AddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_AddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDelete_AddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_AddressMutation(baseOptions?: Apollo.MutationHookOptions<Delete_AddressMutation, Delete_AddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_AddressMutation, Delete_AddressMutationVariables>(Delete_AddressDocument, options);
      }
export type Delete_AddressMutationHookResult = ReturnType<typeof useDelete_AddressMutation>;
export type Delete_AddressMutationResult = Apollo.MutationResult<Delete_AddressMutation>;
export type Delete_AddressMutationOptions = Apollo.BaseMutationOptions<Delete_AddressMutation, Delete_AddressMutationVariables>;
export const Set_Address_DefaultDocument = gql`
    mutation SET_ADDRESS_DEFAULT($id: Int!) {
  setAddressDefault(id: $id) {
    id
  }
}
    `;
export type Set_Address_DefaultMutationFn = Apollo.MutationFunction<Set_Address_DefaultMutation, Set_Address_DefaultMutationVariables>;

/**
 * __useSet_Address_DefaultMutation__
 *
 * To run a mutation, you first call `useSet_Address_DefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSet_Address_DefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAddressDefaultMutation, { data, loading, error }] = useSet_Address_DefaultMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSet_Address_DefaultMutation(baseOptions?: Apollo.MutationHookOptions<Set_Address_DefaultMutation, Set_Address_DefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Set_Address_DefaultMutation, Set_Address_DefaultMutationVariables>(Set_Address_DefaultDocument, options);
      }
export type Set_Address_DefaultMutationHookResult = ReturnType<typeof useSet_Address_DefaultMutation>;
export type Set_Address_DefaultMutationResult = Apollo.MutationResult<Set_Address_DefaultMutation>;
export type Set_Address_DefaultMutationOptions = Apollo.BaseMutationOptions<Set_Address_DefaultMutation, Set_Address_DefaultMutationVariables>;
export const Delete_Cart_Item_AddonDocument = gql`
    mutation DELETE_CART_ITEM_ADDON($id: Int!) {
  deleteCartItemAddon(id: $id)
}
    `;
export type Delete_Cart_Item_AddonMutationFn = Apollo.MutationFunction<Delete_Cart_Item_AddonMutation, Delete_Cart_Item_AddonMutationVariables>;

/**
 * __useDelete_Cart_Item_AddonMutation__
 *
 * To run a mutation, you first call `useDelete_Cart_Item_AddonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Cart_Item_AddonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCartItemAddonMutation, { data, loading, error }] = useDelete_Cart_Item_AddonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_Cart_Item_AddonMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Cart_Item_AddonMutation, Delete_Cart_Item_AddonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Cart_Item_AddonMutation, Delete_Cart_Item_AddonMutationVariables>(Delete_Cart_Item_AddonDocument, options);
      }
export type Delete_Cart_Item_AddonMutationHookResult = ReturnType<typeof useDelete_Cart_Item_AddonMutation>;
export type Delete_Cart_Item_AddonMutationResult = Apollo.MutationResult<Delete_Cart_Item_AddonMutation>;
export type Delete_Cart_Item_AddonMutationOptions = Apollo.BaseMutationOptions<Delete_Cart_Item_AddonMutation, Delete_Cart_Item_AddonMutationVariables>;