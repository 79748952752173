import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../Store/store';
import { ROLE } from '../types';

export default function NotAnAdmin({ children }) {
  const { role } = useAppSelector((state) => state.auth);
  console.log(role);
  

  if (role !== ROLE.CUSTOMER) {
    return <Navigate to="/" />;
  }

  return children
}