export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Time: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type Addon = {
  __typename?: 'Addon';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type AddonInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type Address = {
  __typename?: 'Address';
  address: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  default: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  address: Scalars['String']['input'];
  default?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  appointmentDate: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  order: Order;
  status: AppointmentStatusCode;
  tableTime: TableTime;
  user: User;
};

export type AppointmentInput = {
  appointmentDate: Scalars['Date']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  tableTimeId: Scalars['Int']['input'];
};

/** A paginated list of Appointment items. */
export type AppointmentPaginator = {
  __typename?: 'AppointmentPaginator';
  /** A list of Appointment items. */
  data: Array<Appointment>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Appointment status code */
export enum AppointmentStatusCode {
  /** Approved */
  Approved = 'APPROVED',
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Pending */
  Pending = 'PENDING'
}

export type Branch = {
  __typename?: 'Branch';
  address: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  endTime: Scalars['Time']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Image>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  startTime: Scalars['Time']['output'];
  tables: Array<Maybe<Table>>;
  telephone?: Maybe<Scalars['String']['output']>;
  whatsapp?: Maybe<Scalars['String']['output']>;
};

export type BranchInput = {
  address: Scalars['String']['input'];
  endTime: Scalars['Time']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<ImageInput>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  startTime: Scalars['Time']['input'];
  telephone?: InputMaybe<Scalars['String']['input']>;
  whatsapp?: InputMaybe<Scalars['String']['input']>;
};

/** A paginated list of Branch items. */
export type BranchPaginator = {
  __typename?: 'BranchPaginator';
  /** A list of Branch items. */
  data: Array<Branch>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type CalculateOrderFeesInput = {
  deliveryType?: InputMaybe<DeliveryTypeCode>;
  includeDeliveryFees: Scalars['Boolean']['input'];
};

export type CancelOrderInput = {
  cancellationReason: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['Int']['output'];
  items: Array<Maybe<CartItem>>;
  price: Scalars['Float']['output'];
};

export type CartItem = {
  __typename?: 'CartItem';
  addons: Array<Maybe<CartItemAddon>>;
  id: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  options: Array<Maybe<CartItemOption>>;
  price: Scalars['Float']['output'];
  product: Product;
  quantity: Scalars['Int']['output'];
};

export type CartItemAddon = {
  __typename?: 'CartItemAddon';
  addon: Addon;
  id: Scalars['Int']['output'];
};

export type CartItemAddonInput = {
  addonId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CartItemInput = {
  addons?: InputMaybe<Array<InputMaybe<CartItemAddonInput>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<CartItemOptionInput>>>;
  productId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type CartItemOption = {
  __typename?: 'CartItemOption';
  id: Scalars['Int']['output'];
  option: Option;
  value: Scalars['Boolean']['output'];
};

export type CartItemOptionInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  optionId: Scalars['Int']['input'];
  value: Scalars['Boolean']['input'];
};

export type Category = {
  __typename?: 'Category';
  active: Scalars['Boolean']['output'];
  children: Array<Maybe<Category>>;
  date: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Image>;
  name: Scalars['String']['output'];
  parent?: Maybe<Category>;
  productsCount: Scalars['Int']['output'];
};

export type CategoryInput = {
  active?: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<ImageInput>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

/** A paginated list of Category items. */
export type CategoryPaginator = {
  __typename?: 'CategoryPaginator';
  /** A list of Category items. */
  data: Array<Category>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ChangePasswordInput = {
  new: Scalars['String']['input'];
  old: Scalars['String']['input'];
};

/** Delivery type code */
export enum DeliveryTypeCode {
  /** Dlv */
  Dlv = 'DLV',
  /** Rib */
  Rib = 'RIB',
  /** Trs */
  Trs = 'TRS'
}

export type DropdownEntry = {
  __typename?: 'DropdownEntry';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Image = {
  __typename?: 'Image';
  path: Scalars['String']['output'];
};

export type ImageInput = {
  image: Scalars['Upload']['input'];
};

export type ListAppointmentsFilterInput = {
  appointmentDate?: InputMaybe<Scalars['Date']['input']>;
  branchId?: InputMaybe<Scalars['Int']['input']>;
  tableId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type ListCategoriesFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  sub?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListOrdersFilterInput = {
  appointmentId?: InputMaybe<Scalars['Int']['input']>;
  branchId?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  deliveryType?: InputMaybe<DeliveryTypeCode>;
  paymentType?: InputMaybe<PaymentTypeCode>;
  status?: InputMaybe<OrderStatusCode>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type ListProductsFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subCategoryId?: InputMaybe<Scalars['Int']['input']>;
};

export type ListTableTimesFilterInput = {
  date: Scalars['Date']['input'];
  tableId: Scalars['Int']['input'];
};

export type ListUsersFilterInput = {
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  rememberMe?: Scalars['Boolean']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adjustCart: Scalars['Boolean']['output'];
  cancelOrder: Order;
  changePassword: Scalars['Boolean']['output'];
  createOrder: Order;
  createPaymentIntent: PaymentIntent;
  deleteAddon: Scalars['Boolean']['output'];
  deleteAddress: Scalars['Boolean']['output'];
  deleteBranch: Scalars['Boolean']['output'];
  deleteCartItem: Scalars['Boolean']['output'];
  deleteCartItemAddon: Scalars['Boolean']['output'];
  deleteCartItemOption: Scalars['Boolean']['output'];
  deleteCategory: Scalars['Boolean']['output'];
  deleteOption: Scalars['Boolean']['output'];
  deleteProduct: Scalars['Boolean']['output'];
  deleteTable: Scalars['Boolean']['output'];
  disableUser: Scalars['Boolean']['output'];
  emptyCart: Cart;
  login: UserWithToken;
  logout: Scalars['Boolean']['output'];
  register: Scalars['Boolean']['output'];
  resendVerificationCode: Scalars['Boolean']['output'];
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  saveAddress: Address;
  saveAppointment: Appointment;
  saveBranch: Branch;
  saveCartItem: CartItem;
  saveCategory: Category;
  saveProduct: Product;
  saveTable: Table;
  setAddressDefault: Address;
  toggleActivateUser: Scalars['Boolean']['output'];
  updateCart: Cart;
  updateOrderStatus: Order;
  updateSettings: Settings;
  updateUser: User;
  updateUserImage: User;
  verifyUserEmail: Scalars['Boolean']['output'];
};


export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCreateOrderArgs = {
  input: OrderInput;
};


export type MutationCreatePaymentIntentArgs = {
  input: PaymentIntentInput;
};


export type MutationDeleteAddonArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAddressArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteBranchArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCartItemArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCartItemAddonArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCartItemOptionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteOptionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteTableArgs = {
  id: Scalars['Int']['input'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationResendVerificationCodeArgs = {
  email: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationSaveAddressArgs = {
  input: AddressInput;
};


export type MutationSaveAppointmentArgs = {
  input: AppointmentInput;
};


export type MutationSaveBranchArgs = {
  input: BranchInput;
};


export type MutationSaveCartItemArgs = {
  input: CartItemInput;
};


export type MutationSaveCategoryArgs = {
  input: CategoryInput;
};


export type MutationSaveProductArgs = {
  input: ProductInput;
};


export type MutationSaveTableArgs = {
  input: TableInput;
};


export type MutationSetAddressDefaultArgs = {
  id: Scalars['Int']['input'];
};


export type MutationToggleActivateUserArgs = {
  id: Scalars['Int']['input'];
};


export type MutationUpdateCartArgs = {
  input: UpdateCartInput;
};


export type MutationUpdateOrderStatusArgs = {
  input: UpdateOrderStatusInput;
};


export type MutationUpdateSettingsArgs = {
  input: SettingsInput;
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationUpdateUserImageArgs = {
  input: UserImageInput;
};


export type MutationVerifyUserEmailArgs = {
  input: VerifyUserEmailInput;
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type OptionInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};

export type Order = {
  __typename?: 'Order';
  address?: Maybe<Address>;
  appointment?: Maybe<Appointment>;
  branch?: Maybe<Branch>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  deliveryFees: Scalars['Float']['output'];
  deliveryType: DeliveryTypeCode;
  id: Scalars['Int']['output'];
  items: Array<OrderItem>;
  note?: Maybe<Scalars['String']['output']>;
  paymentStatus: PaymentStatusCode;
  paymentType: PaymentTypeCode;
  price: Scalars['Float']['output'];
  status: OrderStatusCode;
  subtotal: Scalars['Float']['output'];
  taxFees: Scalars['Float']['output'];
  updateDate: Scalars['Date']['output'];
  user: User;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']['input'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

export type OrderFees = {
  __typename?: 'OrderFees';
  deliveryFees: Scalars['Float']['output'];
  subtotal: Scalars['Float']['output'];
  taxFees: Scalars['Float']['output'];
  totalFees: Scalars['Float']['output'];
};

export type OrderInput = {
  addressId?: InputMaybe<Scalars['Int']['input']>;
  appointmentDate?: InputMaybe<Scalars['Date']['input']>;
  branchId?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryType: DeliveryTypeCode;
  note?: InputMaybe<Scalars['String']['input']>;
  paymentType: PaymentTypeCode;
  tableTimeId?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  addons: Array<Maybe<OrderItemAddon>>;
  id: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  options: Array<Maybe<OrderItemOption>>;
  price: Scalars['Float']['output'];
  product: Product;
  quantity: Scalars['Int']['output'];
};

export type OrderItemAddon = {
  __typename?: 'OrderItemAddon';
  addon: Addon;
  id: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
};

export type OrderItemOption = {
  __typename?: 'OrderItemOption';
  id: Scalars['Int']['output'];
  option: Option;
  value: Scalars['Boolean']['output'];
};

/** A paginated list of Order items. */
export type OrderPaginator = {
  __typename?: 'OrderPaginator';
  /** A list of Order items. */
  data: Array<Order>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Order status code */
export enum OrderStatusCode {
  /** Approved */
  Approved = 'APPROVED',
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Completed */
  Completed = 'COMPLETED',
  /** Delivering */
  Delivering = 'DELIVERING',
  /** Pending */
  Pending = 'PENDING',
  /** Prepared */
  Prepared = 'PREPARED',
  /** Preparing */
  Preparing = 'PREPARING'
}

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int']['output'];
  /** Index of the current page. */
  currentPage: Scalars['Int']['output'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']['output']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']['output'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']['output']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int']['output'];
  /** Number of items per page. */
  perPage: Scalars['Int']['output'];
  /** Number of total available items. */
  total: Scalars['Int']['output'];
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  amount: Scalars['Float']['output'];
  clientSecret: Scalars['String']['output'];
  customerClientSecret?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type PaymentIntentInput = {
  orderId: Scalars['Int']['input'];
};

/** Payment status code */
export enum PaymentStatusCode {
  /** Paid */
  Paid = 'PAID',
  /** Pending */
  Pending = 'PENDING',
  /** Refunded */
  Refunded = 'REFUNDED',
  /** Refunding */
  Refunding = 'REFUNDING'
}

/** Payment type code */
export enum PaymentTypeCode {
  /** Cod */
  Cod = 'COD',
  /** Stripe */
  Stripe = 'STRIPE'
}

export type Product = {
  __typename?: 'Product';
  active: Scalars['Boolean']['output'];
  addons?: Maybe<Array<Maybe<Addon>>>;
  category: Category;
  date: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Image>;
  name: Scalars['String']['output'];
  options: Array<Maybe<Option>>;
  price: Scalars['Float']['output'];
  recommendations?: Maybe<Array<Maybe<Recommendation>>>;
  subCategory: Category;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type ProductInput = {
  active?: Scalars['Boolean']['input'];
  addons?: InputMaybe<Array<InputMaybe<AddonInput>>>;
  categoryId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<ImageInput>;
  name: Scalars['String']['input'];
  options?: InputMaybe<Array<InputMaybe<OptionInput>>>;
  price: Scalars['Float']['input'];
  recommendedProducts?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  subCategoryId: Scalars['Int']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
};

/** A paginated list of Product items. */
export type ProductPaginator = {
  __typename?: 'ProductPaginator';
  /** A list of Product items. */
  data: Array<Product>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Query = {
  __typename?: 'Query';
  appointment: Appointment;
  branch: Branch;
  calculateOrderFees: OrderFees;
  cart: Cart;
  category: Category;
  listAppointments: AppointmentPaginator;
  listBranches: BranchPaginator;
  listBranchesDropdown: Array<Maybe<DropdownEntry>>;
  listCategories: CategoryPaginator;
  listCategoriesDropdown: Array<Maybe<DropdownEntry>>;
  listOrders: OrderPaginator;
  listProducts: ProductPaginator;
  listProductsDropdown: Array<Maybe<DropdownEntry>>;
  listTableTimesDropdown: Array<Maybe<DropdownEntry>>;
  listTables: TablePaginator;
  listTablesDropdown: Array<Maybe<DropdownEntry>>;
  listUsers: UserPaginator;
  listUsersDropdown: Array<Maybe<DropdownEntry>>;
  me?: Maybe<User>;
  order: Order;
  product: Product;
  retrievePaymentIntent: PaymentIntent;
  settings: Settings;
};


export type QueryAppointmentArgs = {
  id: Scalars['Int']['input'];
};


export type QueryBranchArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCalculateOrderFeesArgs = {
  input: CalculateOrderFeesInput;
};


export type QueryCategoryArgs = {
  id: Scalars['Int']['input'];
};


export type QueryListAppointmentsArgs = {
  first?: Scalars['Int']['input'];
  input?: InputMaybe<ListAppointmentsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListBranchesArgs = {
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListCategoriesArgs = {
  first?: Scalars['Int']['input'];
  input?: InputMaybe<ListCategoriesFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListCategoriesDropdownArgs = {
  input?: InputMaybe<ListCategoriesFilterInput>;
};


export type QueryListOrdersArgs = {
  first?: Scalars['Int']['input'];
  input?: InputMaybe<ListOrdersFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListProductsArgs = {
  first?: Scalars['Int']['input'];
  input?: InputMaybe<ListProductsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListProductsDropdownArgs = {
  input?: InputMaybe<ListProductsFilterInput>;
};


export type QueryListTableTimesDropdownArgs = {
  input: ListTableTimesFilterInput;
};


export type QueryListTablesArgs = {
  branchId?: InputMaybe<Scalars['Int']['input']>;
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListTablesDropdownArgs = {
  branchId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListUsersArgs = {
  first?: Scalars['Int']['input'];
  input?: InputMaybe<ListUsersFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListUsersDropdownArgs = {
  input?: InputMaybe<ListUsersFilterInput>;
};


export type QueryOrderArgs = {
  id: Scalars['Int']['input'];
};


export type QueryProductArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRetrievePaymentIntentArgs = {
  paymentIntentId: Scalars['String']['input'];
};

export type Recommendation = {
  __typename?: 'Recommendation';
  id: Scalars['Int']['output'];
  recommended: Product;
};

export type RegisterInput = {
  address?: InputMaybe<AddressInput>;
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobile?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type Settings = {
  __typename?: 'Settings';
  deliveryFess?: Maybe<Scalars['Float']['output']>;
  lastOrder?: Maybe<Scalars['Time']['output']>;
  reservationHours: Scalars['Int']['output'];
  serviceTax?: Maybe<Scalars['Float']['output']>;
  stripe?: Maybe<StripeSettings>;
  tax?: Maybe<Scalars['Float']['output']>;
};

export type SettingsInput = {
  deliveryFess?: InputMaybe<Scalars['Float']['input']>;
  lastOrder?: InputMaybe<Scalars['Time']['input']>;
  reservationHours: Scalars['Int']['input'];
  serviceTax?: InputMaybe<Scalars['Float']['input']>;
  stripe?: InputMaybe<StripeSettingsInput>;
  tax?: InputMaybe<Scalars['Float']['input']>;
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type StripeSettings = {
  __typename?: 'StripeSettings';
  active?: Maybe<Scalars['Boolean']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  secretKey?: Maybe<Scalars['String']['output']>;
  webhookSecret?: Maybe<Scalars['String']['output']>;
};

export type StripeSettingsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  secretKey?: InputMaybe<Scalars['String']['input']>;
  webhookSecret?: InputMaybe<Scalars['String']['input']>;
};

export type Table = {
  __typename?: 'Table';
  branch: Branch;
  chairsNumber: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  times: Array<Maybe<TableTime>>;
};

export type TableInput = {
  branchId: Scalars['Int']['input'];
  chairsNumber: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

/** A paginated list of Table items. */
export type TablePaginator = {
  __typename?: 'TablePaginator';
  /** A list of Table items. */
  data: Array<Table>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type TableTime = {
  __typename?: 'TableTime';
  fromTime: Scalars['Time']['output'];
  id: Scalars['Int']['output'];
  table: Table;
  toTime: Scalars['Time']['output'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UpdateCartInput = {
  items: Array<InputMaybe<CartItemInput>>;
};

export type UpdateOrderStatusInput = {
  id: Scalars['Int']['input'];
  status: OrderStatusCode;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  addresses: Array<Maybe<Address>>;
  birthdate?: Maybe<Scalars['Date']['output']>;
  cart?: Maybe<Cart>;
  date: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Image>;
  isAdmin: Scalars['Boolean']['output'];
  isSuper: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  orders: Array<Maybe<Order>>;
};

export type UserImageInput = {
  image: ImageInput;
};

export type UserInput = {
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobile?: InputMaybe<Scalars['String']['input']>;
};

/** A paginated list of User items. */
export type UserPaginator = {
  __typename?: 'UserPaginator';
  /** A list of User items. */
  data: Array<User>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type UserWithToken = {
  __typename?: 'UserWithToken';
  expireAt?: Maybe<Scalars['DateTime']['output']>;
  token: Scalars['String']['output'];
  user: User;
};

export type VerifyUserEmailInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: boolean };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'UserWithToken', token: string, user: { __typename?: 'User', id: number, firstName: string, lastName: string, mobile?: string | null, isAdmin: boolean, isSuper: boolean, birthdate?: any | null, email: string, image?: { __typename?: 'Image', path: string } | null, addresses: Array<{ __typename?: 'Address', id: number, name: string, address: string, default: boolean, phone?: string | null, mobile?: string | null, date: any } | null>, cart?: { __typename?: 'Cart', id: number, price: number, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, price: number, product: { __typename?: 'Product', name: string, price: number, weight?: number | null, id: number, image?: { __typename?: 'Image', path: string } | null }, options: Array<{ __typename?: 'CartItemOption', id: number, value: boolean } | null>, addons: Array<{ __typename?: 'CartItemAddon', id: number, addon: { __typename?: 'Addon', price: number } } | null> } | null> } | null } } };

export type Verify_User_EmailMutationVariables = Exact<{
  input: VerifyUserEmailInput;
}>;


export type Verify_User_EmailMutation = { __typename?: 'Mutation', verifyUserEmail: boolean };

export type Resend_Verification_CodeMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type Resend_Verification_CodeMutation = { __typename?: 'Mutation', resendVerificationCode: boolean };

export type Save_BranchMutationVariables = Exact<{
  input: BranchInput;
}>;


export type Save_BranchMutation = { __typename?: 'Mutation', saveBranch: { __typename?: 'Branch', id: number } };

export type Save_TableMutationVariables = Exact<{
  input: TableInput;
}>;


export type Save_TableMutation = { __typename?: 'Mutation', saveTable: { __typename?: 'Table', id: number } };

export type Create_OrderMutationVariables = Exact<{
  input: OrderInput;
}>;


export type Create_OrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'Order', id: number, code: string } };

export type Create_Payment_IntentMutationVariables = Exact<{
  input: PaymentIntentInput;
}>;


export type Create_Payment_IntentMutation = { __typename?: 'Mutation', createPaymentIntent: { __typename?: 'PaymentIntent', id: string, clientSecret: string, amount: number, customerClientSecret?: string | null } };

export type Update_Order_StatusMutationVariables = Exact<{
  input: UpdateOrderStatusInput;
}>;


export type Update_Order_StatusMutation = { __typename?: 'Mutation', updateOrderStatus: { __typename?: 'Order', id: number } };

export type Cancel_OrderMutationVariables = Exact<{
  input: CancelOrderInput;
}>;


export type Cancel_OrderMutation = { __typename?: 'Mutation', cancelOrder: { __typename?: 'Order', id: number } };

export type Save_CategoryMutationVariables = Exact<{
  input: CategoryInput;
}>;


export type Save_CategoryMutation = { __typename?: 'Mutation', saveCategory: { __typename?: 'Category', id: number, name: string } };

export type Save_ProductMutationVariables = Exact<{
  input: ProductInput;
}>;


export type Save_ProductMutation = { __typename?: 'Mutation', saveProduct: { __typename?: 'Product', id: number } };

export type Delete_OptionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type Delete_OptionMutation = { __typename?: 'Mutation', deleteOption: boolean };

export type Delete_AddonMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type Delete_AddonMutation = { __typename?: 'Mutation', deleteAddon: boolean };

export type Delete_ProductMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type Delete_ProductMutation = { __typename?: 'Mutation', deleteProduct: boolean };

export type Update_SettingsMutationVariables = Exact<{
  input: SettingsInput;
}>;


export type Update_SettingsMutation = { __typename?: 'Mutation', updateSettings: { __typename?: 'Settings', lastOrder?: any | null, tax?: number | null, deliveryFess?: number | null } };

export type Save_Cart_ItemMutationVariables = Exact<{
  input: CartItemInput;
}>;


export type Save_Cart_ItemMutation = { __typename?: 'Mutation', saveCartItem: { __typename?: 'CartItem', id: number, price: number, quantity: number, product: { __typename?: 'Product', name: string, price: number, weight?: number | null, id: number, image?: { __typename?: 'Image', path: string } | null }, options: Array<{ __typename?: 'CartItemOption', id: number, value: boolean } | null>, addons: Array<{ __typename?: 'CartItemAddon', id: number, addon: { __typename?: 'Addon', price: number } } | null> } };

export type DeleteCartItemMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteCartItemMutation = { __typename?: 'Mutation', deleteCartItem: boolean };

export type Adjust_CartMutationVariables = Exact<{ [key: string]: never; }>;


export type Adjust_CartMutation = { __typename?: 'Mutation', adjustCart: boolean };

export type Update_UserMutationVariables = Exact<{
  input: UserInput;
}>;


export type Update_UserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number } };

export type Update_User_ImageMutationVariables = Exact<{
  input: UserImageInput;
}>;


export type Update_User_ImageMutation = { __typename?: 'Mutation', updateUserImage: { __typename?: 'User', id: number, image?: { __typename?: 'Image', path: string } | null } };

export type Change_PasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type Change_PasswordMutation = { __typename?: 'Mutation', changePassword: boolean };

export type Reset_PasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type Reset_PasswordMutation = { __typename?: 'Mutation', resetPassword?: boolean | null };

export type Toggle_Activate_UserMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type Toggle_Activate_UserMutation = { __typename?: 'Mutation', toggleActivateUser: boolean };

export type List_BranchesQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
}>;


export type List_BranchesQuery = { __typename?: 'Query', listBranches: { __typename?: 'BranchPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number }, data: Array<{ __typename?: 'Branch', id: number, name: string, address: string, longitude: number, latitude: number, telephone?: string | null, whatsapp?: string | null, startTime: any, endTime: any, date: any, image?: { __typename?: 'Image', path: string } | null, tables: Array<{ __typename?: 'Table', id: number } | null> }> } };

export type List_Tables_BranchQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type List_Tables_BranchQuery = { __typename?: 'Query', branch: { __typename?: 'Branch', tables: Array<{ __typename?: 'Table', id: number, name: string, chairsNumber: number, date: any, times: Array<{ __typename?: 'TableTime', id: number, fromTime: any, toTime: any } | null> } | null> } };

export type BranchQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type BranchQuery = { __typename?: 'Query', branch: { __typename?: 'Branch', id: number, name: string, address: string, longitude: number, latitude: number, telephone?: string | null, whatsapp?: string | null, startTime: any, endTime: any, date: any, image?: { __typename?: 'Image', path: string } | null, tables: Array<{ __typename?: 'Table', id: number, name: string, chairsNumber: number, date: any } | null> } };

export type ListAppointmentsQueryVariables = Exact<{
  input?: InputMaybe<ListAppointmentsFilterInput>;
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
}>;


export type ListAppointmentsQuery = { __typename?: 'Query', listAppointments: { __typename?: 'AppointmentPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number }, data: Array<{ __typename?: 'Appointment', id: number, appointmentDate: any, status: AppointmentStatusCode, tableTime: { __typename?: 'TableTime', id: number, fromTime: any, toTime: any, table: { __typename?: 'Table', name: string, id: number, branch: { __typename?: 'Branch', id: number, name: string } } }, user: { __typename?: 'User', firstName: string, lastName: string, id: number }, order: { __typename?: 'Order', id: number, code: string } }> } };

export type List_Branches_DropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type List_Branches_DropdownQuery = { __typename?: 'Query', listBranchesDropdown: Array<{ __typename?: 'DropdownEntry', id: number, name: string } | null> };

export type List_Tables_DropdownQueryVariables = Exact<{
  branchId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type List_Tables_DropdownQuery = { __typename?: 'Query', listTablesDropdown: Array<{ __typename?: 'DropdownEntry', id: number, name: string } | null> };

export type List_Tables_Times_DropdownQueryVariables = Exact<{
  input: ListTableTimesFilterInput;
}>;


export type List_Tables_Times_DropdownQuery = { __typename?: 'Query', listTableTimesDropdown: Array<{ __typename?: 'DropdownEntry', id: number, name: string } | null> };

export type DashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQuery = { __typename?: 'Query', listProducts: { __typename?: 'ProductPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number } }, listUsers: { __typename?: 'UserPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number } }, listOrders: { __typename?: 'OrderPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number } } };

export type List_OrdersQueryVariables = Exact<{
  input?: InputMaybe<ListOrdersFilterInput>;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type List_OrdersQuery = { __typename?: 'Query', listOrders: { __typename?: 'OrderPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, total: number }, data: Array<{ __typename?: 'Order', id: number, code: string, status: OrderStatusCode, deliveryType: DeliveryTypeCode, paymentType: PaymentTypeCode, deliveryDate?: any | null, paymentStatus: PaymentStatusCode, cancellationReason?: string | null, note?: string | null, date: any, user: { __typename?: 'User', firstName: string, lastName: string }, branch?: { __typename?: 'Branch', id: number, name: string } | null }> } };

export type OrderQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type OrderQuery = { __typename?: 'Query', order: { __typename?: 'Order', id: number, code: string, status: OrderStatusCode, deliveryType: DeliveryTypeCode, paymentType: PaymentTypeCode, updateDate: any, paymentStatus: PaymentStatusCode, deliveryDate?: any | null, cancellationReason?: string | null, note?: string | null, date: any, price: number, subtotal: number, taxFees: number, deliveryFees: number, branch?: { __typename?: 'Branch', id: number, name: string } | null, appointment?: { __typename?: 'Appointment', appointmentDate: any, tableTime: { __typename?: 'TableTime', fromTime: any, toTime: any, table: { __typename?: 'Table', name: string, chairsNumber: number } } } | null, address?: { __typename?: 'Address', name: string, address: string, phone?: string | null, mobile?: string | null } | null, items: Array<{ __typename?: 'OrderItem', id: number, quantity: number, price: number, note?: string | null, product: { __typename?: 'Product', id: number, name: string, price: number, image?: { __typename?: 'Image', path: string } | null }, options: Array<{ __typename?: 'OrderItemOption', id: number, value: boolean, option: { __typename?: 'Option', name: string } } | null>, addons: Array<{ __typename?: 'OrderItemAddon', price: number, addon: { __typename?: 'Addon', name: string, price: number } } | null> }> } };

export type Order_AdminQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type Order_AdminQuery = { __typename?: 'Query', order: { __typename?: 'Order', id: number, code: string, status: OrderStatusCode, deliveryType: DeliveryTypeCode, paymentType: PaymentTypeCode, updateDate: any, paymentStatus: PaymentStatusCode, cancellationReason?: string | null, note?: string | null, date: any, price: number, subtotal: number, taxFees: number, deliveryFees: number, deliveryDate?: any | null, branch?: { __typename?: 'Branch', id: number, name: string } | null, appointment?: { __typename?: 'Appointment', appointmentDate: any, tableTime: { __typename?: 'TableTime', fromTime: any, toTime: any, table: { __typename?: 'Table', name: string, chairsNumber: number } } } | null, address?: { __typename?: 'Address', name: string, address: string, phone?: string | null, mobile?: string | null } | null, items: Array<{ __typename?: 'OrderItem', id: number, quantity: number, price: number, note?: string | null, product: { __typename?: 'Product', id: number, name: string, price: number, image?: { __typename?: 'Image', path: string } | null }, options: Array<{ __typename?: 'OrderItemOption', id: number, value: boolean, option: { __typename?: 'Option', name: string } } | null>, addons: Array<{ __typename?: 'OrderItemAddon', price: number, addon: { __typename?: 'Addon', name: string, price: number } } | null> }>, user: { __typename?: 'User', email: string, firstName: string, lastName: string } } };

export type Calculate_Order_FeesQueryVariables = Exact<{
  input: CalculateOrderFeesInput;
}>;


export type Calculate_Order_FeesQuery = { __typename?: 'Query', calculateOrderFees: { __typename?: 'OrderFees', subtotal: number, taxFees: number, deliveryFees: number, totalFees: number } };

export type List_CategoriesQueryVariables = Exact<{
  input?: InputMaybe<ListCategoriesFilterInput>;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type List_CategoriesQuery = { __typename?: 'Query', listCategories: { __typename?: 'CategoryPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, total: number }, data: Array<{ __typename?: 'Category', id: number, name: string, active: boolean, date: any, productsCount: number, parent?: { __typename?: 'Category', name: string, id: number } | null, image?: { __typename?: 'Image', path: string } | null }> } };

export type List_Categories_DropdownQueryVariables = Exact<{
  input?: InputMaybe<ListCategoriesFilterInput>;
}>;


export type List_Categories_DropdownQuery = { __typename?: 'Query', listCategoriesDropdown: Array<{ __typename?: 'DropdownEntry', id: number, name: string } | null> };

export type List_Product_DropdownQueryVariables = Exact<{
  input?: InputMaybe<ListProductsFilterInput>;
}>;


export type List_Product_DropdownQuery = { __typename?: 'Query', listProductsDropdown: Array<{ __typename?: 'DropdownEntry', id: number, name: string } | null> };

export type List_ProductsQueryVariables = Exact<{
  input?: InputMaybe<ListProductsFilterInput>;
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
}>;


export type List_ProductsQuery = { __typename?: 'Query', listProducts: { __typename?: 'ProductPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, total: number }, data: Array<{ __typename?: 'Product', id: number, name: string, active: boolean, date: any, weight?: number | null, price: number, category: { __typename?: 'Category', name: string }, subCategory: { __typename?: 'Category', name: string }, image?: { __typename?: 'Image', path: string } | null }> } };

export type List_Products_PublicQueryVariables = Exact<{
  input?: InputMaybe<ListProductsFilterInput>;
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
}>;


export type List_Products_PublicQuery = { __typename?: 'Query', listProducts: { __typename?: 'ProductPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, total: number }, data: Array<{ __typename?: 'Product', id: number, name: string, active: boolean, date: any, image?: { __typename?: 'Image', path: string } | null, category: { __typename?: 'Category', name: string, id: number }, subCategory: { __typename?: 'Category', name: string, id: number } }> } };

export type Category_By_IdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type Category_By_IdQuery = { __typename?: 'Query', category: { __typename?: 'Category', id: number, name: string } };

export type Product_By_IdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type Product_By_IdQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: number, name: string, description?: string | null, active: boolean, date: any, weight?: number | null, price: number, addons?: Array<{ __typename?: 'Addon', id: number, name: string, price: number } | null> | null, category: { __typename?: 'Category', id: number, name: string }, subCategory: { __typename?: 'Category', id: number, name: string }, image?: { __typename?: 'Image', path: string } | null, options: Array<{ __typename?: 'Option', id: number, name: string, value: boolean } | null>, recommendations?: Array<{ __typename?: 'Recommendation', id: number, recommended: { __typename?: 'Product', id: number, name: string, price: number, image?: { __typename?: 'Image', path: string } | null } } | null> | null } };

export type Product_Addons_By_IdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type Product_Addons_By_IdQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: number, addons?: Array<{ __typename?: 'Addon', id: number, name: string, price: number } | null> | null } };

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = { __typename?: 'Query', settings: { __typename?: 'Settings', stripe?: { __typename?: 'StripeSettings', publicKey?: string | null, active?: boolean | null } | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: number, firstName: string, lastName: string, mobile?: string | null, email: string, date: any, birthdate?: any | null, isAdmin: boolean, isSuper: boolean, image?: { __typename?: 'Image', path: string } | null, addresses: Array<{ __typename?: 'Address', id: number, address: string, default: boolean, phone?: string | null, mobile?: string | null, date: any, name: string } | null>, cart?: { __typename?: 'Cart', id: number, price: number, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, price: number, product: { __typename?: 'Product', name: string, price: number, weight?: number | null, id: number, image?: { __typename?: 'Image', path: string } | null }, options: Array<{ __typename?: 'CartItemOption', id: number, value: boolean } | null>, addons: Array<{ __typename?: 'CartItemAddon', id: number, addon: { __typename?: 'Addon', price: number } } | null> } | null> } | null } | null };

export type CartQueryVariables = Exact<{ [key: string]: never; }>;


export type CartQuery = { __typename?: 'Query', cart: { __typename?: 'Cart', id: number, price: number, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, price: number, product: { __typename?: 'Product', name: string, price: number, weight?: number | null, id: number, image?: { __typename?: 'Image', path: string } | null, addons?: Array<{ __typename?: 'Addon', id: number } | null> | null }, options: Array<{ __typename?: 'CartItemOption', id: number, value: boolean, option: { __typename?: 'Option', id: number, name: string } } | null>, addons: Array<{ __typename?: 'CartItemAddon', id: number, addon: { __typename?: 'Addon', name: string, price: number, id: number } } | null> } | null> } };

export type List_UsersQueryVariables = Exact<{
  input?: InputMaybe<ListUsersFilterInput>;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type List_UsersQuery = { __typename?: 'Query', listUsers: { __typename?: 'UserPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number }, data: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string, email: string, date: any, birthdate?: any | null, isAdmin: boolean, active: boolean }> } };

export type Save_AddressMutationVariables = Exact<{
  input: AddressInput;
}>;


export type Save_AddressMutation = { __typename?: 'Mutation', saveAddress: { __typename?: 'Address', id: number, address: string, default: boolean, phone?: string | null, mobile?: string | null, date: any } };

export type Delete_AddressMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type Delete_AddressMutation = { __typename?: 'Mutation', deleteAddress: boolean };

export type Set_Address_DefaultMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type Set_Address_DefaultMutation = { __typename?: 'Mutation', setAddressDefault: { __typename?: 'Address', id: number } };

export type Delete_Cart_Item_AddonMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type Delete_Cart_Item_AddonMutation = { __typename?: 'Mutation', deleteCartItemAddon: boolean };
