import CustomDialog from '../../../Components/MUI/CustomDialog'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSave_CategoryMutation } from '../../../graphql/mutations/products.generated'
import { CategoryInput } from '../../../graphql/types'
import { Stack, Typography } from '@mui/material'
import ControlMUITextField from '../../../Components/MUI/ControlMUItextField'
import MuiSwitch from '../../../Components/MUI/MuiSwitch'
import UploadFile from '../../../Components/MUI/UploadFile'
import { setValidationError } from '../../../Components/helperFunctions/setValidationError'
import { DataByIdType } from './CategoriesList'
import MuiSelect from '../../../Components/MUI/MuiSelect'
import { useList_Categories_DropdownQuery } from '../../../graphql/queries/products.generated'
import { toast } from 'sonner'

interface PropsType {
    open: boolean
    handleClose: () => void
    dataById?: DataByIdType
    refetch: () => void
}

const CategoriesCreate = ({
    open,
    handleClose,
    dataById,
    refetch
}: PropsType) => {

    const { t } = useTranslation()
    const { control, handleSubmit, setValue, setError } = useForm<CategoryInput>({
        defaultValues: {
            active: true,
            ...dataById
        }
    })
    const [saveCategoryMutation, { loading }] = useSave_CategoryMutation();

    const onSubmit = (data: CategoryInput) => {
        const image = data.image

        saveCategoryMutation({
            variables: {
                input: {
                    ...(dataById && { id: dataById.id }),
                    name: data.name,
                    active: data.active,
                    parentId: data.parentId,
                    ...(image && {
                        image: {
                            image: image
                        }
                    })
                }
            }
        }).then(() => {
            dataById ? toast.success(t("updatedSuccessfully")) : toast.success(t("savedSuccessfully"))
            handleClose()
            refetch()
        }).catch(({ graphQLErrors }) => {
            setValidationError({
                setError,
                graphQLErrors
            })
        })
    }

    const { data: categoriesDropdownData } = useList_Categories_DropdownQuery({
        variables: {
            input: {
                parentId: null
            }
        }
    });

    return (
        <CustomDialog
            open={open}
            handleClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
                noValidate: true
            }}
            title={
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography>{t(dataById ? "update" : "new")}</Typography>
                    <MuiSwitch
                        edge="end"
                        name="active"
                        label={t("active")}
                        control={control}
                    />
                </Stack>
            }
            content={
                <Stack spacing={2}>
                    <ControlMUITextField
                        control={control}
                        name='name'
                        label={t("name")}
                        rules={{
                            required: t("fieldIsRequired"),
                        }}
                    />
                    <MuiSelect
                        name='parentId'
                        label={t('kitchen')}
                        control={control}
                        variant='filled'
                        data={!categoriesDropdownData ? [] : categoriesDropdownData?.listCategoriesDropdown.map((item) => ({
                            key: item?.name!,
                            value: item?.id!,
                        }))}
                        rules={{
                            required: t("fieldIsRequired"),
                        }}
                    />
                    <UploadFile
                        control={control}
                        setValue={setValue}
                        name="image"
                        icon={"add_photo_alternate"}
                        label={t("image")}
                        accept=".png,.jpg,.jpeg,.webp"
                        maxSize={1000 * 1024}
                    />
                </Stack>
            }
            buttonAction={
                <LoadingButton loading={loading} type='submit' variant='contained'>{t("submit")}</LoadingButton>
            }
        />
    )
}

export default CategoriesCreate