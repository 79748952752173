import moment from 'moment';
import CustomDialog from '../../../../Components/MUI/CustomDialog'
import { useListAppointmentsQuery } from '../../../../graphql/queries/branches.generated';
import { Stack, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { FixedTableCell } from '../../Layouts/FixedTableCell';
import { TimesTypes } from './TablesTable';
import { useTranslation } from 'react-i18next';
import BooleanCell from '../../../../Components/MUI/BooleanCell';
import DatePickerValue from '../../../../Components/MUI/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

export interface FiltersTypes {
    branchId?: number
    tableId?: number
}

const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");
const dateFormat = (date: any) => moment(date).locale("en").format("YYYY-MM-DD");

const Appointments = ({
    open,
    handleClose,
    filters,
    times
}: {
    handleClose: () => void,
    open: boolean,
    filters: FiltersTypes
    times: TimesTypes[]
}) => {
    const { t } = useTranslation()
    const [value, setValue] = useState<Dayjs | null>(dayjs(initDate));

    console.log(dateFormat(new Date(value as any)));
    

    const { data } = useListAppointmentsQuery({
        fetchPolicy:"no-cache",
        variables: {
            input: {
                appointmentDate: dateFormat(new Date(value as any)),
                ...filters
            },
            first: 100,
            page: 1
        },
    });


    return (
        <CustomDialog
            open={open}
            handleClose={handleClose}
            title={
                <Stack alignItems={"center"}>
                    <DatePickerValue
                        value={value}
                        setValue={setValue}
                        label={t("date")}
                    />
                </Stack>
            }
            maxWidth='sm'
            content={
                <TableContainer sx={{ width: "100%", overflow: "auto" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <FixedTableCell align={'left'} >
                                    -
                                </FixedTableCell>
                                <FixedTableCell align={'left'} >
                                    {t("fromTime")}
                                </FixedTableCell>
                                <FixedTableCell align={'left'} >
                                    {t("toTime")}
                                </FixedTableCell>
                                <FixedTableCell align={'left'} >
                                    {t("reserved")}
                                </FixedTableCell>
                                <FixedTableCell align={'left'} >
                                    {t("reservationUnder")}
                                </FixedTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {times.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                                        <FixedTableCell>
                                            {row?.id}
                                        </FixedTableCell>
                                        <FixedTableCell align={'left'}>
                                            {row?.fromTime}
                                        </FixedTableCell>
                                        <FixedTableCell align={'left'}>
                                            {row?.toTime}
                                        </FixedTableCell>
                                        <FixedTableCell align={'left'}>
                                            <BooleanCell value={!!data?.listAppointments.data.find(e => e.tableTime.id === row.id)} />
                                        </FixedTableCell>
                                        <FixedTableCell align={'left'}>
                                            {data?.listAppointments.data.find(e => e.tableTime.id === row.id)?.user.firstName}
                                        </FixedTableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        />
    )
}

export default Appointments