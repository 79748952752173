import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Stack } from "@mui/material";
import ControlMUITextField from "../MUI/ControlMUItextField";
import CustomDialog from "../MUI/CustomDialog";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { ChangePasswordInput } from "../../graphql/types";
import { useChange_PasswordMutation } from "../../graphql/mutations/user.generated";
import { setValidationError } from "../helperFunctions/setValidationError";
import { toast } from "sonner";

type PassType = {
    [key: string]: string;  // Each key is a string and its value is a string (e.g., "text")
};

const ChangePassword = () => {
    const { t } = useTranslation()
    const [passType, setPassType] = useState<PassType>({
        oldPassword: "password",
        newPassword: "password"
    })
    const [changePassword, setChangePassword] = useState(false)


    const handleOpenChangePassword = () => {
        setChangePassword(true)
    }
    const handleCloseChangePassword = () => {
        setChangePassword(false)
    }

    const { handleSubmit, control, setError, reset } = useForm<ChangePasswordInput>()

    const [changePasswordMutation, { loading }] = useChange_PasswordMutation()

    const onSubmit = (data: ChangePasswordInput) => {
        changePasswordMutation({
            variables: {
                input: {
                    ...data
                }
            }
        }).then(() => {
            reset()
            toast.success(t("passwordChanged"))
            handleCloseChangePassword()
        }).catch(({ graphQLErrors }) => {
            setValidationError({
                graphQLErrors,
                setError
            })
        })
    }

    const changePassType = (type: string) => {
        setPassType(prev => ({
            ...prev,
            [type]: prev[type] === "password" ? "text" : "password"
        }))
    }


    return (
        <>
            <Button
                size="small"
                variant="outlined"
                onClick={handleOpenChangePassword}
                color="info"
                endIcon={<Lock fontSize="small" />}
            >
                {t("changePassword")}
            </Button>
            {changePassword && <CustomDialog
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                    noValidate: true
                }}
                open={changePassword}
                handleClose={handleCloseChangePassword}
                title={t("changePassword")}
                content={
                    <Stack spacing={2}>
                        <ControlMUITextField
                            name='old'
                            label={t('oldPassword')}
                            type={passType.oldPassword}
                            control={control}
                            rules={{
                                required: t("fieldIsRequired"),
                            }}
                            slotProps={{
                                input: {
                                    endAdornment:
                                        <IconButton size='small' onClick={() => changePassType("oldPassword")}>
                                            {passType.oldPassword === "password" && <VisibilityOff color='primary' fontSize='inherit' />}
                                            {passType.oldPassword === "text" && <Visibility color='primary' fontSize='inherit' />}
                                        </IconButton>
                                }
                            }}
                        />
                        <ControlMUITextField
                            name='new'
                            label={t('newPassword')}
                            type={passType.newPassword}
                            control={control}
                            rules={{
                                required: t("fieldIsRequired"),
                            }}
                            slotProps={{
                                input: {
                                    endAdornment: <IconButton size='small' onClick={() => changePassType("newPassword")}>
                                        {passType.newPassword === "password" && <VisibilityOff color='primary' fontSize='inherit' />}
                                        {passType.newPassword === "text" && <Visibility color='primary' fontSize='inherit' />}
                                    </IconButton>
                                }
                            }}
                        />
                    </Stack>
                }
                buttonAction={
                    <LoadingButton
                        loading={loading}
                        variant='contained'
                        type='submit'
                    >
                        {t("submit")}
                    </LoadingButton>
                }
            />}
        </>
    )
}

export default ChangePassword