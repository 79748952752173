import React from 'react'
import CustomDialog from '../../../Components/MUI/CustomDialog'
import { Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import ControlMUITextField from '../../../Components/MUI/ControlMUItextField'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../Store/store'
import { UserInput } from '../../../graphql/types'
import { LoadingButton } from '@mui/lab'
import { useUpdate_UserMutation } from '../../../graphql/mutations/user.generated'
import { changeUser } from '../../../Store/slices/auth'

const UpdateProfile = ({
    open,
    handleClose
}: {
    open: boolean
    handleClose: () => void
}) => {
    const { t } = useTranslation()
    const { user } = useAppSelector((state) => state.auth);
    const { handleSubmit, control } = useForm<UserInput>({
        defaultValues: {
            firstName: user?.firstName,
            lastName: user?.lastName
        }
    })

    const [updateUserMutation, { loading }] = useUpdate_UserMutation()
    const dispatch = useAppDispatch()

    const onSubmit = (data: UserInput) => {
        updateUserMutation({
            variables: {
                input: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    birthdate: user?.birthdate,
                    mobile: user?.mobile,
                }
            }
        }).then(() => {
            dispatch(changeUser({
                birthdate: user?.birthdate!,
                email: user?.email!,
                firstName: data.firstName!,
                lastName: data.lastName!,
                image: user?.image,
                mobile: user?.mobile
            }))
            handleClose()
        }).catch(() => console.log("err"))
    }

    return (
        <CustomDialog
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
                noValidate: true
            }}
            open={open}
            handleClose={handleClose}
            title={t("updateProfile")}
            content={
                <Stack spacing={2}>
                    <ControlMUITextField
                        control={control}
                        name='firstName'
                        label={t("firstName")}
                        rules={{
                            required: t("fieldIsRequired"),
                        }}
                    />
                    <ControlMUITextField
                        control={control}
                        name='lastName'
                        label={t("lastName")}
                        rules={{
                            required: t("fieldIsRequired"),
                        }}
                    />
                </Stack>
            }
            buttonAction={
                <LoadingButton
                    loading={loading}
                    variant='contained'
                    type='submit'
                >
                    {t("submit")}
                </LoadingButton>
            }
        />
    )
}

export default UpdateProfile