import { Box, Container, Divider, Grid2 as Grid, IconButton, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import BreadCrumb from "../../../Components/BreadCrumb";
import notRestaurant from "../../../assets/images/not-restaurant.svg";
import { useParams } from "react-router-dom";
import { useProduct_By_IdQuery } from "../../../graphql/queries/products.generated";
import { getBackendUri } from "../../../Components/helperFunctions/getBackendUri";
import DangerouslySetInnerHTML from "../../../Components/DangerouslySetInnerHTML";
import { useTranslation } from "react-i18next";
import TextFieldElement from "../../../Components/MUI/TextFieldElement";
import { useFieldArray, useForm } from "react-hook-form";
import { FaMinus, FaPlus } from "react-icons/fa";
import MuiSwitch from "../../../Components/MUI/MuiSwitch";
import { InputMaybe, OptionInput } from "../../../graphql/types";
import MuiCheckBox from "../../../Components/MUI/MuiCheckBox";
import { Fragment, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Store/store";
import { useSave_Cart_ItemMutation } from "../../../graphql/mutations/user.generated";
import { formatted, slugify } from "../../../Components/helperFunctions/function";
import { ROLE } from "../../../types";
import { LoadingButton } from "@mui/lab";
import { changeCart } from "../../../Store/slices/cart";
import { Forward } from "@mui/icons-material";
import RecommendedComponent from "./RecommendedComponent";
import useWidth, { isWidthDown } from "../../../Components/helperFunctions/useWidth";

const PREFIX = "product-view";

const classes = {
    paymentWrapper: `${PREFIX}-paymentWrapper`,
    addonsPrice: `${PREFIX}-addonsPrice`,
    gridView: `${PREFIX}-gridView`,
    iconButton: `${PREFIX}-iconButton`,
};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.paymentWrapper}`]: {
        [`& p`]: {
            fontSize: 16
        },
    },
    [`& .${classes.addonsPrice}`]: {
        direction: theme.direction
    },
    [`& .${classes.iconButton}`]: {
        border: `1px solid ${theme.palette.primary.main}`
    },
    [`& .${classes.gridView}`]: {
        display: "grid",
        gridAutoFlow: "column", /* Forces items to be laid out in a single row */
        gridGap: "10px", /* Optional: Adds spacing between items */
        overflowX: "scroll", /* Allows horizontal scrolling if items overflow */
        whiteSpace: "nowrap",
        width: "100%",
        paddingBottom: theme.spacing(1),
        MsOverflowStyle: "none", /* Internet Explorer 10+ */
        "&::-webkit-scrollbar": {
            display: "none"
        },
        justifyContent: "start"
    },
}));


type ProductInput = {
    productId?: number
    quantity: number
    options?: InputMaybe<Array<InputMaybe<OptionInput>>>;
    addons?: any;
};

const ProductView = ({
    id,
}: { id?: number }) => {
    const { productId } = useParams()
    const { t, i18n } = useTranslation()
    const { isLoggedIn, role } = useAppSelector((state) => state.auth);
    const { value } = useAppSelector((state) => state.cart);
    const dispatch = useAppDispatch()
    const [saveCartItemMutation, { loading: saveCartItemLoading }] = useSave_Cart_ItemMutation();
    const screenWidth = useWidth();
    const isScreenSmall = isWidthDown("sm", screenWidth);

    const gridRef = useRef<HTMLDivElement | null>(null); // Explicitly type the ref

    const scrollRight = () => {
        if (gridRef.current) {
            gridRef.current.scrollBy({ left: 150, behavior: 'smooth' }); // Safe usage of scrollBy
        }
    };

    const scrollLeft = () => {
        if (gridRef.current) {
            gridRef.current.scrollBy({ left: -150, behavior: 'smooth' }); // Safe usage of scrollBy
        }
    };

    const { control, watch, setValue, handleSubmit } = useForm<ProductInput>({
        defaultValues: {
            quantity: 1
        }
    })

    const [addonsPrice, setAddonsPrice] = useState(0)

    const { data, loading } = useProduct_By_IdQuery({
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        variables: {
            id: id ? id : parseInt(productId!)
        },
        onCompleted: () => {
        },
    });

    useEffect(() => {
        setValue("options", data?.product?.options)
        return () => { }
    }, [data, setValue])

    const prevLink = [
        {
            name: data?.product.category.name!,
            link: `kitchens/${data?.product.category.id}/${slugify(data?.product.category.name! ?? "")}`
        },
        {
            name: data?.product.subCategory.name!,
            link: `categories/${data?.product.subCategory.id}/${slugify(data?.product.subCategory.name! ?? "")}`
        },
    ]

    const { fields } = useFieldArray({
        control,
        name: "options",
    });

    const onSubmit = (submitData: ProductInput) => {
        const result = data?.product.addons?.filter(item => submitData.addons.includes(item?.id));

        if (isLoggedIn) {
            saveCartItemMutation({
                variables: {
                    input: {
                        addons: result?.map(e => ({ addonId: e?.id! })),
                        options: submitData.options
                            ? submitData.options
                                .map((e) => e && e.id !== undefined ? {
                                    optionId: e.id,
                                    value: e.value
                                } : null)
                                .filter((e): e is { optionId: number; value: boolean } => e !== null) // Ensure correct type
                            : [],
                        productId: id ? id : Number(productId),
                        quantity: submitData.quantity
                    }
                }
            }).then((data) => {
                dispatch(changeCart({
                    stateCartDrawer: true,
                    count: value + 1
                }))
            }).catch(({ graphQLErrors }) => {
                console.log(graphQLErrors);
            })
        } else {
            if (localStorage.getItem("cart")) {
                console.log(JSON.parse(localStorage.getItem("cart")!));
            } else {
                submitData.productId = Number(productId);
                localStorage.setItem("cart", JSON.stringify(submitData));
            }
        }
    };

    const handleChangeAddons = () => {
        const addons = watch("addons")
        if (data?.product.addons) {
            const totalPrice = data?.product.addons
                .filter(addon => addons.includes(addon?.id)) // Filter addons that are selected
                .reduce((sum, addon) => sum + addon?.price!, 0); // Sum their prices
            setAddonsPrice(totalPrice)
        }
    }

    return (
        <Root>
            {!id && <BreadCrumb prevLink={prevLink} pageLink={data?.product.name!} />}

            <Container maxWidth="lg">
                <Grid container spacing={5} m={0} my={3}>
                    <Grid size={{ md: 6, xs: 12 }}>
                        <Stack spacing={2} alignItems={"center"} width={"100%"}>
                            <Stack height={{ md: 350, sm: 200 }} component={Paper} elevation={4} width={"100%"}>
                                {loading ?
                                    <Skeleton variant="rounded" animation="wave" width={"100%"} height={"100%"} />
                                    :
                                    <img
                                        src={data?.product.image?.path ? getBackendUri(data?.product.image?.path!) : notRestaurant}
                                        alt={data?.product.name}
                                        width={"100%"}
                                        height={"100%"}
                                        style={{ objectFit: "fill", borderRadius: "8px" }}
                                    />
                                }
                            </Stack>
                            {data?.product.recommendations?.length! > 0 && !id && <Stack width={"100%"} alignItems={"center"} spacing={2}>
                                <Typography fontSize={20}>{t("suggestionsWithMeal")}</Typography>
                                <Box
                                    className={classes.gridView}
                                    ref={gridRef}
                                >
                                    {data?.product.recommendations?.map(e =>
                                        <RecommendedComponent
                                            key={e?.id}
                                            item={{
                                                id: e?.recommended.id!,
                                                name: e?.recommended.name!,
                                                price: e?.recommended.price!,
                                                image: e?.recommended.image?.path
                                            }} />
                                    )}
                                </Box>
                                {!isScreenSmall &&
                                    <Stack direction={i18n.dir() === "rtl" ? "row-reverse" : "row"} spacing={2}>
                                        <IconButton onClick={scrollLeft} className={classes.iconButton}>
                                            <Forward sx={{ transform: "rotate(180deg)" }} color="primary" />
                                        </IconButton>
                                        <IconButton onClick={scrollRight} className={classes.iconButton}>
                                            <Forward color="primary" />
                                        </IconButton>
                                    </Stack>
                                }
                            </Stack>}
                        </Stack>
                    </Grid>
                    <Grid size={{ md: 6, xs: 12 }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={3}>
                                <Stack>
                                    <Typography color="primary.main">
                                        {t("name")}:
                                    </Typography>
                                    <Typography variant="h1" fontSize={30}>
                                        {loading ? <Skeleton animation="wave" width={"80px"} /> : data?.product.name}
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography color="primary.main">
                                        {t("description")}:
                                    </Typography>

                                    {loading ?
                                        <Stack >
                                            <Skeleton animation="wave" width={"100%"} />
                                            <Skeleton animation="wave" width={"100%"} />
                                            <Skeleton animation="wave" width={"200px"} />
                                            <Skeleton animation="wave" width={"200px"} />
                                            <Skeleton animation="wave" width={"200px"} />
                                            <Skeleton animation="wave" width={"80px"} />
                                            <Skeleton animation="wave" width={"80px"} />
                                            <Skeleton animation="wave" width={"80px"} />
                                        </Stack>
                                        :
                                        <DangerouslySetInnerHTML data={data?.product.description!} />
                                    }
                                </Stack>
                                <Stack>
                                    <Typography color="primary.main">
                                        {t("price")}:
                                    </Typography>
                                    {loading ?
                                        <Skeleton animation="wave" width={"60px"} />
                                        : <Typography fontSize={18}>
                                            {data?.product?.price ? formatted(data?.product?.price!) : ""}
                                        </Typography>}
                                </Stack>
                                <Divider />
                                {fields.length > 0 &&
                                    <Stack spacing={1}>
                                        <Typography color="primary.main">
                                            {t("options")}:
                                        </Typography>
                                        <Stack direction={"row"} spacing={2} useFlexGap flexWrap={"wrap"}>
                                            {fields.map((item, index) =>
                                                <Fragment key={index}>
                                                    <Stack>
                                                        <Typography>
                                                            {item?.name}
                                                        </Typography>
                                                        <Stack>
                                                            <MuiSwitch
                                                                edge="end"
                                                                name={`options.${index}.value`}
                                                                label={""}
                                                                control={control}
                                                            // onChange={(e) => changeOptionValue(e, item)}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                    {(index !== fields.length - 1) && <Divider orientation="vertical" flexItem />}
                                                </Fragment>
                                            )}
                                        </Stack>
                                    </Stack>
                                }
                                {data?.product.addons && data?.product.addons.length > 0 && <Stack>
                                    <Typography color="primary.main">
                                        {t("addons")}:
                                    </Typography>
                                    <Stack>
                                        <MuiCheckBox
                                            control={control}
                                            name="addons"
                                            label=""
                                            data={data?.product.addons.map((ele) => ({
                                                value: ele?.id!,
                                                key: <Stack direction={"row"} spacing={1}>
                                                    <Typography>{ele?.name}</Typography>
                                                    <Typography fontWeight={"bolder"}>+ ( {formatted(ele?.price!)} )</Typography>
                                                    <Typography>x</Typography>
                                                    <Typography>{Number(watch("quantity"))}</Typography>
                                                    {/* <Box component={"span"}>x</Box> {Number(watch("quantity"))} */}
                                                </Stack>
                                            }))}
                                            onChanges={handleChangeAddons}
                                        />
                                    </Stack>
                                </Stack>}
                                {!!data?.product.weight && <Stack>
                                    <Typography color="primary.main">
                                        {t("minWeight")}:
                                    </Typography>
                                    <Typography fontSize={18}>
                                        {data?.product.weight} {t("gram")}
                                    </Typography>
                                </Stack>}
                                <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"space-between"}>
                                    {loading ?
                                        <Skeleton width={150} height={60} /> :
                                        <TextFieldElement
                                            name='quantity'
                                            control={control}
                                            // disabled={entriesBeingDeleted.includes(item.id!)}
                                            label={t("quantity")}
                                            type='number'
                                            value={watch("quantity") || 1}
                                            sx={{ width: '20ch', "& .MuiInputBase-input": { textAlign: "center" } }}
                                            onChange={(e) => {
                                                if (parseInt(e.target.value) < 1 || e.target.value === '')
                                                    setValue("quantity", 1)
                                                if (parseInt(e.target.value) > 100) {
                                                    setValue("quantity", 100)
                                                }
                                            }}
                                            variant='outlined'
                                            inputSlotProps={{
                                                startAdornment: <IconButton
                                                    onClick={() => setValue("quantity", Number(watch("quantity")) - 1 < 1 ? 1 : Number(watch("quantity")) - 1)}
                                                // disabled={cartUpdateLoading || item.quantity <= 1 || entriesBeingDeleted.includes(item.id!)}
                                                // onClick={() => decreaseQuantity(item.id, item.quantity)}
                                                >
                                                    <FaMinus fontSize={15} />
                                                </IconButton>,
                                                endAdornment: <IconButton
                                                    onClick={() => setValue("quantity", Number(watch("quantity")) + 1)}
                                                // disabled={cartUpdateLoading || item.quantity === item.totalQuantity}
                                                // onClick={() => increaseQuantity(item.id, item.quantity, item.totalQuantity)}
                                                >
                                                    <FaPlus fontSize={15} />
                                                </IconButton>,

                                            }}
                                        />
                                    }
                                    <Stack>
                                        <Typography color="primary.main">
                                            {t("totalPrice")}:
                                        </Typography>
                                        {loading ?
                                            <Skeleton animation="wave" width={"60px"} />
                                            : <Typography fontSize={18}>
                                                {formatted((Number(watch("quantity")) * data?.product?.price!) + (addonsPrice * Number(watch("quantity"))))}
                                            </Typography>}
                                    </Stack>
                                </Stack>
                                {role === ROLE.CUSTOMER && isLoggedIn &&
                                    <LoadingButton loading={saveCartItemLoading} type="submit" variant="contained">
                                        {t("addToCart")}
                                    </LoadingButton>
                                }
                            </Stack>
                        </form>
                    </Grid>
                </Grid>
            </Container>

            {/* <BreadCrumb /> */}
        </Root>
    )
}

export default ProductView


// <Grid container spacing={2} m={0}>
// {data?.product.recommendations?.map(e =>
//     <Grid size={{ xs: 6, sm: 4 }} key={e?.id}>
//         <Stack alignItems={"center"} spacing={0.5}>
//             <Avatar sx={{ width: "90px", height: "90px", background: "transparent" }}>
//                 <img
//                     src={e?.recommended.image?.path ? getBackendUri(e?.recommended.image?.path) : notRestaurant}
//                     alt={e?.recommended.name}
//                     width={"100%"}
//                     height={"100%"}
//                     style={{ objectFit: "fill", borderRadius: "8px" }}
//                 />
//             </Avatar>
//             <Typography textAlign={"center"}>{e?.recommended.name}</Typography>
//             <Typography>{formatted(e?.recommended.price)}</Typography>
//             <Button variant="contained" size="small">{t("add")}</Button>
//         </Stack>
//     </Grid>
// )}
// </Grid>