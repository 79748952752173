export const ROLE = {
    CUSTOMER: "CUSTOMER",
    ADMIN: "ADMIN",
    SUPER: "SUPER"
}

export enum DeliveryTypeNames {
    DLV = 'delivery',
    RIB = 'receiveInBranch',
    TRS = 'tableReservation'
}

export enum PaymentStatusTypeNames {
    PAID = 'paid',
    PENDING = 'pending',
    REFUNDING = 'refunding',
    REFUNDED = 'refunded'
}

export enum paymentTypeNames {
    COD = 'cashOnDeliver',
    STRIPE = 'stripe'
}