import React, { useEffect, useState } from 'react'
import { useCreate_Payment_IntentMutation } from '../../graphql/mutations/orders.generated';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Container, Stack } from '@mui/material';
import CheckoutForm from './Components/PaymentForm';
import Loading from '../../Components/Loading';
import { useAppSelector } from '../../Store/store';
import { useSettingsQuery } from '../../graphql/queries/settings.generated';

const CheckoutPayment = () => {
    const { orderId } = useParams()
    const location = useLocation();

    // Extract token from URL query string
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const { data, loading: settingLoading } = useSettingsQuery({
        ...(token && {
            context: {
                headers: {
                    authorization: token ? `Bearer ${token}` : "",
                },
            }
        }),
        skip: !token
    });

    const { settings } = useAppSelector((e) => e.auth)
    const stripePromise = loadStripe(settings.stripe.publicKey || data?.settings.stripe?.publicKey!);


    const navigate = useNavigate()
    const [createPaymentIntentMutation, { loading }] = useCreate_Payment_IntentMutation();

    const [clientSecret, setClientSecret] = useState<string | null>(null)
    const [customerSessionClientSecret, setCustomerSessionClientSecret] = useState<string | null>(null)
    const [loadingStripe, setLoadingStripe] = useState<boolean>(true)

    useEffect(() => {
        setTimeout(() => {
            setLoadingStripe(false)
        }, 5000);
    }, [])


    useEffect(() => {
        createPaymentIntentMutation({
            ...(token && {
                context: {
                    headers: {
                        authorization: token ? `Bearer ${token}` : "",
                    },
                }
            }),
            variables: {
                input: {
                    orderId: parseInt(orderId!)
                }
            }
        }).then((data) => {
            if (!data.data?.createPaymentIntent.clientSecret) {
                navigate(token ? "/payment-mobile-done" : "/")
            }
            setClientSecret(data.data?.createPaymentIntent.clientSecret!)
            setCustomerSessionClientSecret(data.data?.createPaymentIntent.customerClientSecret!)
        }).catch(({ graphQLErrors }) => console.log(graphQLErrors))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret!,
        customerSessionClientSecret: customerSessionClientSecret ?? undefined
    };

    return (loading || loadingStripe || settingLoading) ? <Loading height='200px' /> : !clientSecret ? "null" : (
        <Container maxWidth="md">
            <Stack my={5}>
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm orderId={orderId} />
                </Elements>
                <Stack>
                    {/* {error?.message} */}
                </Stack>
            </Stack>
        </Container>
    )
}

export default CheckoutPayment