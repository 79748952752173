import { Alert, Box, Container, Divider, FormControl, FormControlLabel, FormLabel, Grid2 as Grid, Paper, Radio, RadioGroup, Skeleton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { formatted, hexToRgb } from "../../Components/helperFunctions/function";

import noImage from "../../assets/images/no-image-svgrepo-com.svg"
import { useState } from "react";
import clsx from "clsx";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { MdOutlinePayment, MdOutlineTableRestaurant } from "react-icons/md";
import { GiPaperBagOpen } from "react-icons/gi";
import { TbTruckDelivery } from "react-icons/tb";
import { useAppDispatch, useAppSelector } from "../../Store/store";
import DeliveryStack from "./Components/DeliveryStack";
import { Controller, useForm } from "react-hook-form";
import BranchStack from "./Components/BranchStack";
import { getBackendUri } from "../../Components/helperFunctions/getBackendUri";
import ControlMUITextField from "../../Components/MUI/ControlMUItextField";
import { useCreate_OrderMutation } from "../../graphql/mutations/orders.generated";
import { DeliveryTypeCode, OrderInput, PaymentTypeCode } from "../../graphql/types";
import moment from "moment";
import { setValidationError } from "../../Components/helperFunctions/setValidationError";
import { toast } from "sonner";
import { LoadingButton } from "@mui/lab";
import AddressDialog from "./Components/AddressDialog";
import { useNavigate } from "react-router-dom";
import { changeCartCount } from "../../Store/slices/cart";
import useCartData from "../../Components/Hooks/useCartData";
import { BsCashCoin } from "react-icons/bs";
import { useCalculate_Order_FeesQuery } from "../../graphql/queries/orders.generated";
import MUIDateTime from "../../Components/MUI/MuiDateTime";

const PREFIX = "checkout";

const classes = {
    imageWrapper: `${PREFIX}-imageWrapper`,
    cartWrapper: `${PREFIX}-cartWrapper`,
    radioStyle: `${PREFIX}-radioStyle`,
    radioStyleActive: `${PREFIX}-radioStyleActive`,
    radioAddressStyle: `${PREFIX}-radioAddressStyle`,
    image: `${PREFIX}-image`,
    leftWrapper: `${PREFIX}-leftWrapper`,
    mdNone: `${PREFIX}-mdNone`,
    divider: `${PREFIX}-divider`,
};

export const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    textTransform: "capitalize",
    color: theme.palette.text.secondary
}))

const dateFormat = (date: string) => moment(date).locale("en").format("YYYY-MM-DD");
const dateTimeFormat = (date: string) => moment(date).locale("en").format("YYYY-MM-DD HH:MM:SS");

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.imageWrapper}`]: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "8px",
        overflow: "hidden",
        padding: "2px"
    },
    [`& .${classes.cartWrapper}`]: {
        overflowY: "scroll",
        MsOverflowStyle: "none",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },

    [`& .${classes.radioStyle}`]: {
        display: "flex",
        height: 56,
        width: "100%",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "10px",
        padding: theme.spacing(0, 2),
        margin: 0,
        "& span": {
            width: "100%",
        }
    },
    [`& .${classes.radioAddressStyle}`]: {
        display: "flex",
        width: "100%",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "10px",
        margin: 0,
        ".MuiFormControlLabel-label": {
            width: "100%",
        }
    },
    [`& .${classes.radioStyleActive}`]: {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        backgroundColor: `rgba(${hexToRgb(theme.palette.primary.main)}, 0.1)`, // Adding 0.5 opacity
    },
    [`& .${classes.image}`]: {
        objectFit: "cover",
        borderRadius: "5px",
    },
    [`& .${classes.leftWrapper}`]: {
        borderLeft: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down("md")]: {
            borderLeft: `none`,
        },
    },
    [`& .${classes.mdNone}`]: {
        [theme.breakpoints.down("md")]: {
            display: `none`,
        },
    },
    [`& .${classes.divider}`]: {
        background: theme.palette.divider
    },

}));


const TypesOfCheckout = (t: any) => [
    {
        value: DeliveryTypeCode.Dlv,
        icon: <TbTruckDelivery size={22} />,
        title: t("delivery")
    },
    {
        value: DeliveryTypeCode.Rib,
        icon: <GiPaperBagOpen size={22} />,
        title: t("receiveInBranch")
    },
    {
        value: DeliveryTypeCode.Trs,
        icon: <MdOutlineTableRestaurant size={22} />,
        title: t("reserveTable")
    },
]

const TypesOfPayment = (t: any) => [
    {
        value: PaymentTypeCode.Cod,
        icon: <BsCashCoin size={22} />,
        title: t("cashOnDeliver")
    },
    {
        value: PaymentTypeCode.Stripe,
        icon: <MdOutlinePayment size={22} />,
        title: t("stripe")
    },
]

const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: "center" });
    }
};

const isTimeBeforeNow = (timeString: string) => {
    const now = new Date(); // Current date and time
    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    // Create a new Date object with the current date and the given time
    const inputTime = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        hours,
        minutes,
        seconds
    );

    return inputTime < now; // Compare the two Date objects
};

const Checkout = () => {
    const { t } = useTranslation()
    const { addresses, settings } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { control, handleSubmit, watch, setError, setValue, clearErrors } = useForm<OrderInput>({
        defaultValues: {
            deliveryType: DeliveryTypeCode.Dlv,
            paymentType: PaymentTypeCode.Cod,
            addressId: addresses.find((e) => e.default)?.id || null,
        },
    })

    const { data, loading: cartLoading } = useCartData()

    const [createOrderMutation, { loading: createOrderLoading }] = useCreate_OrderMutation();

    const [addressError, setAddressError] = useState<string | null>(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }

    const handleAddNew = (id: number) => {
        setValue("addressId", id)
    }

    const DELIVERY_STACK =
        <DeliveryStack
            classes={classes}
            addressError={addressError}
            control={control}
            handleOpen={handleOpen}
        />

    const BRANCH =
        <BranchStack
            control={control}
            watch={watch}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
        />

    const onSubmit = (data: OrderInput) => {
        if (data.deliveryType === DeliveryTypeCode.Dlv && !data.addressId) {
            setAddressError(t("mustAddAddress"))
            scrollToSection("Delivery")
            return
        }
        const deliveryDate = dateTimeFormat(data.deliveryDate)
        const paymentType = data.paymentType

        createOrderMutation({
            variables: {
                input: {
                    deliveryType: data.deliveryType,
                    paymentType: data.paymentType,
                    ...([DeliveryTypeCode.Rib, DeliveryTypeCode.Dlv].includes(data.deliveryType) && { deliveryDate: deliveryDate }),
                    ...(data.note && { note: data.note }),
                    ...(data.deliveryType === DeliveryTypeCode.Dlv && { addressId: +data.addressId! }),
                    ...([DeliveryTypeCode.Rib, DeliveryTypeCode.Trs].includes(data.deliveryType) && { branchId: data.branchId }),
                    ...(data.deliveryType === DeliveryTypeCode.Trs && { tableTimeId: data.tableTimeId }),
                    ...(data.deliveryType === DeliveryTypeCode.Trs && { appointmentDate: dateFormat(data.appointmentDate) }),
                }
            }
        }).then((data) => {
            dispatch(changeCartCount(0))
            toast.success(t("orderCreated"))
            if (paymentType === PaymentTypeCode.Stripe) {
                // window.location.href = `/checkout/payment/${data.data?.createOrder.id}`
                navigate(`payment/${data.data?.createOrder.id}`, { replace: true })
                return
            }
            if (data.data?.createOrder.id) {
                navigate(`/profile/orders/${data.data?.createOrder.id}`, { replace: true })
            }
        }).catch(({ graphQLErrors }) => {
            console.log(graphQLErrors[0]);

            setValidationError({
                graphQLErrors,
                setError
            })
        })
    };

    const noCart = data?.cart.items.length === 0

    const { data: orderFeesData, loading } = useCalculate_Order_FeesQuery({
        fetchPolicy: "no-cache",
        variables: {
            input: {
                includeDeliveryFees: watch("deliveryType") === DeliveryTypeCode.Dlv,
                deliveryType: watch("deliveryType")
            }
        },
    });

    return (
        <Root>
            {open && <AddressDialog open={open} handleClose={handleClose} handleAddNew={handleAddNew} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container maxWidth="lg">
                    <Grid container m={0} height={"100%"}>
                        <Grid size={{ md: 6, xs: 12 }}>
                            <Stack height={80} justifyContent={"center"}>
                                <Typography variant="h1" fontSize={30}>{t("checkout")}</Typography>
                            </Stack>
                            <Stack px={{ md: 6, sm: 4 }} spacing={2} mb={2}>
                                <FormControl component={Stack} spacing={2}>
                                    <FormLabel id="deliveryType">
                                        <CustomTypography>{t("deliveryType")}</CustomTypography>
                                    </FormLabel>
                                    <Controller
                                        name="deliveryType"
                                        control={control}
                                        render={({ field }) => (
                                            <RadioGroup
                                                {...field}
                                                row
                                            >
                                                <Grid container spacing={2} width={"100%"}>
                                                    {TypesOfCheckout(t).map(e =>
                                                        <Grid size={{ xs: 12 }} key={e.value}>
                                                            <FormControlLabel
                                                                className={
                                                                    clsx(classes.radioStyle,
                                                                        { [classes.radioStyleActive]: field.value === e.value })
                                                                }
                                                                value={e.value}
                                                                control={<Radio sx={{ display: "none" }} />}
                                                                label={
                                                                    <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                                                                        <Stack direction={"row"} spacing={1}>
                                                                            {field.value === e.value ? <RadioButtonChecked color="primary" /> : <RadioButtonUnchecked />}
                                                                            {/* <Icon>{e.icon}</Icon> */}
                                                                            {e.icon}
                                                                            <Typography>{e.title}</Typography>
                                                                        </Stack>
                                                                        <Typography>
                                                                            {[DeliveryTypeCode.Dlv, DeliveryTypeCode.Rib].includes(e.value) ?
                                                                                t("tax") + " " + settings.tax + "%"
                                                                                : t("tax") + " " + settings.serviceTax + "%"
                                                                            }
                                                                        </Typography>
                                                                    </Stack>
                                                                }
                                                            // onChange={() => updateQueries(e.value === DeliveryTypeCode.Dlv)}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </FormControl>
                                {[DeliveryTypeCode.Rib, DeliveryTypeCode.Dlv].includes(watch("deliveryType")) && <Stack spacing={2}>
                                    <CustomTypography>
                                        {t("orderDate")}
                                    </CustomTypography>
                                    <MUIDateTime
                                        control={control}
                                        name="deliveryDate"
                                        label={t("deliveryDate")}
                                        rules={{
                                            required: t("fieldIsRequired"),
                                        }}
                                        shouldDisableDate={(day: any) => {
                                            const today = new Date().setHours(0, 0, 0, 0)
                                            const getDay = new Date(day).setHours(0, 0, 0, 0)
                                            return getDay <= today
                                        }}
                                    />
                                </Stack>}
                                {watch("deliveryType") === DeliveryTypeCode.Dlv && DELIVERY_STACK}
                                {[DeliveryTypeCode.Rib, DeliveryTypeCode.Trs].includes(watch("deliveryType")) && BRANCH}
                                <Stack>
                                    <FormControl component={Stack} spacing={2}>
                                        <FormLabel id="paymentMethod">
                                            <CustomTypography>
                                                {t("paymentType")}
                                            </CustomTypography>
                                        </FormLabel>
                                        <Controller
                                            name="paymentType"
                                            control={control}
                                            render={({ field }) => (
                                                <RadioGroup
                                                    {...field}
                                                    row
                                                >
                                                    <Grid container spacing={2} width={"100%"}>
                                                        {TypesOfPayment(t)
                                                            .filter(e => e.value !== "STRIPE" || (e.value === "STRIPE" && settings.stripe.active)) // Filter out inactive STRIPE
                                                            .map(e => (
                                                                <Grid size={{ sm: 6, xs: 12 }} key={e.value}>
                                                                    <FormControlLabel
                                                                        className={clsx(classes.radioStyle, {
                                                                            [classes.radioStyleActive]: field.value === e.value,
                                                                        })}
                                                                        value={e.value}
                                                                        control={<Radio sx={{ display: "none" }} />}
                                                                        label={
                                                                            <Stack direction={"row"} spacing={1}>
                                                                                {field.value === e.value ? (
                                                                                    <RadioButtonChecked color="primary" />
                                                                                ) : (
                                                                                    <RadioButtonUnchecked />
                                                                                )}
                                                                                {e.icon}
                                                                                <Typography>{e.title}</Typography>
                                                                            </Stack>
                                                                        }
                                                                    />
                                                                </Grid>
                                                            ))}

                                                    </Grid>
                                                </RadioGroup>
                                            )}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack>
                                    <ControlMUITextField
                                        label={t("notes")}
                                        control={control}
                                        name="note"
                                        rows={3}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid className={classes.leftWrapper} size={{ md: 6, xs: 12 }} display={"flex"}>
                            <Stack px={{ md: 6, sm: 4 }} mb={5} width={"100%"} >
                                <Box height={80} className={classes.mdNone} />
                                <Stack spacing={2} justifyContent={"space-between"} height={"100%"}>
                                    <Stack maxHeight={400} className={classes.cartWrapper} spacing={2}>
                                        <CustomTypography>{t("reviewYourCart")}</CustomTypography>
                                        {cartLoading && [1, 2, 3].map(e =>
                                            <Stack direction={"row"} spacing={1} key={e}>
                                                <Stack width={100} height={100} className={classes.imageWrapper}>
                                                    <Skeleton width={"100%"} height={"100%"} variant="rounded" animation="wave" />
                                                </Stack>
                                                <Stack justifyContent={"space-between"}>
                                                    <Stack>
                                                        <Typography fontWeight={"bolder"}><Skeleton width={100} variant="text" animation="wave" /></Typography>
                                                        <Typography color="text.secondary"><Skeleton width={100} variant="text" animation="wave" /></Typography>
                                                    </Stack>
                                                    <Typography fontWeight={"bolder"}>
                                                        <Skeleton width={100} variant="text" animation="wave" />
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        )}
                                        {!cartLoading && (!noCart ? data?.cart.items.map(e =>
                                            <Stack direction={"row"} spacing={1} key={e?.id}>
                                                <Stack width={100} height={100} className={classes.imageWrapper}>
                                                    <img
                                                        src={e?.product.image?.path ? getBackendUri(e?.product.image?.path) : noImage}
                                                        alt={e?.product.name}
                                                        width={"100%"}
                                                        height={"100%"}
                                                        className={classes.image}
                                                    />
                                                </Stack>
                                                <Stack justifyContent={"space-between"}>
                                                    <Stack>
                                                        <Typography fontWeight={"bolder"}>{e?.product.name}</Typography>
                                                        <Typography color="text.secondary">{e?.quantity}X</Typography>
                                                    </Stack>
                                                    <Typography fontWeight={"bolder"}>
                                                        {formatted(e?.price)}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        ) : <div>{t("noData")}</div>)}
                                        <Divider />
                                    </Stack>
                                    <Stack spacing={1}>
                                        {!noCart && <>
                                            <Stack component={Paper} p={1} className={classes.divider} direction={"row"} justifyContent={"space-between"}>
                                                <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("subtotal")}</Typography>
                                                <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(orderFeesData?.calculateOrderFees?.subtotal)}</Typography>
                                            </Stack>
                                            <Stack component={Paper} p={1} direction={"row"} justifyContent={"space-between"}>
                                                <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("taxFees")}</Typography>
                                                <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(orderFeesData?.calculateOrderFees?.taxFees)}</Typography>
                                            </Stack>
                                            {watch("deliveryType") === "DLV" && <Stack component={Paper} p={1} className={classes.divider} direction={"row"} justifyContent={"space-between"}>
                                                <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("deliveryFees")}</Typography>
                                                <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(orderFeesData?.calculateOrderFees?.deliveryFees)}</Typography>
                                            </Stack>}
                                            <Stack component={Paper} p={1} className={watch("deliveryType") === "DLV" ? "" : classes.divider} direction={"row"} justifyContent={"space-between"}>
                                                <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={100} variant="text" animation="wave" /> : t("totalFees")}</Typography>
                                                <Typography fontWeight={"bold"} fontSize={16}>{loading ? <Skeleton width={60} variant="text" animation="wave" /> : formatted(orderFeesData?.calculateOrderFees?.totalFees)}</Typography>
                                            </Stack>
                                        </>}
                                        {isTimeBeforeNow(settings.lastOrder) && <Alert severity="warning" sx={{ marginTop: "10px" }}>
                                            {t("last Customer Order at")}{" "}{settings.lastOrder}
                                        </Alert>}
                                        <LoadingButton
                                            loading={createOrderLoading}
                                            type="submit"
                                            variant="contained"
                                            disabled={noCart || isTimeBeforeNow(settings.lastOrder)}
                                        >
                                            {t("payNow")}
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </Root>
    )
}

export default Checkout