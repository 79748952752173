import React, { useEffect } from 'react'
import CustomDialog from '../../../Components/MUI/CustomDialog'
import { Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../Store/store'
import { UserImageInput } from '../../../graphql/types'
import UploadFile from '../../../Components/MUI/UploadFile'
import { LoadingButton } from '@mui/lab'
import { useUpdate_User_ImageMutation } from '../../../graphql/mutations/user.generated'
import { changeUser } from '../../../Store/slices/auth'

const UpdateProfilePic = ({
    open,
    handleClose
}: {
    open: boolean
    handleClose: () => void
}) => {
    const { t } = useTranslation()
    const { user } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch()
    const { handleSubmit, control, setValue } = useForm<UserImageInput & { fileName?: string }>({
        defaultValues: {

        }
    })

    useEffect(() => {
        setValue("fileName", user?.image)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const [updateUserImageMutation, { loading }] = useUpdate_User_ImageMutation();

    const onSubmit = (data: UserImageInput & { fileName?: string }) => {
        updateUserImageMutation({
            variables: {
                input: {
                    ...(data.image && {
                        image: {
                            image: data.image
                        }
                    }),
                }
            }
        }).then((data) => {
            dispatch(changeUser({
                birthdate: user?.birthdate!,
                email: user?.email!,
                firstName: user?.firstName!,
                lastName: user?.lastName!,
                image: data.data?.updateUserImage.image?.path!,
                mobile: user?.mobile
            }))
            handleClose()
        })
    }
    return (
        <CustomDialog
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
                noValidate: true
            }}
            open={open}
            handleClose={handleClose}
            title={t("updateProfile")}
            content={
                <Stack spacing={2}>
                    <UploadFile
                        control={control}
                        setValue={setValue}
                        name="image"
                        icon={"add_photo_alternate"}
                        label={t("image")}
                        accept=".png,.jpg,.jpeg,.webp"
                        maxSize={1000 * 1024}
                    />
                </Stack>
            }
            buttonAction={
                <LoadingButton
                    loading={loading}
                    variant='contained'
                    type='submit'
                >
                    {t("submit")}
                </LoadingButton>
            }
        />
    )
}

export default UpdateProfilePic