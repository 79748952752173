import React, { Dispatch, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
    Typography,
    Paper,
    Table,
    TableRow,
    TableBody,
    TableHead,
    IconButton,
    Toolbar,
    Stack,
    TableContainer,
    TableCell,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add, Delete, Edit } from "@mui/icons-material";
import { FixedTableCell } from "../../Layouts/FixedTableCell";
import ListPaper from "../../Layouts/ListPaper";
import TableBodyWithLoad from "../../Layouts/TableBodyWithLoad";
import { useList_Tables_BranchQuery } from "../../../../graphql/queries/branches.generated";
import { Action } from "../BranchesCreate";
import Appointments, { FiltersTypes } from "./Appointments";


const PREFIX = "RecordsTable";

const classes = {
    p: `${PREFIX}-p`,
    tap: `${PREFIX}-tap`,
    table: `${PREFIX}-table`,
    tableContainer: `${PREFIX}-tableContainer`,
    title: `${PREFIX}-title`,
    toolbar: `${PREFIX}-toolbar`,
    focus: `${PREFIX}-focus`,
    destinations: `${PREFIX}-destinations`,
    deleteIcon: `${PREFIX}-deleteIcon`,
};

const StyledGrid = styled(Stack)(({ theme }) => ({
    [`& .${classes.p}`]: {
        fontSize: "14px",
        margin: "2px 1px",
        width: 100,
    },

    [`& .${classes.tap}`]: {
        margin: 0,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        // flexDirection: "column",
        textTransform: "capitalize",
    },

    [`& .${classes.table}`]: {
        display: "grid",
    },

    [`& .${classes.tableContainer}`]: {
        minHeight: "250px",
    },

    [`& .${classes.title}`]: {
        flex: 1,
    },

    [`& .${classes.toolbar}`]: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderBottom: "1px solid " + theme.palette.divider,
        borderRight: "1px solid " + theme.palette.divider,
    },

    [`& .${classes.focus}`]: {
        backgroundColor: theme.palette.action.hover,
    },

    [`& .${classes.destinations}`]: {
        [theme.breakpoints.down("md")]: {
            marginTop: theme.spacing(2),
        },
    },

    [`& .${classes.deleteIcon}`]: {
        marginLeft: theme.spacing(1),
    },
}));


export type TablesList = {
    id: number
    name: String
    chairsNumber: number
    date: string
}

export type TimesTypes = {
    id: number
    fromTime: string
    toTime: string
}


const TablesTable = ({
    branchId,
    setExposeRefetchFunction,
    dispatch
}: {
    branchId: number,
    setExposeRefetchFunction?: any
    dispatch?: Dispatch<Action>

}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false)
    const [times, setTimes] = useState<TimesTypes[]>([])
    const [filters, setFilters] = useState<FiltersTypes>({
        branchId: branchId
    })

    const { data, loading, refetch } = useList_Tables_BranchQuery({
        fetchPolicy: "no-cache",
        skip: !branchId,
        variables: {
            id: Number(branchId)
        }
    });

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    useEffect(() => {
        setExposeRefetchFunction && setExposeRefetchFunction(() => refetch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setExposeRefetchFunction]);

    const tableCellHeader = [
        "name", "chairsNumber", ""
    ]

    if (!dispatch) {
        tableCellHeader.pop()
    }

    return (
        <StyledGrid spacing={1}>
            {open && <Appointments open={open} handleClose={handleClose} filters={filters} times={times}/>}
            <Toolbar component={Paper} className={classes.toolbar} variant="dense">
                <Typography className={classes.title} color="inherit" variant="h6">
                    {t("tables")}
                </Typography>
                {dispatch ? (
                    <IconButton
                        color="primary"
                        disabled={!branchId}
                        onClick={() => {
                            dispatch({
                                payload: {
                                    dialog: true,
                                }
                            })
                        }}
                        aria-label="Add record"
                        size="large"
                    >
                        <Add />
                    </IconButton>
                ) : null}
            </Toolbar>
            <ListPaper height="auto" loading={loading} data={!!(data && data.branch.tables.length)}  >
                <TableContainer sx={{ width: "100%", overflow: "auto" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {tableCellHeader.map(e =>
                                    <TableCell align={'left'} key={e}>
                                        {t(e)}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBodyWithLoad loading={loading} tableCellHeaderLength={tableCellHeader.length}>
                            <TableBody>
                                {data?.branch.tables.map((row) => {
                                    
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                                            <FixedTableCell onClick={() => {
                                                setTimes(row?.times as any)
                                                setFilters(prev => ({ ...prev, tableId: row?.id }))
                                                handleOpen()
                                            }}>
                                                {row?.name}
                                            </FixedTableCell>
                                            <FixedTableCell align={'left'}>
                                                {row?.chairsNumber}
                                            </FixedTableCell>
                                            {dispatch && <FixedTableCell align={'left'}>
                                                <Stack direction={"row"} spacing={0.5}>
                                                    <IconButton size="small"
                                                        onClick={() =>
                                                            dispatch({
                                                                payload: {
                                                                    tableData: row,
                                                                    dialog: true,
                                                                }
                                                            })
                                                        }>
                                                        <Edit fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton size="small">
                                                        <Delete fontSize="inherit" color="error" />
                                                    </IconButton>
                                                </Stack>
                                            </FixedTableCell>}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </TableBodyWithLoad>
                    </Table>
                </TableContainer>
            </ListPaper>
        </StyledGrid>
    );
};

export default TablesTable;
