import { Avatar, IconButton, Stack, TextField, Typography } from "@mui/material"
import { formatted } from "../../../Components/helperFunctions/function"
import { useEffect, useState } from "react";
import { getBackendUri } from "../../../Components/helperFunctions/getBackendUri";
import notRestaurant from "../../../assets/images/not-restaurant.svg";
import { useTranslation } from "react-i18next";
import { useSave_Cart_ItemMutation } from "../../../graphql/mutations/user.generated";
import { LoadingButton } from "@mui/lab";
import CustomDialog from "../../../Components/MUI/CustomDialog";
import ProductView from "./ProductView";
import { useAppDispatch, useAppSelector } from "../../../Store/store";
import { toast } from "sonner";
import { changeCartCount } from "../../../Store/slices/cart";
import { ROLE } from "../../../types";
import { styled } from "@mui/material/styles";
import { FaMinus, FaPlus } from "react-icons/fa";

const Root = styled(TextField)(({ theme }) => ({
    [`& input[type=number]::-webkit-inner-spin-button`]: {
        WebkitAppearance: "none",
        margin: 0,
    },
    [`& input[type=number]::-webkit-outer-spin-button`]: {
        WebkitAppearance: "none",
        margin: 0,
    },
}));

const AvatarRoot = styled("div")(({ theme }) => ({
    cursor: "pointer"
}));

interface itemTypes {
    id: number
    image?: string
    name: string
    price: number
}

const RecommendedComponent = ({
    item
}: {
    item: itemTypes
}) => {
    const { t } = useTranslation()
    const [quantity, setQuantity] = useState("1");
    const [open, setOpen] = useState(false);

    const dispatch = useAppDispatch()
    const { stateCartDrawer, value } = useAppSelector((s) => s.cart)
    const { isLoggedIn, role } = useAppSelector((s) => s.auth)

    // const handleChange = (event: SelectChangeEvent) => {
    //     setQuantity(event.target.value as string);
    // };

    // const array = Array.from({ length: 20 }, (_, index) => index + 1);

    const [saveCartItemMutation, { loading: saveCartItemLoading }] = useSave_Cart_ItemMutation();

    const handleAddItem = () => {
        saveCartItemMutation({
            variables: {
                input: {
                    productId: item.id,
                    quantity: parseInt(quantity)
                }
            }
        }).then((data) => {
            toast.success(data.data?.saveCartItem.product.name + " " + t("addedToCart"))
            dispatch(changeCartCount(
                value + 1
            ))
        }).catch((err) => {
            toast.error(err)
        })
    }
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (stateCartDrawer) {
            handleClose()
        }

        return () => { }
    }, [stateCartDrawer])


    return (
        <Stack
            alignItems={"center"}
            spacing={1}
            width={"120px"}
        >
            {open && <CustomDialog
                open={open}
                handleClose={handleClose}
                maxWidth="lg"
                content={
                    <ProductView id={item.id} />
                }
            />}
            <AvatarRoot onClick={handleOpen} >
                <Avatar sx={{ width: "80px", height: "80px", background: "transparent" }}>
                    <img
                        src={item.image ? getBackendUri(item.image) : notRestaurant}
                        alt={item.name}
                        width={"100%"}
                        height={"100%"}
                        style={{ objectFit: "fill", borderRadius: "8px" }}
                    />
                </Avatar>
            </AvatarRoot>
            <Typography>{item.name}</Typography>
            <Typography>{formatted(item.price)}</Typography>
            <Root
                id="outlined-controlled"
                label="quantity"
                value={quantity}
                type="number"
                disabled
                InputProps={{
                    startAdornment:
                        <IconButton
                            onClick={() => setQuantity(prev => String(Number(prev) - 1 < 1 ? 1 : Number(prev) - 1))}
                        >
                            <FaMinus fontSize={15} />
                        </IconButton>,
                    endAdornment:
                        <IconButton
                            onClick={() => setQuantity(prev => String(Number(prev) + 1))}
                        >
                            <FaPlus fontSize={15} />
                        </IconButton>,

                }}
            />

            {/* <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">{t("placeholder")}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={quantity}
                    label="Age"
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                >
                    {array.map(e =>
                        <MenuItem key={e} value={e}>{e}</MenuItem>
                    )}
                </Select>
            </FormControl> */}
            {role === ROLE.CUSTOMER && isLoggedIn && <LoadingButton
                loading={saveCartItemLoading}
                variant="contained"
                size="small"
                onClick={handleAddItem}
            >
                {t("add")}
            </LoadingButton>}
        </Stack>
    )
}

export default RecommendedComponent